import React from "react";
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import PlaceBidDialog from './PlaceBidDialog';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { Roles } from '../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../Roles';
import Tooltip from '@material-ui/core/Tooltip';

const styles = () => createStyles({
  reducedPadding: {
    paddingTop: '6px'
  }
});

interface IAddBidProps extends WithTranslation, WithStyles, IWithRoleProps{}
interface IAddBidState {
  dialogOpen: boolean
}

class AddBid extends React.Component<IAddBidProps, IAddBidState> {

  constructor(props: IAddBidProps) {
    super(props);
    this.state = {
      dialogOpen: false
    }
  }

  private handleOpenDialog = () => {
    this.setState({
      dialogOpen: true
    })
  }

  private handleCloseDialog = () => {
    this.setState({
      dialogOpen: false
    });
  }

  public render() {
    const {
      dialogOpen
    } = this.state;
    const {
      t,
      classes
    } = this.props;
    return (
        <>
            <Tooltip
                title={t('bidsList.addBid').toString()}
            >
                <IconButton 
                    className={classes.reducedPadding}
                    onClick={this.handleOpenDialog}
                >
                    <AddBoxIcon />
                </IconButton>
            </Tooltip>
            {dialogOpen && 
                <PlaceBidDialog
                    onClose={this.handleCloseDialog} 
                    open={dialogOpen}
                />}
        </>
    );
  }
}



export default withRoles(withStyles(styles)(withTranslation()(AddBid)), [Roles.User]);
