import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { signout } from '../../redux/reducers/signout';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Network, { TYPES, networkErrorHelper } from '../../utils/Network';
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';

const logoutIcon = (
    <ExitToAppIcon />
)
const logoutItem = (
    <ListItemIcon>
        {logoutIcon}
    </ListItemIcon>
);


const connector = connect(null, {signout, addError});

interface ISignoutProps extends WithTranslation, ConnectedProps<typeof connector> {}

class Signout extends React.Component<ISignoutProps> {
  private handleLogout = () => {
    Network.GET<null>('/logout', TYPES.JSON, false, {
      prefix: '/auth'
    }).then(() => {
        const {
          signout
        } = this.props;
        signout();
        const env = process.env;
        location.assign(`https://${env.REACT_APP_A0_DOMAIN || ''}/v2/logout?federated&returnTo=${encodeURIComponent(location.origin)}&client_id=${env.REACT_APP_A0_ID || ''}`);
    }, (err: Error) => {
      const {
        addError
      } = this.props;
      networkErrorHelper(err.message as ERRORS, addError);
    })
  }

  public render() {
    const {
      t
    } = this.props;

    return (
        <ListItem
            button
            onClick={this.handleLogout}
        >
            {logoutItem}
            <ListItemText primary={t('drawer.logout')} />
        </ListItem>
    );
  }
}

export default connector(
    withTranslation()(Signout)
);