import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import ISystemState from '../../../model/ISystemState';
import IOfferData from '../../../model/IOfferData';
import { connect, ConnectedProps } from 'react-redux';
import Network, { TYPES, networkErrorHelper } from '../../../utils/Network';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import { addOffer, fetchOffer } from '../../../redux/reducers/offers';
import store from '../../../redux/store';
import BidData from '../BidData';
import { addError } from '../../../redux/reducers/errors';
import { ERRORS } from '../../../model/Errors';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';


const styles = () => createStyles({
  centerAligned: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '8px',
    display: 'flex',
    height: '100%'
  },
  contrast: {
    color: '#888888'
  }
});

const mapState = (state: ISystemState) => {
  return {
    bidStates: state.bidStates,
    bids: state.bids,
    allbids: state.allbids,
    offers: state.offers
  };
}

const connector = connect(mapState, { addOffer, addError });

interface IOfferDialogProps extends WithTranslation, WithStyles,
    ConnectedProps<typeof connector> {
  open: boolean;
  onClose:  () => void;
  onSend: (bidId: string, cb: () => void) => void;
  bidId: string;
}

interface IOfferDialogState {
  open: boolean;
  comment: string;
  unitPrice: number;
  editorEmpty: boolean;
  submitting: boolean;
}

class OfferDialog extends React.Component<IOfferDialogProps, IOfferDialogState> {
  constructor(props: IOfferDialogProps) {
    super(props);
    this.state = {
      open: props.open,
      comment: '',
      unitPrice: 0,
      editorEmpty: true,
      submitting: false
    }
  }

  public componentDidMount() {
    const {
      offers,
      bidId
    } = this.props;

    if (!offers[bidId]) {
      (store.dispatch as ThunkDispatch<ISystemState, void, AnyAction>)(fetchOffer(bidId));
    }
  }

  private handleCommentUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      comment: event.target.value
    })
  }

  private handleUnitPriceUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      unitPrice: event.target.value as unknown as number
    })
  }

  private handleClose = () => {
    const {
      onClose
    } = this.props;
    onClose();
    this.setState({
      open: false
    });
  }

  private handleSubmit = () => {
    const {
      submitting,
      unitPrice,
      comment
    } = this.state;
    const {
      bidId,
      onSend
    } = this.props;
    if (submitting) return;
    this.setState({
      submitting: true
    });

    Network.POST<IOfferData|null>(`/bids/${bidId}/offer/`, TYPES.JSON, {
      unitPrice,
      comment
    }).then((data: IOfferData|null) => {
      const {
        addOffer
      } = this.props;
      this.setState({
        submitting: false
      });
      if (data) {
        addOffer(data);
      }
      onSend(bidId, () => {return;});
    }, (err: Error) => {
      const {
        addError
      } = this.props;
      networkErrorHelper(err.message as ERRORS, addError);
    })
  }

  public render() {
    const {
      t,
      classes,
      allbids,
      bids,
      bidId,
      offers
    } = this.props;

    const {
      open,
      editorEmpty,
      submitting,
      comment,
      unitPrice
    } = this.state;

    let el = allbids.find((el) => {
      return el.bidId === bidId;
    });

    if (el === undefined) {
      el = bids.find((el) => {
        return el.bidId === bidId;
      });
    }

    let newPrice;
    let newComment;
    if (offers[bidId]) {
      newPrice = offers[bidId].unitPrice;
      newComment = offers[bidId].comment;
    }

    let submitDisabled;
    if (newPrice) submitDisabled = true;

    return (el &&
        <div>
            <Dialog
                disableBackdropClick={!editorEmpty}
                disableEscapeKeyDown={!editorEmpty}
                fullWidth
                maxWidth='md'
                onClose={this.handleClose}
                open={open}
            >
                <ValidatorForm
                    instantValidate={false}
                    onSubmit={this.handleSubmit}
                >
                    <DialogContent>
                        <DialogContentText />
                        <Grid
                            container
                            spacing={1}
                        >
                            <BidData el={el} />
                            <Grid
                                item
                                xs={12}
                            >
                                <Grid
                                    alignItems="flex-end"
                                    container
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                    >
                                        <TextValidator
                                            autoFocus
                                            disabled={submitDisabled || submitting}
                                            errorMessages={[t('order.errors.required'),
                                              t('order.errors.quantityNaN'),
                                              t('order.errors.quantityTooSmall'),
                                              t('order.errors.quantityTooLarge')
                                            ]}
                                            fullWidth
                                            id="unitPrice"
                                            label={t('offer.unitPrice').toString()}
                                            name="unitPrice"
                                            onChange={this.handleUnitPriceUpdate}
                                            validators={['required', 'isFloat', 'minFloat:0.0001', 'maxFloat:100000000']}
                                            value={newPrice || unitPrice}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <TextValidator
                                            disabled={submitDisabled || submitting}
                                            errorMessages={[t('offer.errors.tooLong')]}
                                            fullWidth
                                            id="comment"
                                            label={t('offer.comments').toString()}
                                            multiline
                                            name="comment"
                                            onChange={this.handleCommentUpdate}
                                            rows={5}
                                            validators={['maxStringLength:500']}
                                            value={newComment || comment}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <DialogActions>
                                    <Button
                                        disabled={!editorEmpty}
                                        onClick={this.handleClose}
                                    >
                                        {t('order.close')}
                                    </Button>
                                    <Button 
                                        color="primary"
                                        disabled={submitDisabled || submitting}
                                        type="submit"
                                        variant="contained"
                                    >
                                        {t('order.send')}
                                        {submitting &&
                                            <CircularProgress
                                                className={classes.buttonProgress}
                                                size={24}
                                            />}
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </ValidatorForm>
            </Dialog>
        </div>
    );
  }
}

export default 
  withStyles(styles)(
    withTranslation()(
      connector(OfferDialog)
    )
  );