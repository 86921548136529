import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import { withTranslation, WithTranslation } from 'react-i18next';
import ISystemState from '../../../model/ISystemState';
import IOfferData from '../../../model/IOfferData';
import { connect, ConnectedProps } from 'react-redux';
import Network, { TYPES, ContentType, networkErrorHelper } from '../../../utils/Network';
import { ValidatorForm } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchOffer } from '../../../redux/reducers/offers';
import store from '../../../redux/store';
import BidData from '../BidData';
import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { formatDateForStorage } from '../../../utils/Date';
import { addError } from '../../../redux/reducers/errors';
import { ERRORS } from '../../../model/Errors';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';

const mapState = (state: ISystemState) => {
  return {
    bidStates: state.bidStates,
    bids: state.bids,
    allbids: state.allbids,
    offers: state.offers
  };
}

const connector = connect(mapState, { addError });

interface IOrderConfirmedProps extends WithTranslation,
    ConnectedProps<typeof connector> {
  open: boolean;
  onClose:  () => void;
  onSend: (bidId: string, cb: () => void) => void;
  bidId: string;
}

interface IOrderConfirmedState {
  open: boolean;
  submitting: boolean;
  filename: string;
  deliveryDate: Date;
}

class OrderConfirmed extends React.Component<IOrderConfirmedProps, IOrderConfirmedState> {
  constructor(props: IOrderConfirmedProps) {
    super(props);
    this.state = {
      open: props.open,
      submitting: false,
      filename: '',
      deliveryDate: new Date()
    }
  }

  public componentDidMount() {
    const {
      offers,
      bidId
    } = this.props;

    if (!offers[bidId]) {
      (store.dispatch as ThunkDispatch<ISystemState, void, AnyAction>)(fetchOffer(bidId));
    }
  }

  private inputRef?: HTMLInputElement;

  private handleClose = () => {
    const {
      onClose
    } = this.props;
    onClose();
    this.setState({
      open: false
    });
  }

  private handleFileAttach = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (ev.target.value) {
      const parts = ev.target.value.split('\\');
      this.setState({
        filename: parts[parts.length - 1]
      })
    }
  }

  private handleSetDate = (date: Date | null) => {
    if (date) {
      this.setState({
        deliveryDate: date
      })
    }
  }

  private handleSubmit = () => {
    const {
      submitting,
      deliveryDate
    } = this.state;

    const {
      bidId,
      offers,
      onSend,
      onClose
    } = this.props;

    if (submitting) return;

    this.setState({
      submitting: true
    });

    Network.POST<IOfferData|null>(`/bids/${bidId}/offer/confirmation/`, TYPES.JSON, {
      offerId: offers[bidId].offerId,
      deliveryDate: formatDateForStorage(new Date(deliveryDate)),
      file: this.inputRef && this.inputRef.files ? this.inputRef.files[0] : undefined
    }, ContentType.multipart).then(() => {
      onSend(bidId, () => {return;});
      onClose();
      this.setState({
        submitting: false,
      });
    }, (err: Error) => {
      const {
        addError
      } = this.props;
      networkErrorHelper(err.message as ERRORS, addError);
    })
  }

  private createReference = (ref: HTMLInputElement) => {
    this.inputRef = ref;
  }

  public render() {
    const {
      t,
      allbids,
      bids,
      bidId
    } = this.props;

    const {
      open,
      submitting,
      filename,
      deliveryDate
    } = this.state;

    let el = allbids.find((el) => {
      return el.bidId === bidId;
    });

    if (el === undefined) {
      el = bids.find((el) => {
        return el.bidId === bidId;
      });
    }

    return (el &&
        <div>
            <Dialog
                fullWidth
                maxWidth='md'
                onClose={this.handleClose}
                open={open}
            >
                <ValidatorForm
                    instantValidate={false}
                    onSubmit={this.handleSubmit}
                >
                    <DialogContent>
                        <DialogContentText />
                        <Grid
                            container
                            spacing={1}
                        >
                            <BidData el={el} />
                            <Grid
                                item
                                xs={12}
                            >
                                <Grid
                                    alignItems="flex-end"
                                    container
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <Grid
                                            alignItems="center"
                                            container
                                            spacing={1}
                                        >
                                            <Grid
                                                item
                                            >
                                                <Button
                                                    color="primary"
                                                    component="label"
                                                    variant="contained"
                                                >
                                                    {t('order.confirm.addPDF')}
                                                    <input
                                                        accept="application/pdf"
                                                        hidden
                                                        onChange={this.handleFileAttach}
                                                        ref={this.createReference}
                                                        type="file"
                                                    />
                                                </Button>
                                            </Grid>
                                            <Grid
                                                item
                                            >
                                                <Typography>
                                                    {filename}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                format="dd/MM/yyyy"
                                                id="date-picker"
                                                label={t('order.confirm.deliveryDate')}
                                                minDate={new Date()}
                                                onChange={this.handleSetDate}
                                                value={deliveryDate}
                                                variant="inline"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <DialogActions>
                                    <Button
                                        onClick={this.handleClose}
                                    >
                                        {t('order.close')}
                                    </Button>
                                    <Button 
                                        color="primary"
                                        disabled={submitting || !filename}
                                        type="submit"
                                        variant="contained"
                                    >
                                        {t('order.send')}
                                        {submitting &&
                                            <CircularProgress
                                                size={24}
                                            />}
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </ValidatorForm>
            </Dialog>
        </div>
    );
  }
}

export default 
  withTranslation()(
    connector(OrderConfirmed)
  );