import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IOfferData from "../../model/IOfferData";
import { AppThunk } from '../store';
import Network, { TYPES } from "../../utils/Network";
import { Offers } from "../../model/ISystemState";
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';


const initialState: Offers = {};


const offers = createSlice({
  name: 'offers',
  initialState: initialState,
  reducers: {
    'addOffer': (state = initialState, action: PayloadAction<IOfferData>) => {
      const payload = action.payload;
      state[payload.bidId] = payload;
      return state;
    },
    'updatePrice': (state = initialState, action: PayloadAction<IOfferData>) => {
      const payload = action.payload;
      state[payload.bidId] = payload;
      return state;
    },
  }
});

export const { addOffer, updatePrice } = offers.actions;

export default offers.reducer;

export const fetchOffer = (bidId: string): AppThunk => (dispatch) => {
  Network.GET<IOfferData>(`/bids/${bidId}/offer/`, TYPES.JSON).then((json) => {
    if (json) {
      dispatch(addOffer(json));
    }
  }, (err: Error) => {
    if (err.message in ERRORS) {
      dispatch(addError(err.message as ERRORS));
      return;
    }
    dispatch(addError(ERRORS.NETWORK_ERROR));
  });
}
