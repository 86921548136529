import React from "react";
import clsx from 'clsx';
import { connect, ConnectedProps } from "react-redux";
import ISystemState from "../../../../model/ISystemState";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withTranslation, WithTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Title from '../../Title';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Roles } from '../../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../../Roles';
import Container from '@material-ui/core/Container';
import JoinDialog from './JoinDialog';
import { addOpenBids } from '../../../../redux/reducers/openBids';
import Network, { TYPES, networkErrorHelper } from '../../../../utils/Network';
import IBidData from '../../../../model/IBidData';
import { formatDate } from '../../../../utils/Date';
import { Switch, Route, withRouter, RouteComponentProps } from "react-router-dom";
import { addError } from '../../../../redux/reducers/errors';
import { ERRORS } from '../../../../model/Errors';

const styles = (theme: Theme) => createStyles({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    noOverflow: {
        overflowY: 'hidden',
        overflowX: 'scroll'
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    hover: {
        cursor: 'pointer'
    },
});


const mapState = (state: ISystemState) => {
  return {
      bids: state.openBids
  };
};

const connector = connect(mapState, { addOpenBids, addError });

interface IOpenBidsListProps extends ConnectedProps<typeof connector>, WithTranslation, WithStyles, IWithRoleProps, RouteComponentProps{}

class OpenBidsList extends React.Component<IOpenBidsListProps> {

  componentDidMount = () => {
    const {
      bids
    } = this.props;
  
    if (bids.length === 0) {
      Network.GET<IBidData[]>('/bids/', TYPES.JSON, false, {
        open: true
      }).then((res) => {
        const {
          addOpenBids
        } = this.props;

        if (res) {
          addOpenBids(res);
        }
      }, (err: Error) => {
        const {
            addError
          } = this.props;
          networkErrorHelper(err.message as ERRORS, addError);
      });
    }
  }

  private handleRowClick = (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
    const {
      i18n,
      history
    } = this.props;

    const bidId = ev.currentTarget.getAttribute("data-id") || '';
    if (bidId) {
      history.push(`/${i18n.language}/join/${bidId}`);
    }
  }

  private handleCloseDialog = () => {
    const {
      i18n,
      history
    } = this.props;
    history.push(`/${i18n.language}/join`);
  }

  private getTableRow = (el: IBidData) => {
    const {
      classes
    } = this.props;

    return (
        <TableRow
            className={classes.hover}
            data-id={el.bidId}
            key={el.bidId}
            onClick={this.handleRowClick}
        >
            <TableCell>
                {el.commodity}
            </TableCell>
            <TableCell>
                {el.manufacturer}
            </TableCell>
            <TableCell>
                {el.product}
            </TableCell>
            <TableCell>
                {el.EAN}
            </TableCell>
            <TableCell>
                {el.partNumber}
            </TableCell>
            <TableCell>
                {formatDate(new Date(el.byDate))}
            </TableCell>
        </TableRow>
    )
  }

  public render() {
    const {
      bids,
      t,
      classes,
      i18n
    } = this.props;

    const noOverflowPaper = clsx(classes.paper, classes.noOverflow);

    return (
        <>
            <Container
                className={classes.container}
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Title>
                        {t('openBidsList.outstaningBids')}
                    </Title>
                    <Grid
                        container
                        item
                        spacing={3}
                        xs={12}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Paper 
                                className={noOverflowPaper}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {t('bidsList.commodity')}
                                            </TableCell>
                                            <TableCell>
                                                {t('bidsList.manufacturer')}
                                            </TableCell>
                                            <TableCell>
                                                {t('bidsList.product')}
                                            </TableCell>
                                            <TableCell>
                                                {t('bidsList.EAN')}
                                            </TableCell>
                                            <TableCell>
                                                {t('bidsList.partNumber')}
                                            </TableCell>
                                            <TableCell>
                                                {t('bidsList.neededBy')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {bids.map((el) => (
                                            this.getTableRow(el)
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Switch>
                <Route
                    exact
                    path={`/${i18n.language}/join/:bidId`}
                >
                    <JoinDialog
                        onClose={this.handleCloseDialog}
                    />
                </Route>
            </Switch>
        </>
    );
  }
}



export default withRoles(
    connector(
        withStyles(styles)(
            withTranslation()(
              withRouter(OpenBidsList)
            )
        )
    ), [Roles.User]
);
