import React from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import { Roles } from '../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../Roles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';

interface IEditQuantityProps extends WithTranslation, IWithRoleProps, WithStyles{
  quantity: number;
  onUpdate: (qty: number, cb: () => void) => void;
  canEdit: boolean;
}

interface IEditQuantityState {
  quantity: number;
  editable: boolean;
  submitting: boolean;
}

const styles = () => createStyles({
  contrast: {
    color: '#888888'
  }
});

class EditQuantity extends React.Component<IEditQuantityProps, IEditQuantityState> {
  constructor(props: IEditQuantityProps) {
    super(props);
    this.state = {
      quantity: props.quantity,
      editable: false,
      submitting: false
    };
  }

  private handleSubmit = () => {
    const {
      onUpdate
    } = this.props;
    const {
      submitting,
      quantity
    } = this.state;
    if (submitting) {
      return;
    }

    this.setState({
      submitting: true
    });
    onUpdate(quantity, this.submitCallback);
  }

  private submitCallback = () => {
    this.setState({
      submitting: false,
      editable: false
    });
  }

  private handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
    if (val === '') val = '0';
    this.setState({
      quantity: parseInt(val, 10)
    })
  }

  private handleClick = () => {
    this.setState({
      editable: true
    })
  }

  public render() {
    const {
      canEdit
    } = this.props;
    const {
      editable,
      quantity,
      submitting
    } = this.state;

    const {
      t,
      classes
    } = this.props;

    if (!canEdit) {
      return (
          <>
              <Typography
                  className={classes.contrast}
                  variant="caption"
              >
                  {`${t('order.quantity')}:`}
              </Typography>
              <Typography>
                  {quantity}
              </Typography>
          </>)
    } else {
      return (
          <>
              {!editable &&
              <Grid
                  alignItems="flex-end"
                  container
                  spacing={1}
              >
                  <Grid
                      item
                      onClick={this.handleClick}
                      xs={6}
                  >
                      <Typography
                          className={classes.contrast}
                          variant="caption"
                      >
                          {`${t('order.quantity')}:`}
                      </Typography>
                      <Box
                          borderBottom={1}
                      >
                          <Typography>
                              {quantity}
                          </Typography>
                      </Box>
                  </Grid>
                  <Grid
                      item
                      xs={6}
                  >
                      <Box
                          alignItems="flex-end"
                          display="flex"
                          justifyContent="flex-start"
                      >
                          <IconButton
                              onClick={this.handleClick}
                          >
                              <EditIcon 
                                  fontSize="small"
                              />
                          </IconButton>
                      </Box>
                  </Grid>
              </Grid>}
              {editable && 
              <ValidatorForm
                  onSubmit={this.handleSubmit}
              >
                  <Grid
                      alignItems="flex-end"
                      container
                      spacing={1}
                  >
                      <Grid
                          item
                          onClick={this.handleClick}
                          xs={6}
                      >
                          <TextValidator
                              autoFocus
                              disabled={submitting}
                              errorMessages={[t('order.errors.required'), 
                                t('order.errors.quantityNaN'),
                                t('order.errors.quantityTooSmall'),
                                t('order.errors.quantityTooLarge')
                              ]}
                              fullWidth
                              id="quantity"
                              label={t('order.quantity').toString()}
                              name="quantity"
                              onChange={this.handleInput}
                              validators={['required', 'isNumber', 'minNumber:1', 'maxNumber:100000000']}
                              value={quantity}
                          />
                      </Grid>
                      <Grid
                          item
                          xs={6}
                      >
                          <Box
                              alignItems="flex-end"
                              display="flex"
                              justifyContent="flex-start"
                          >
                              <IconButton
                                  disabled={submitting}
                                  type="submit"
                              >
                                  {!submitting &&
                                  <CheckIcon 
                                      fontSize="small"
                                  />}
                                  {submitting &&
                                  <CircularProgress 
                                      size={20}
                                  />}
                              </IconButton>
                          </Box>
                      </Grid>
                  </Grid>
              </ValidatorForm>}
          </>);
      }
  }
}



export default withRoles(
  withStyles(styles)(
    withTranslation()(
        EditQuantity
    )
  ), [Roles.User]
);
