/* eslint-disable react/no-danger */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Roles } from '../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../Roles';
import { connect, ConnectedProps } from 'react-redux';
import ISystemState from "../../../model/ISystemState";
import { fetchOrderConfirmation } from '../../../redux/reducers/orderconfirmation';
import store from '../../../redux/store';
import Link from '@material-ui/core/Link';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { SERVER_URL, version } from '../../../utils/Network';
import Typography from '@material-ui/core/Typography';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';

const mapState = (state: ISystemState) => {
  return {
    confirmations: state.confirmations,
    allbids: state.allbids,
    bidStates: state.bidStates
  };
}

const connector = connect(mapState);

interface IOrderConfirmationProps extends WithTranslation, IWithRoleProps, 
  ConnectedProps<typeof connector> {
    bidId: string;
}

class OrderConfirmation extends React.Component<IOrderConfirmationProps> {

  public componentDidMount() {
    const {
      bidId,
      confirmations,
      bidStates,
      allbids
    } = this.props;

    const el = allbids.find((el) => {
      return el.bidId === bidId;
    });

    if (el && el.state >= bidStates['Confirmed'] && !confirmations[bidId]) {
      (store.dispatch as ThunkDispatch<ISystemState, void, AnyAction>)(fetchOrderConfirmation(bidId));

    }
  }

  public render() {
    const {
      confirmations,
      bidId,
      t
    } = this.props;

    const confirmation = confirmations[bidId];
    if (!confirmation) {
      return (null);
    }

    return (
        <Grid
            item
            xs={12}
        >
            <Link
                color="primary"
                href={SERVER_URL + version + `/bids/${bidId}/offer/confirmation/${confirmation.handle}`}
                target="_blank"
            >
                <Grid
                    alignContent="center"
                    container
                    spacing={1}
                >
                    <Grid
                        item
                    >
                        <CloudDownloadOutlinedIcon />
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography>
                            {t('order.details.downloadConfirmation')}
                        </Typography>
                    </Grid>
                </Grid>
            </Link>
        </Grid>
    );
  }
}

export default
  connector(
    withRoles(
      withTranslation()(OrderConfirmation), [Roles.Buyer]
    )
  );