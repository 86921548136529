import React from "react";
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';

interface IEditableFieldProps extends WithStyles {
  caption: string;
  value: string;
  onUpdate: (qty: string, cb: () => void) => void;
  onChange?: () => void;
  canEdit: boolean;
  validators: string[];
  errorMessages: string[];
}

interface IEditableFieldState {
  value: string;
  editable: boolean;
  submitting: boolean;
}

const styles = () => createStyles({
  contrast: {
    color: '#888888'
  }
});

class EditableField extends React.Component<IEditableFieldProps, IEditableFieldState> {
  constructor(props: IEditableFieldProps) {
    super(props);
    this.state = {
      value: props.value,
      editable: false,
      submitting: false
    };
  }

  public shouldComponentUpdate(nextProps: IEditableFieldProps) {
    const {
      value
    } = this.props;

    if (value !== nextProps.value) {
      this.setState({
        value: nextProps.value
      });
    }
    return true;
  }

  private handleSubmit = () => {
    const {
      onUpdate
    } = this.props;
    const {
      submitting,
      value
    } = this.state;
    if (submitting) {
      return;
    }

    this.setState({
      submitting: true
    });
    onUpdate(value, this.submitCallback);
  }

  private submitCallback = () => {
    this.setState({
      submitting: false,
      editable: false
    });
  }

  private handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      onChange
    } = this.props;

    if (onChange) {
      onChange();
    }
    this.setState({
      value: event.target.value
    })
  }

  private handleClick = () => {
    this.setState({
      editable: true
    })
  }

  public render() {
    const {
      canEdit,
      caption,
      validators,
      errorMessages
    } = this.props;
    const {
      editable,
      submitting,
      value
    } = this.state;

    const {
      classes
    } = this.props;

    if (!canEdit) {
      return (
          <>
              <Typography
                  className={classes.contrast}
                  variant="caption"
              >
                  {`${caption}:`}
              </Typography>
              <Typography>
                  {value}
              </Typography>
          </>)
    } else {
      return (
          <>
              {!editable &&
              <Grid
                  alignItems="flex-end"
                  container
                  spacing={1}
              >
                  <Grid
                      item
                      onClick={this.handleClick}
                      xs={6}
                  >
                      <Typography
                          className={classes.contrast}
                          variant={!value ? "body1" : "caption"}
                      >
                          {`${caption}${!value ? "" : ":"}`}
                      </Typography>
                      <Box
                          borderBottom={1}
                      >
                          <Typography>
                              {value}
                          </Typography>
                      </Box>
                  </Grid>
                  <Grid
                      item
                      xs={6}
                  >
                      <Box
                          alignItems="flex-end"
                          display="flex"
                          justifyContent="flex-start"
                      >
                          <IconButton
                              onClick={this.handleClick}
                          >
                              <EditIcon 
                                  fontSize="small"
                              />
                          </IconButton>
                      </Box>
                  </Grid>
              </Grid>}
              {editable && 
              <ValidatorForm
                  onSubmit={this.handleSubmit}
              >
                  <Grid
                      alignItems="flex-end"
                      container
                      spacing={1}
                  >
                      <Grid
                          item
                          onClick={this.handleClick}
                          xs={6}
                      >
                          <TextValidator
                              autoFocus
                              disabled={submitting}
                              errorMessages={errorMessages}
                              fullWidth
                              id="value"
                              label={caption}
                              name="value"
                              onChange={this.handleInput}
                              validators={validators}
                              value={value}
                          />
                      </Grid>
                      <Grid
                          item
                          xs={6}
                      >
                          <Box
                              alignItems="flex-end"
                              display="flex"
                              justifyContent="flex-start"
                          >
                              <IconButton
                                  disabled={submitting}
                                  type="submit"
                              >
                                  {!submitting &&
                                  <CheckIcon 
                                      fontSize="small"
                                  />}
                                  {submitting &&
                                  <CircularProgress 
                                      size={20}
                                  />}
                              </IconButton>
                          </Box>
                      </Grid>
                  </Grid>
              </ValidatorForm>}
          </>);
      }
  }
}



export default withStyles(styles)(EditableField);
