import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IBidData from "../../model/IBidData";
import { AppThunk } from '../store';
import Network, { TYPES } from "../../utils/Network";
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';

const initialState = Array<IBidData>();

const allbids = createSlice({
  name: 'allbids',
  initialState: initialState,
  reducers: {
    'addAllBids': (state = initialState, action: PayloadAction<IBidData[]>) => {
      return state.concat(action.payload);
    },
    'advance': (state = initialState, action: PayloadAction<IBidData[]>) => {
      return state.map(el => {
        if (el.bidId === action.payload[0].bidId) {
          return {
            ...el,
            state: action.payload[0].state,
            deliveryDate: action.payload[0].deliveryDate,
            dispatchedDate: action.payload[0].dispatchedDate
          }
        }
        return el;
      })
    },
    'updateCommodity': (state = initialState, action: PayloadAction<IBidData>) => {
      const idx = state.findIndex((el) => {
        return el.bidId === action.payload.bidId;
      });
      state[idx].commodity = action.payload.commodity;
      return state;
    },
    'updateManufacturer': (state = initialState, action: PayloadAction<IBidData>) => {
      const idx = state.findIndex((el) => {
        return el.bidId === action.payload.bidId;
      });
      state[idx].manufacturer = action.payload.manufacturer;
      return state;
    },
  }
});

export const { 
  addAllBids,
  advance,
  updateCommodity,
  updateManufacturer
} = allbids.actions;

export default allbids.reducer;

export const fetchAllBids = (): AppThunk => (dispatch) => {
  Network.GET<IBidData[]>('/bids/all', TYPES.JSON).then((json) => {
    if (json) {
      dispatch(addAllBids(json));
    }
  }, (err: Error) => {
    if (err.message in ERRORS) {
      dispatch(addError(err.message as ERRORS));
      return;
    }
    dispatch(addError(ERRORS.NETWORK_ERROR));
  });
}
