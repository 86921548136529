import React from 'react';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AddBid from './user/AddBid';
import BidsList from './user/BidsList';
import OpenBidsList from './user/openBids/OpenBidsList';
import ProcessBoard from './buyer/ProcessBoard';
import Profile from '../profile/Profile';
import Users from './buyer/userManagement/Users';
import { Switch, Route, Redirect } from "react-router-dom";
import IBidData from '../../model/IBidData';
import { Roles } from '../../model/Roles';
import { withRoles, IWithRoleProps } from '../Roles';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';


import UserDrawer from './user/DrawerItems';
import BuyerDrawer from './buyer/DrawerItems';

const drawerWidth = 290;

const MIN_WIDTH = 1240;

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '60px',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: '60px',
    boxShadow: 'none',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    height: '60px',
    boxShadow: 'none',
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: `calc(100vh - 100px)`,
    overflow: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  noOverflow: {
    overflow: 'hidden'
  },
  fixedHeight: {
    height: 240,
  },
  logo: {
    marginTop: '20px',
    width: '100px'
  },
  drawerList: {
    height: '100%'
  }
});

interface IDashboardState {
  drawerOpen: boolean;
  suppressResizer: boolean;
}

interface IDashboardProps extends WithTranslation, WithStyles, IWithRoleProps {}

class Dashboard extends React.Component<IDashboardProps, IDashboardState> {

  constructor(props: IDashboardProps) {
    super(props);

    this.state = {
      drawerOpen: window.innerWidth > MIN_WIDTH ? true : false,
      suppressResizer: false
    }
  }

  public componentDidMount = () => {
    window.addEventListener('resize', this.handleResize)
  }

  public componentDidUnmount = () => {
    window.removeEventListener('resize', this.handleResize)
  }

  private handleResize = () => {
    const {
      suppressResizer
    } = this.state;

    if (!suppressResizer) {
      if (window.innerWidth < MIN_WIDTH) {
        this.setState({
          drawerOpen: false
        });
      } else {
        this.setState({
          drawerOpen: true
        });
      }
    }
  }

  private handleOpenDrawer = () => {
    this.setState({
      drawerOpen: true,
      suppressResizer: true
    });
  }

  private handleCloseDrawer = () => {
    this.setState({
      drawerOpen: false,
      suppressResizer: true
    });
  }

  private filterOpenBids = (el: IBidData) =>{
    return !el.exited && el.isPaid !== 'PAID';
  }

  private filterArchivedBids = (el: IBidData) =>{
    return el.exited || el.isPaid === 'PAID';
  }

  public render() {
    const {
      drawerOpen
    } = this.state;

    const {
      classes,
      i18n,
      t
    } = this.props;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
                position="absolute"
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        aria-label="open drawer"
                        className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}
                        color="inherit"
                        edge="start"
                        onClick={this.handleOpenDrawer}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        className={classes.title}
                        color="inherit"
                        component="h1"
                        noWrap
                        variant="h6"
                    >
                        <img
                            alt="EoS4U"
                            className={classes.logo}
                            height="30%"
                            src="./assets/logo.png"
                            width="30%"
                        />
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                classes={{
                  paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
                }}
                open={drawerOpen}
                variant="permanent"
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={this.handleCloseDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <List
                    className={classes.drawerList}
                >
                    <UserDrawer />
                    <BuyerDrawer />
                </List>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Switch>
                    <Route
                        exact
                        path={`/${i18n.language}`}
                    >
                        <Redirect to={`/${i18n.language}/home`} />
                    </Route>
                    <Route
                        exact
                        path={[`/${i18n.language}/home`, `/${i18n.language}/home/:bidId`]}
                    >
                        <ProcessBoard />
                        {/* <LastOpenBids /> */}
                        <BidsList
                            filter={this.filterOpenBids}
                            headerButton={(
                                <AddBid />
                            )}
                            pathFragment='home'
                            title={t('dashboard.yourBidsTitle').toString()}
                        />
                    </Route>
                    <Route
                        exact
                        path={[`/${i18n.language}/archive`, `/${i18n.language}/archive/:bidId`]}
                    >
                        <BidsList
                            filter={this.filterArchivedBids}
                            immutableDialog
                            pathFragment='archive'
                            title={t('archive').toString()}
                        />
                    </Route>
                    <Route
                        exact
                        path={`/${i18n.language}/profile`}
                    >
                        <Profile />
                    </Route>
                    <Route
                        exact
                        path={[`/${i18n.language}/join`, `/${i18n.language}/join/:bidId`]}
                    >
                        <OpenBidsList />
                    </Route>
                    <Route
                        exact
                        path={`/${i18n.language}/userManagement`}
                    >
                        <Users />
                    </Route>
                </Switch>
            </main>
        </div>
    );
  }
}

export default withRoles(
  withStyles(styles)(
    withTranslation()(Dashboard)
  ), [Roles.User, Roles.Buyer]
);
