import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import ISystemState from "../../model/ISystemState";
import { connect, ConnectedProps } from 'react-redux';
import BidNotes from './buyer/BidNotes';
import Offer from './user/Offer';
import INoteData from '../../model/INoteData'
import Network, { TYPES, networkErrorHelper } from '../../utils/Network';
import AllNotes from './buyer/AllNotes';
import { addNote } from '../../redux/reducers/allnotes';
import { exitBid, updateBid } from '../../redux/reducers/bids';
import ExitBid from './user/ExitBid';
import Box from '@material-ui/core/Box';
import SubmitBuyer from './buyer/SubmitBuyer';
import SubmitUser from './user/SubmitUser';
import IBidData from '../../model/IBidData';
import BidData from './BidData';
import OrderConfirmation from './buyer/OrderConfirmation';
import PackingSlip from './buyer/PackingSlip';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';


const styles = (theme: Theme) => createStyles({
  centerAligned: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '8px',
    display: 'flex',
    height: '100%'
  },
  contrast: {
    color: '#888888'
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '1rem'
  }
});

const mapState = (state: ISystemState) => {
  return {
    bidStates: state.bidStates,
    bids: state.bids,
    allbids: state.allbids,
    offers: state.offers
  };
}

interface MatchParams {
  bidId: string;
}

const connector = connect(mapState, { addNote, exitBid, updateBid, addError });

interface IShowDetailsProps extends WithTranslation, WithStyles,
    ConnectedProps<typeof connector>, RouteComponentProps<MatchParams> {
  onClose: () => void;
  immutable?: boolean;
}

interface IShowDetailsState {
  note: string;
  editorEmpty: boolean;
  submitting: boolean;
}

class ShowDetails extends React.Component<IShowDetailsProps, IShowDetailsState> {
  constructor(props: IShowDetailsProps) {
    super(props);
    this.state = {
      note: '',
      editorEmpty: true,
      submitting: false
    }
  }

  private handleNotesUpdate = (noteHtml: string, isEmpty: boolean) => {
    this.setState({
      note: noteHtml,
      editorEmpty: isEmpty
    })
  }

  private handleClose = () => {
    const {
      onClose
    } = this.props;
    onClose();
  }

  private handleSubmit = () => {
    const {
      submitting,
      note
    } = this.state;
    const {
      match
    } = this.props;

    const bidId = match.params.bidId;

    if (submitting) return;
    this.setState({
      submitting: true
    });
    
    Network.POST<INoteData>('/bids/notes/', TYPES.JSON, {
      note: note,
      bidId: bidId
    }).then((res) => {
      const {
        addNote
      } = this.props;
      addNote({
        bidId: bidId,
        notes: [res]
      })
      this.handleClose();
    }, (err: Error) => {
      const {
        addError
      } = this.props;
      networkErrorHelper(err.message as ERRORS, addError);
    });
  }

  private handleAcceptOffer = () => {
    const {
      submitting
    } = this.state;
    const {
      match,
      offers
    } = this.props;

    const bidId = match.params.bidId;

    if (submitting) return;
    this.setState({
      submitting: true
    });

    Network.PATCH<IBidData[]>(`/bids/${bidId}/offer/`, TYPES.JSON, {
      offerId: offers[bidId].offerId,
      accepted: true
    }).then(
      (res) => {
        const {
          updateBid,
        } = this.props;
        updateBid(res[0]);
        this.handleClose();
      }, (err: Error) => {
        const {
          addError
        } = this.props;
        networkErrorHelper(err.message as ERRORS, addError);
      }
    )
  }

  private handleExitBid = () => {
    const {
      match
    } = this.props;

    const bidId = match.params.bidId;

    this.handleClose()
    Network.DELETE<null>(`/bids/${bidId}/`).then(() => {
      const {
        match,
        exitBid
      } = this.props;

      const bidId = match.params.bidId;
      exitBid(bidId);
      this.handleClose();
    }, (err: Error) => {
      const {
        addError
      } = this.props;
      networkErrorHelper(err.message as ERRORS, addError);
    })
  }

  public render() {
    const {
      t,
      bidStates,
      match,
      allbids,
      bids,
      offers,
      immutable
    } = this.props;

    const {
      editorEmpty,
      submitting
    } = this.state;

    const bidId = match.params.bidId;

    let el = allbids.find((el) => {
      return el.bidId === bidId;
    });

    if (el === undefined) {
      el = bids.find((el) => {
        return el.bidId === bidId;
      });
    }

    let offer;
    if (el) {
      offer = offers[bidId];
    }

    if (!el) return null;

    return (el &&
        <div>
            <Dialog
                disableBackdropClick={!editorEmpty}
                disableEscapeKeyDown={!editorEmpty}
                fullWidth
                maxWidth='md'
                onClose={this.handleClose}
                open
            >
                <DialogContent>
                    <DialogContentText />
                    <Grid
                        container
                        spacing={2}
                    >
                        <BidData
                            el={el}
                            immutable={immutable}
                        />
                        <OrderConfirmation
                            bidId={bidId}
                        />
                        <PackingSlip
                            bidId={bidId}
                        />
                        <AllNotes 
                            bidId={bidId}
                        />
                        <BidNotes
                            onChange={this.handleNotesUpdate}
                        />
                        {el.state >= bidStates['Closed'] &&
                            <Offer
                                bidId={bidId}
                            />}
                        <Grid
                            item
                            xs={12}
                        >
                            <Grid
                                alignItems="center"
                                container
                                spacing={1}
                            >
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        justifyContent="flex-start"
                                    >
                                        {el.state !== bidStates['Ordered'] && !el.accepted && !immutable && 
                                        <ExitBid
                                            onClick={this.handleExitBid}
                                        />}
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <DialogActions>
                                        <Button
                                            disabled={!editorEmpty}
                                            onClick={this.handleClose}
                                        >
                                            {t('order.close')}
                                        </Button>
                                        <SubmitBuyer
                                            editorEmpty={editorEmpty}
                                            onClick={this.handleSubmit}
                                            submitting={submitting}
                                        />
                                        {el.state === bidStates['Offer'] && offer && !el.accepted && !immutable &&
                                        <SubmitUser
                                            onClick={this.handleAcceptOffer}
                                            submitting={submitting}
                                        />}
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
  }
}

export default 
  withStyles(styles)(
    withTranslation()(
      connector(
        withRouter(ShowDetails)
      )
    )
  );