import React from "react";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Profile } from '../../../../model/IUserData';
import BlockIcon from '@material-ui/icons/Block';

const styles = (theme: Theme) => createStyles({
    hover: {
        cursor: 'pointer'
    },
    open: {
        color: '#888888',
        borderColor: '#888888'
    },
    closed: {
        color: '#e7b416',
        borderColor: '#e7b416'
    },
    acceptedOffer: {
      color: '#2dc937',
      borderColor: '#2dc937'
    },
    offer: {
        color: '#cc3232',
        borderColor: '#cc3232'
    },
    ordered: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
    }
});

interface IBidsListRowProps extends WithStyles, WithTranslation{
  user: Profile,
  onRowClick: (bidId: string) => void;
}

class BidsListRow extends React.Component<IBidsListRowProps> {

  private handleRowClick = () => {
    const {
      user,
      onRowClick
    } = this.props;

    if (user.contactDetails.id) {
      onRowClick(user.contactDetails.id);
    }
  }


  public render() {
    const {
      classes,
      user
    } = this.props;

    return (
        <TableRow
            className={classes.hover}
            key={user.contactDetails.id}
            onClick={this.handleRowClick}
        >
            <TableCell>
                {user.companyDetails.companyName}
            </TableCell>
            <TableCell>
                {user.contactDetails.phonenumber}
            </TableCell>
            <TableCell>
                {user.contactDetails.email}
            </TableCell>
            <TableCell>
                {`${user.contactDetails.firstName} ${user.contactDetails.lastName}`}
            </TableCell>
            <TableCell>
                {!!user.contactDetails.suspended && <BlockIcon style={{color: 'red'}} />}
            </TableCell>
        </TableRow>
    );
  }
}



export default withStyles(styles)(
    withTranslation()(BidsListRow)
  );
