import { configureStore, Action } from '@reduxjs/toolkit';
import rootReducer from "./reducers";
import { ThunkAction } from 'redux-thunk';
import ISystemState from "../model/ISystemState";

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production'
})

export type AppThunk = ThunkAction<void, ISystemState, unknown, Action<string>>;

export type AppDispatch = typeof store.dispatch;

export default store