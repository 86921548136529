import React from 'react';
import Typography from '@material-ui/core/Typography';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import IBidData from '../../model/IBidData';
import Grid from '@material-ui/core/Grid';
import ShowQuantity from './buyer/ShowQuantity';
import EditQuantity from './user/EditQuantity';
import { formatDate } from '../../utils/Date';
import Network, { TYPES, networkErrorHelper } from '../../utils/Network';
import { updateQuantity } from '../../redux/reducers/bids';
import { updateCommodity, updateManufacturer } from '../../redux/reducers/allbids';
import { connect, ConnectedProps } from 'react-redux';
import ISystemState from "../../model/ISystemState";
import EditableField from "./buyer/EditableField";
import StaticField from "./user/StaticField";
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import TextField from '@material-ui/core/TextField';
import { APP_URL } from '../../utils/Network';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';
import BiddersList from './buyer/BiddersList';


const mapState = (state: ISystemState) => {
    return {
      bidStates: state.bidStates,
      bids: state.bids,
      allbids: state.allbids,
      offers: state.offers
    };
  }

const connector = connect(mapState, { updateQuantity, updateCommodity, updateManufacturer, addError });

interface BidDataProps extends WithTranslation, WithStyles, ConnectedProps<typeof connector> {
  el: IBidData;
  immutable?: boolean;
}

interface IBidDataState {
  tooltipOpen: boolean;
  tooltipText: string;
}

const styles = () => createStyles({
  contrast: {
    color: '#888888'
  }
});

class BidData extends React.Component<BidDataProps, IBidDataState> {
  constructor(props: BidDataProps) {
    super(props);
    this.state = {
      tooltipOpen: false,
      tooltipText: props.t('order.copied')
    }
  }

  private copyRef?: HTMLInputElement;

  private createReference = (ref: HTMLInputElement) => {
    this.copyRef = ref;
  }

  private handleNetworkError = (err: Error) => {
    const {
      addError
    } = this.props;
    networkErrorHelper(err.message as ERRORS, addError);
  }

  private handleTooltipClose = () => {
    this.setState({
      tooltipOpen: false
    });
  };

  private handleCopy = () => {
    const {
      t
    } = this.props;
    if (this.copyRef) {
      const text = this.copyRef.getElementsByTagName('input')[0].value;
      if (!navigator.clipboard) {
        this.setState({
          tooltipOpen: true,
          tooltipText: t('order.cantcopy')
        });
        return;
      }
      navigator.clipboard.writeText(text).then(() => {
        this.setState({
          tooltipOpen: true,
          tooltipText: t('order.copied')
        });
      }, () => {
        this.setState({
          tooltipOpen: true,
          tooltipText: t('order.cantcopy')
        });
      });
    }
  }

  private handleQuantityUpdate = (qty: number, cb: () => void) => {
    const {
      el
    } = this.props;

    Network.PATCH<IBidData[]>(`/bids/${el.bidId}/`, TYPES.JSON, {
      quantity: qty
    }).then((data) => {
      const {
        updateQuantity
      } = this.props;

      updateQuantity(data[0]);
      cb();
    }, this.handleNetworkError);
  }

  private handleCommodityUpdate = (value: string, cb: () => void) => {
    const {
      el
    } = this.props;

    Network.PATCH<IBidData[]>(`/bids/${el.bidId}/`, TYPES.JSON, {
      commodity: value
    }).then((data) => {
      const {
        updateCommodity
      } = this.props;

      updateCommodity(data[0]);
      cb();
    }, this.handleNetworkError);
  }

  private handleManufacturerUpdate = (value: string, cb: () => void) => {
    const {
      el
    } = this.props;

    Network.PATCH<IBidData[]>(`/bids/${el.bidId}/`, TYPES.JSON, {
      manufacturer: value
    }).then((data) => {
      const {
        updateManufacturer
      } = this.props;

      updateManufacturer(data[0]);
      cb();
    }, this.handleNetworkError);
  }

  public render() {
    const {
      classes,
      t,
      el,
      bidStates,
      immutable
    } = this.props;

    const {
      tooltipText,
      tooltipOpen
    } = this.state;

    return (
        <>
            <Grid
                item
                xs={6}
            >
                <EditableField
                    canEdit={el.commodity === ''}
                    caption={t('order.commodity').toString()}
                    errorMessages={[t('order.errors.required')]}
                    onUpdate={this.handleCommodityUpdate}
                    validators={['required', 'isString']}
                    value={el.commodity}
                />
                <StaticField
                    caption={t('order.commodity').toString()}
                    value={el.commodity}
                />
            </Grid>
            <Grid
                item
                xs={6}
            >
                <Typography
                    className={classes.contrast}
                    variant="caption"
                >
                    {`${t('order.product')}: `}
                </Typography>
                <Typography>
                    {el.product}
                </Typography>
            </Grid>
            <Grid
                item
                xs={6}
            >
                <EditableField
                    canEdit={el.manufacturer === ''}
                    caption={t('order.manufacturer').toString()}
                    errorMessages={[t('order.errors.required')]}
                    onUpdate={this.handleManufacturerUpdate}
                    validators={['required', 'isString']}
                    value={el.manufacturer}
                />
                <StaticField
                    caption={t('order.manufacturer').toString()}
                    value={el.manufacturer}
                />
            </Grid>
            <Grid
                item
                xs={6}
            >
                <Typography
                    className={classes.contrast}
                    variant="caption"
                >
                    {el.EAN ? 
                        `${t('order.EAN')}:` :
                        `${t('order.partNumber')}:`}
                </Typography>
                <Typography>
                    {el.EAN ? el.EAN : el.partNumber}
                </Typography>
            </Grid>
            <Grid
                item
                xs={6}
            >
                {/* only the User can edit the quantity of the goods */}
                <ShowQuantity 
                    quantity={el.quantity}
                />
                <EditQuantity
                    canEdit={!immutable && (el.state === bidStates['Open'])}
                    onUpdate={this.handleQuantityUpdate}
                    quantity={el.quantity}
                />
            </Grid>
            <Grid
                item
                xs={6}
            >
                <Typography
                    className={classes.contrast}
                    variant="caption"
                >
                    {`${t('order.neededBy')}:`}
                </Typography>
                <Typography>
                    {formatDate(new Date(el.byDate))}
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
            >
                <BiddersList
                    bidId={el.bidId}
                />
            </Grid>
            {!el.immediate && el.state === bidStates.Open && !immutable &&
            <Grid
                item
                xs={6}
            >
                <Grid
                    alignItems="flex-end"
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={6}
                    >
                        <TextField
                            fullWidth
                            id="sharable-link"
                            label={t('order.shareLink')}
                            ref={this.createReference}
                            value={`${APP_URL}/#/en_US/join/${el.bidId}`}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={2}
                    >
                        <Box
                            alignItems="flex-end"
                            display="flex"
                            justifyContent="flex-start"
                        >
                            <ClickAwayListener
                                onClickAway={this.handleTooltipClose}
                            >
                                <Tooltip
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    open={tooltipOpen}
                                    title={tooltipText}
                                >
                                    <IconButton
                                        onClick={this.handleCopy}
                                    >
                                        <AssignmentReturnedIcon 
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </ClickAwayListener>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>}
        </>
    );
  }

}

export default 
  withStyles(styles)(
    withTranslation()(
        connector(BidData)
    )
  );