import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Roles } from '../model/Roles';
import { withRoles, IWithRoleProps } from './Roles';
import { connect, ConnectedProps } from "react-redux";
import ISystemState from '../model/ISystemState';

const mapState = (state: ISystemState) => {
  return {
    locale: state.user.locale
  };
};
const connector = connect(mapState);

interface ISetLanguageProps extends IWithRoleProps, WithTranslation, ConnectedProps<typeof connector>{}

class SetLanguage extends React.Component<ISetLanguageProps> {

  public render() {
    const {
      locale,
      i18n
    } = this.props;

    if (i18n.language !== locale) {
      void i18n.changeLanguage(locale);
      location.hash = `/${locale}`;
    }
    return null;
  }
}

export default connector(
  withTranslation()(
    withRoles(SetLanguage, [Roles.User, Roles.Buyer, Roles.Guest])
  )
);