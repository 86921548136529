import { createSlice } from "@reduxjs/toolkit";
import ISystemState from '../../model/ISystemState';

const initialState = <ISystemState>{};

const signoutSlice = createSlice({
  name: 'signout',
  initialState: initialState,
  reducers: {
    'signout': () => {
      return <ISystemState>{};
    }
  }
});



export const { signout } = signoutSlice.actions;

export default signoutSlice.reducer;

