import { combineReducers } from "redux";
import bids from "../reducers/bids";
import allbids from "../reducers/allbids";
import openBids from "../reducers/openBids";
import bidnotes from "../reducers/allnotes";
import user from "../reducers/user";
import bidStates from "../reducers/bidStates";
import industries from "../reducers/industries";
import countries from "../reducers/countries";
import vendors from "../reducers/vendors";
import offers from "../reducers/offers";
import errors from "../reducers/errors";
import warnings from "../reducers/warnings";
import signout from "../reducers/signout";
import confirmations from "../reducers/orderconfirmation";
import packingslips from "../reducers/packingslip";
import users from "../reducers/users";
import bidders from "../reducers/bidders";

export default combineReducers({ 
  bidStates, 
  bids, 
  allbids, 
  bidnotes, 
  confirmations,
  packingslips,
  user, 
  industries, 
  countries,
  vendors,
  offers,
  errors,
  warnings,
  signout,
  users,
  openBids,
  bidders
 });
