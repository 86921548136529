import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IBidData from "../../model/IBidData";
import { AppThunk } from '../store';
import Network, { TYPES } from "../../utils/Network";
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';

const initialState = Array<IBidData>();

const bids = createSlice({
  name: 'bids',
  initialState: initialState,
  reducers: {
    'addOwnBid': (state = initialState, action: PayloadAction<IBidData>) => {
      state.unshift(action.payload);
    },
    'addOwnBids': (state = initialState, action: PayloadAction<IBidData[]>) => {
      return state.concat(action.payload);
    },
    'exitBid': (state = initialState, action: PayloadAction<string>) => {
      const idx = state.findIndex((el) => {
        return el.bidId === action.payload;
      });
      state.splice(idx, 1);
      return state;
    },
    'updateQuantity': (state = initialState, action: PayloadAction<IBidData>) => {
      const idx = state.findIndex((el) => {
        return el.bidId === action.payload.bidId;
      });
      state[idx].quantity = action.payload.quantity;
      return state;
    },
    'updateBid': (state = initialState, action: PayloadAction<IBidData>) => {
      const idx = state.findIndex((el) => {
        return el.bidId === action.payload.bidId;
      });
      state[idx] = action.payload;
      return state;
    }
  }
});

export const {
  addOwnBid,
  addOwnBids,
  exitBid,
  updateQuantity,
  updateBid
} = bids.actions;

export default bids.reducer;

export const fetchOwnBids = (): AppThunk => (dispatch) => {
  Network.GET<IBidData[]>('/bids', TYPES.JSON).then((json) => {
    if (json) {
      dispatch(addOwnBids(json));
    }
  }, (err: Error) => {
    if (err.message in ERRORS) {
      dispatch(addError(err.message as ERRORS));
      return;
    }
    dispatch(addError(ERRORS.NETWORK_ERROR));
  });
}
