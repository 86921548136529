import React from "react";
import { Roles } from '../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../Roles';
import GenericEditableField from "../../forms/EditableField";


interface IEditableFieldProps extends IWithRoleProps {
  caption: string;
  value: string;
  onUpdate: (qty: string, cb: () => void) => void;
  onChange?: () => void;
  canEdit: boolean;
  validators: string[];
  errorMessages: string[];
}

class EditableField extends React.Component<IEditableFieldProps> {

  public render() {
    const {
      caption,
      value,
      onUpdate,
      onChange,
      canEdit,
      validators,
      errorMessages
    } = this.props;

    return (
        <GenericEditableField
            canEdit={canEdit}
            caption={caption}
            errorMessages={errorMessages}
            onChange={onChange}
            onUpdate={onUpdate}
            validators={validators}
            value={value}
        />
    );
  }
}



export default withRoles(EditableField, [Roles.Buyer]);
