import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { connect, ConnectedProps } from "react-redux";
import ISystemState from "./model/ISystemState";
import { HashRouter } from "react-router-dom";
import Footer from './Footer';
import Signin from './components/signin/Signin';
import Register from './components/signin/Register';
import store from './redux/store';
import { fetchUser } from './redux/reducers/user';
import Dashboard from './components/dashboard/Dashboard';
import Populatestore from './components/PopulateStore';
import Error from './Error';
import Warning from './Warning';
import SetLangauge from './components/SetLanguage';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';


const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
});


const mapState = (state: ISystemState) => {
  return {
    locale: state.user.locale
  };
};

const connector = connect(mapState);

interface IAppProps extends ConnectedProps<typeof connector>, WithStyles{}

class App extends React.Component<IAppProps> {
  public shouldComponentUpdate() {
    return true;
  }

  private handleRegistration = () => {
    (store.dispatch as ThunkDispatch<ISystemState, void, AnyAction>)(fetchUser());
  }

  private handleSignIn = () => {
    (store.dispatch as ThunkDispatch<ISystemState, void, AnyAction>)(fetchUser());
  }

  public render() {
    const {
      classes
    } = this.props;

    return (
        <HashRouter>
            <CssBaseline />
            <div className={classes.root}>
                <Signin onChange={this.handleSignIn} />
                <Register onSuccess={this.handleRegistration} />
                <Error />
                <Warning />
                <SetLangauge />
                <Dashboard />
                <Populatestore />
                <Footer />
            </div>
        </HashRouter>
    );
  }

}

export default withStyles(styles)(connector(App));


