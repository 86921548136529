import React from "react";
import { Roles } from '../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../Roles';
import Typography from '@material-ui/core/Typography';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';

interface IStaticFieldProps extends IWithRoleProps, WithStyles {
  value: string;
  caption: string;
}

const styles = () => createStyles({
  contrast: {
    color: '#888888'
  }
});

class StaticField extends React.Component<IStaticFieldProps> {

  public render() {
    const {
      classes,
      caption,
      value
    } = this.props;

    return (
        <>
            <Typography
                className={classes.contrast}
                variant="caption"
            >
                {`${caption}:`}
            </Typography>
            <Typography>
                {value}
            </Typography>
        </>
    );
  }
}



export default withStyles(styles)(
  withRoles(StaticField, [Roles.User])
);
