import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { addError } from '../../redux/reducers/errors';
import { connect, ConnectedProps } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Roles } from '../../model/Roles';
import { withRoles, IWithRoleProps } from '../Roles';

const connector = connect(null, { addError });

interface ISigninProps extends  WithStyles, WithTranslation, IWithRoleProps, ConnectedProps<typeof connector>  {
  onChange(signedIn: boolean): void;
  noDecoration?: boolean;
}

const REACT_APP_REMOTE_URL = process.env.REACT_APP_REMOTE_URL || '';

const styles = (theme: Theme) => createStyles({
  main: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: 'center'
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
});

class Signin extends React.Component<ISigninProps> {

  private handleSignIn = () => {
    location.assign(REACT_APP_REMOTE_URL + '/auth');
  }


  private getButton = () => {
    const {
      t
    } = this.props;

    return (
        <Button
            color="primary"
            component="span"
            onClick={this.handleSignIn}
            style={{justifyContent: 'center'}}
            variant="contained"
        >
            {t('signin.buttontext')}
        </Button>)
  }

  public render() {
    const {
      classes,
      t,
      noDecoration
    } = this.props;

    if (noDecoration) {
      return (
          <Grid
              container
              justify="center"
          >
              {this.getButton()}
          </Grid>
      );
    }

    return (
        <Container
            className={classes.container}
            component="main"
            maxWidth="sm"
        >
            <div>
                <Grid
                    container
                    justify="center"
                >
                    <img
                        alt="EoS4U"
                        height="30%"
                        src="./assets/logo.png"
                        width="30%"
                    />
                </Grid>
                <Container
                    className={classes.main}
                    component="main"
                >
                    <Typography
                        component="h3"
                        gutterBottom
                        variant="h6"
                    >
                        {t('signin.welcometext')}
                    </Typography>
                </Container>
                <Grid
                    container
                    justify="center"
                >
                    {this.getButton()}
                </Grid>
            </div>
        </Container>
    );
  }
}

export default connector(
  withRoles(
    withStyles(styles)(
      withTranslation()(Signin)
    ),
    [Roles.Anonymous]
  )
);
