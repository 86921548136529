import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BiddersDict } from '../../model/ISystemState';

const initialState = {} as BiddersDict;

const bids = createSlice({
  name: 'bidders',
  initialState: initialState,
  reducers: {
    'addBidders': (state = initialState, action: PayloadAction<BiddersDict>) => {
      const payload = action.payload;
      for(const key in payload) {
        state[key] = payload[key];
      }
      return state;
    }
  }
});

export const {
  addBidders
} = bids.actions;

export default bids.reducer;
