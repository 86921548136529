import React from "react";
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';

interface ITwoStageButtonProps{
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  labelText: string;
  buttonText: string;
  classNameLabel?: string;
  classNameButton?: string;
  tooltipText?: string;
  name: string;
  inProgress?: boolean;
}

interface ITwoStageButtonState {
  confirmed: boolean;
  submitting: boolean;
}

class TwoStageButton extends React.Component<ITwoStageButtonProps, ITwoStageButtonState> {

  constructor(props: ITwoStageButtonProps) {
    super(props);
    this.state = {
      confirmed: false,
      submitting: false
    };
  }

  private handleConsent = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirmed: event.target.checked });
  }

  private handleClick = () => {
    const {
      onClick
    } = this.props;
    const {
      submitting
    } = this.state;

    if (submitting) return;

    this.setState({
      submitting: true
    });

    if (onClick) {
      onClick();
    }
  }

  public render(): JSX.Element {
    const {
      confirmed,
      submitting
    } = this.state;

    const {
      labelText,
      buttonText,
      classNameLabel,
      classNameButton,
      name,
      onClick,
      type,
      inProgress,
      tooltipText
    } = this.props;

    return (
        <>
            {!confirmed &&
                <FormControlLabel
                    className={classNameLabel}
                    control={
                        <Checkbox
                            className={classNameLabel}
                            name={name}
                            onChange={this.handleConsent}
                            value={false}
                        />
                    }
                    label={labelText}
                />}
            {confirmed &&
                <Tooltip
                    title={tooltipText || ''}
                >
                    <Button
                        className={classNameButton}
                        color="primary"
                        disabled={submitting || inProgress}
                        onClick={onClick && this.handleClick}
                        type={type}
                        variant="contained"
                    >
                        {buttonText}
                        {(submitting || inProgress) &&
                            <CircularProgress
                                size={24}
                            />}
                    </Button>
                </Tooltip>}
        </>
    );
  }
}



export default TwoStageButton;
