import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

const BLOCK_TYPES = [
  {label: 'H1', style: 'header-one'},
  {label: 'H2', style: 'header-two'},
  {label: 'H3', style: 'header-three'},
  {label: 'H4', style: 'header-four'},
  {label: 'H5', style: 'header-five'},
  {label: 'H6', style: 'header-six'},
  {label: 'Blockquote', icon: FormatQuoteIcon, style: 'blockquote'},
  {label: 'UL', icon: FormatListBulletedIcon, style: 'unordered-list-item'},
  {label: 'OL', icon: FormatListNumberedIcon, style: 'ordered-list-item'}
];

interface IBlockStyleControlsState {
  format: string
}

interface IProps {
  editorState: Draft.EditorState;
  onToggle: (style: string) => void;
}

class BlockStyleControls extends React.Component<IProps, IBlockStyleControlsState>{
  constructor(props: IProps) {
    super(props);

    this.state = {
      format: ''
    };
  }

  private handleFormat = (event: React.MouseEvent<HTMLElement>, newFormat: string) => {
    const {
      onToggle
    } = this.props;

    onToggle(newFormat);
    this.setState({
      format: newFormat
    });
  }

  public render(): JSX.Element {
    const {
      editorState
    } = this.props;

    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return (
        <div>
            <ToggleButtonGroup
                exclusive
                onChange={this.handleFormat}
                value={blockType}
            >
                {BLOCK_TYPES.map((type) => (
                    <ToggleButton
                        key={type.label}
                        value={type.style}
                    >
                        {(type.icon !== undefined) ? <type.icon /> : type.label}
                    </ToggleButton>)
                )}
            </ToggleButtonGroup>
        </div>
      );
  }
}

export default BlockStyleControls;
