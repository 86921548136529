import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Profile } from "../../model/IUserData";

const initialState = <Profile[]>[];

const users = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {
    'setUsers': (state = initialState, action: PayloadAction<Profile[]>) => {
      state = action.payload;
      return state;
    },
    'setVerified': (state = initialState, action: PayloadAction<string>) => {
      const idx = state.findIndex((el) => {
        return el.contactDetails.id === action.payload;
      });
      
      if (idx !== -1) {
        state[idx].contactDetails.verified = true;
      }
      return state;
    },
    'setSuspended': (state = initialState, action: PayloadAction<string>) => {
      const idx = state.findIndex((el) => {
        return el.contactDetails.id === action.payload;
      });
      
      if (idx !== -1) {
        state[idx].contactDetails.suspended = !state[idx].contactDetails.suspended;
      }
      return state;
    },
    'setArchived': (state = initialState, action: PayloadAction<string>) => {
      const idx = state.findIndex((el) => {
        return el.contactDetails.id === action.payload;
      });
      
      if (idx !== -1) {
        state[idx].contactDetails.archived = !state[idx].contactDetails.archived;
      }
      return state;
    }
  }
});

export const {
  setUsers,
  setVerified,
  setSuspended,
  setArchived
} = users.actions;

export default users.reducer;
