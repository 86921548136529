/* eslint-disable react/no-danger */
import React from 'react';
import 'draft-js/dist/Draft.css';
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import { Roles } from "../../../model/Roles";
import { withRoles, IWithRoleProps } from '../../Roles';
import { connect, ConnectedProps } from 'react-redux';
import ISystemState from "../../../model/ISystemState";
import { fetchNotes } from '../../../redux/reducers/allnotes';
import store from '../../../redux/store';
import Container from '@material-ui/core/Container';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';

const styles = () => createStyles({
  container: {
    width: '100%',
    border: '1px solid #CCCCCC',
    height: '300px',
    overflow: 'scroll',
    padding: '1em'
  },
  contrast: {
    color: '#888888'
  }
});

const mapState = (state: ISystemState) => {
  return {
    bidnotes: state.bidnotes
  };
}

const connector = connect(mapState);

interface IAllNotesProps extends WithTranslation, WithStyles, IWithRoleProps, 
  ConnectedProps<typeof connector> {
    bidId: string;
}

class AllNotes extends React.Component<IAllNotesProps> {

  public componentDidMount() {
    const {
      bidnotes,
      bidId
    } = this.props;

    if (!bidnotes[bidId]) {
      (store.dispatch as ThunkDispatch<ISystemState, void, AnyAction>)(fetchNotes(bidId));
    }
  }

  public render() {
    const {
      bidnotes,
      bidId
    } = this.props;

    let notes = bidnotes[bidId];
    if (!notes) {
      notes = [];
    }

    return (
        <Grid
            item
            xs={12}
        >
            {notes.map((el, idx) => (
                <Container key={el.noteId}>
                    {idx === 0 && <Divider />}
                    <div
                        className="eou4u-note"
                        dangerouslySetInnerHTML={{ __html: el.note }}
                    />
                    <Divider />
                </Container>
            ))}
        </Grid>
    );
  }
}

export default
  connector(
    withRoles(
      withStyles(styles)(
        withTranslation()(AllNotes)
      ), [Roles.Buyer])
  );