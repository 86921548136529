import React from 'react';
import ISystemState from './model/ISystemState';
import { connect, ConnectedProps } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { resetErrors } from './redux/reducers/errors';

const mapState = (state: ISystemState) => {
  return {
    errors: state.errors
  };
}

const connector = connect(mapState, { resetErrors });

interface IErrorProps extends  WithTranslation, ConnectedProps<typeof connector> {}

interface IErrorState {
  open?: boolean;
}

class Error extends React.Component<IErrorProps, IErrorState> {

  private handleClose = () => {
    const {
      resetErrors
    } = this.props;

    resetErrors();
  }

  public render() {
    const {
      t,
      errors
    } = this.props;


    if (!errors.length) {
      return (
          null
      );
    }

    return (
        <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            autoHideDuration={60000}
            onClose={this.handleClose}
            open
        >
            <MuiAlert
                elevation={6}
                onClose={this.handleClose}
                severity="error"
                variant="filled"
            >
                {errors.map((el) => {
                  return (
                      <div key={el}>
                          {t(`globalErrors.${el}`)}
                      </div>
                  );
                })}
            </MuiAlert>
        </Snackbar>
    )
  }
}
export default connector(
    withTranslation()(Error)
);