/* eslint-disable */
import React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import FormHelperText from '@material-ui/core/FormHelperText';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export class CheckboxValidator extends ValidatorComponent {

  renderValidatorComponent() {
    const {
      label,
      name,
      value,
      onChange
    } = this.props;

    return (
      <React.Fragment>
        <FormControlLabel
          control={
            <Checkbox 
              onChange={onChange}
              color="secondary" 
              name={name}
              value={value} />}
          label={label}
        />
        {this.errorText()}
      </React.Fragment>
    );
  }

  errorText() {
      const { isValid } = this.state;

      if (isValid) {
          return null;
      }

      return (
          <FormHelperText error>
              {this.getErrorMessage()}
          </FormHelperText>
      );
  }
}

export default CheckboxValidator;