import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from "react-redux";
import ISystemState from '../../model/ISystemState';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Network, { TYPES, networkErrorHelper } from '../../utils/Network';
import { setLocale } from '../../redux/reducers/user';
import { IUserSettings } from '../../model/IUserData';
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';


const styles = (theme: Theme) => createStyles({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  fullwidth: {
    width: '100%'
  }
})


const mapState = (state: ISystemState) => {
  return {
    language: state.user.locale
  };
};
const connector = connect(mapState, { setLocale, addError });

interface IPreferencesState {
  submitting: boolean;
  language: string;
}

interface IPreferencesProps extends ConnectedProps<typeof connector>,  WithTranslation, WithStyles {}

class Preferences extends React.Component<IPreferencesProps, IPreferencesState> {
  constructor(props: IPreferencesProps) {
    super(props);
    this.state = {
      submitting: false,
      language: props.language || location.hash.split('/')[1]
    };
  }

  private handleSubmit = () => {
    const {
      submitting,
      language
    } = this.state;

    if (submitting) return;

    this.setState({
      submitting: true
    });

    Network.PUT<IUserSettings>('/users/settings', TYPES.JSON, {locale: language}).then((res) => {
      const {
        setLocale
      } = this.props;
      setLocale(res.locale);
      this.setState({
        submitting: false
      });
    }, (err: Error) => {
      const {
        addError
      } = this.props;
      networkErrorHelper(err.message as ERRORS, addError);
    });
  }

  private handleLanguage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      language: event.target.value
    })
  }

  public render() {
    const {
      submitting,
      language
    } = this.state;
    const {
      t,
      classes
    } = this.props;

    return (
        <ValidatorForm
            className={classes.fullwidth}
            instantValidate={false}
            onSubmit={this.handleSubmit}
        >
            <Grid
                container
                spacing={3}
                xs={12}
            >
                <Grid
                    item
                    xs={6}
                >
                    {t('profile.language')}
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <SelectValidator
                        fullWidth
                        id="language"
                        name="language"
                        onChange={this.handleLanguage}
                        value={language}
                    >
                        <MenuItem value='en_US'>
                            {t('English')}
                        </MenuItem>
                        <MenuItem value='de_DE'>
                            {t('Deutsch')}
                        </MenuItem>
                        <MenuItem value='es_ES'>
                            {t('Español')}
                        </MenuItem>
                        <MenuItem value='ru_RU'>
                            {t('Русский')}
                        </MenuItem>
                    </SelectValidator>
                </Grid>
            </Grid>
            <div className={classes.buttons}>
                <Button
                    className={classes.button}
                    color="primary"
                    disabled={submitting}
                    type="submit"
                    variant="contained"
                >
                    {t('profile.saveChanges')}
                    {submitting &&
                        <CircularProgress 
                            className={classes.buttonProgress}
                            size={24}
                        />}
                </Button>
            </div>
        </ValidatorForm>);
  }
}

export default connector(
  withStyles(styles)(
    withTranslation()(Preferences)
  )
);
