/* eslint-disable react/no-danger */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Roles } from '../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../Roles';
import Link from '@material-ui/core/Link';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import { SERVER_URL, version } from '../../../utils/Network';
import Typography from '@material-ui/core/Typography';

interface IInvoiceProps extends WithTranslation, IWithRoleProps {
    bidId: string;
}

class Invoice extends React.Component<IInvoiceProps> {

  public render() {
    const {
      bidId,
      t
    } = this.props;

    return (
        <Grid
            item
            xs={12}
        >
            <Link
                color="primary"
                href={SERVER_URL + version + `/invoice/${bidId}`}
                target="_blank"
            >
                <Grid
                    alignContent="center"
                    container
                    spacing={1}
                >
                    <Grid
                        item
                    >
                        <CloudDownloadOutlinedIcon />
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography>
                            {t('order.details.downloadInvoice')}
                        </Typography>
                    </Grid>
                </Grid>
            </Link>
        </Grid>
    );
  }
}

export default withRoles(
  withTranslation()(Invoice), [Roles.User]
);