import React from 'react';
import { Roles } from '../../model/Roles';
import { withRoles, IWithRoleProps } from '../Roles';
import Grid from '@material-ui/core/Grid';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from "react-redux";
import ISystemState from "../../model/ISystemState";
import Network, { TYPES, networkErrorHelper } from '../../utils/Network';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { setProfile, setCompanyDetails, setBillingDetails, setContactDetails } from '../../redux/reducers/user';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Container from'@material-ui/core/Container';
import { Profile as ProfileType} from '../../model/IUserData';
import IAddressData from '../../model/IAddressData';
import IContactDetails from '../../model/IContactDetails';
import IIndustry from '../../model/IIndustry';
import ContactDetails from '../signin/ContactDetails';
import AddressForm from '../signin/AddressForm';
import Preferences from './Preferences';
// import Developer from './Developer';
import Payment from './Payment';
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';


const styles = (theme: Theme) => createStyles({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  }
});

const mapState = (state: ISystemState) => {
  return {
    profile: state.user.profile
  };
};
const connector = connect(mapState, {
  setProfile,
  setCompanyDetails,
  setBillingDetails,
  setContactDetails,
  addError
});

interface IProfileProps extends ConnectedProps<typeof connector>, IWithRoleProps, WithStyles, WithTranslation{}

let key = 0;

class Profile extends React.Component<IProfileProps> {

  public componentDidMount = () => {
    const {
      profile
    } = this.props;

    if (!profile.billingDetails) {
      Network.GET<ProfileType>('/users/', TYPES.JSON).then((res) => {
        const {
          setProfile
        } = this.props;
        key++;
        if (res) {
          setProfile(res);
        }
      }, this.handleNetworkError);
    }
  }

  private handleNetworkError = (err: Error) => {
    const {
      addError
    } = this.props;
    networkErrorHelper(err.message as ERRORS, addError);
  }

  private handleCompanyDetails = (data: IAddressData, cb: () => void) => {
    if (!data.address2) {
      data.address2 = '';
    }
    if (!data.state) {
      data.state = '';
    }
    if (!data.industry) {
      data.industry = {name: ''} as IIndustry;
    }

    Network.PUT<IAddressData>('/users/companydetails/', TYPES.JSON, data as unknown as Record<string, unknown>).then((res) => {
      const {
        setCompanyDetails
      } = this.props;
      key++;
      setCompanyDetails(res);
      cb();
    }, this.handleNetworkError);
  }

  private handleBillingDetails = (data: IAddressData, cb: () => void) => {
    if (!data.address2) {
      data.address2 = '';
    }
    if (!data.state) {
      data.state = '';
    }

    Network.PUT<IAddressData>('/users/billingdetails/', TYPES.JSON, data as unknown as Record<string, unknown>).then((res) => {
      const {
        setBillingDetails
      } = this.props;
      key++;
      setBillingDetails(res);
      cb();
    }, this.handleNetworkError);
  }

  private handleContactDetails = (data: IContactDetails, cb: () => void) => {
    Network.PUT<IContactDetails>('/users/contactdetails/', TYPES.JSON, data as unknown as Record<string, unknown>).then((res) => {
      const {
        setContactDetails
      } = this.props;
      key++;
      setContactDetails(res);
      cb();
    }, this.handleNetworkError);
  }

  public render() {
    const {
      profile,
      classes,
      t
    } = this.props;

    return (
        <Grid
            className={classes.paper}
            container
            direction="column"
            justify="center"
            spacing={0}
        >
            <Grid
                item
                xs={12}
            >
                <Container
                    maxWidth='lg'
                >
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id="panel1a-header"
                        >
                            <Typography>
                                {t('signup.companyDetails')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AddressForm
                                buttonText={t('profile.saveChanges')}
                                companyDetails={profile.companyDetails ? profile.companyDetails : undefined}
                                isBilling={false}
                                key={`profile.companyDetails${key}`}
                                onChange={this.handleCompanyDetails}
                                preserve
                                supressCheckbox
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id="panel1a-header"
                        >
                            <Typography>
                                {t('signup.billingAddress')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AddressForm
                                billingDetails={profile.billingDetails ? profile.billingDetails : undefined}
                                buttonText={t('profile.saveChanges')}
                                isBilling
                                key={`profile.billingDetails${key}`}
                                onChange={this.handleBillingDetails}
                                preserve
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id="panel1a-header"
                        >
                            <Typography>
                                {t('signup.contactDetails')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ContactDetails
                                buttonText={t('profile.saveChanges')}
                                contactDetails={profile.contactDetails ? profile.contactDetails : undefined}
                                emailDisabled
                                key={`profile.contactdetails${key}`}
                                onChange={this.handleContactDetails}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id="panel1a-header"
                        >
                            <Typography>
                                {t('profile.preferences')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Preferences />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id="panel1a-header"
                        >
                            <Typography>
                                {t('profile.subscription')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Payment />
                        </AccordionDetails>
                    </Accordion>
                    {/* {process.env.NODE_ENV !== 'production' && 
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                id="panel1a-header"
                            >
                                <Typography>
                                    {t('profile.developer')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Developer />
                            </AccordionDetails>
                        </Accordion>} */}
                </Container>
            </Grid>
        </Grid>);
  }
}

export default withRoles(
  connector(
    withStyles(styles)(
      withTranslation()(Profile)
    )
  ),
[Roles.User]);