import React from 'react';
import { Roles } from "../../../model/Roles";
import { withRoles, IWithRoleProps } from '../../Roles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Title from '../Title';
import { withTranslation, WithTranslation } from 'react-i18next';
import ProductCard from './ProductCard';
import ShowDetails from '../ShowDetails';
import OfferDialog from './OfferDIalog';
import PODialog from './PODIalog';
import OrderConfirmedDialog from './OrderConfirmedDialog';
import DisptachedDialog from './DisptachedDialog';
import { connect, ConnectedProps } from "react-redux";
import ISystemState from "../../../model/ISystemState";
import IBidData from "../../../model/IBidData";
import Network, { TYPES, networkErrorHelper } from '../../../utils/Network';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { advance } from '../../../redux/reducers/allbids';
import { Switch, Route, withRouter, RouteComponentProps } from "react-router-dom";
import { addError } from '../../../redux/reducers/errors';
import { ERRORS } from '../../../model/Errors';

interface MatchParams {
  bidId: string;
}

const NUM_COLUMNS = 10;
const COLUMN_WIDTH = 220;
const styles = () => createStyles({
  outer: {
    height: "100%",
    width: "100%"
  },
  column: {
    height: "100%",
    minWidth: `${COLUMN_WIDTH}px`,
    borderRight: "1px dashed #CCCCCC",
    borderLeft: "1px dashed #CCCCCC",
    overflow: "auto",
  },
  container: {
    // height: '100%',
    height: "calc(100% - 65px)",
    paddingTop: "30px",
    flexGrow: 1,
    minWidth: `${(NUM_COLUMNS + 1) * COLUMN_WIDTH}px`
  },
  even: {
    backgroundColor: "#f6f6f6"
  }
});

const mapState = (state: ISystemState) => {
  return {
    allbids: state.allbids,
    bidStates: state.bidStates
  };
};
const connector = connect(mapState, { advance, addError });

interface IProcessBoardProps extends ConnectedProps<typeof connector>, IWithRoleProps, WithStyles, WithTranslation, RouteComponentProps<MatchParams>{}

enum Dialogs {
  NONE,
  DETAILS,
  OFFER,
  PURCHASE_ORDER,
  ORDER_CONFIRMED,
  DISPATCHED
}

interface IProcessBoardState {
  currentDialog: Dialogs;
  dialogBidId: string;
}

class ProcessBoard extends React.Component<IProcessBoardProps, IProcessBoardState> {

  constructor(props: IProcessBoardProps) {
    super(props);
    this.state = {
      currentDialog: Dialogs.NONE,
      dialogBidId: ''
    }
  }

  private handleAdvance = (bidId: string, cb: () => void) => {
    Network.PATCH<IBidData[]>(`/bids/${bidId}`, TYPES.JSON, {
      toNextState: true
    }).then((data) => {
      const {
        advance
      } = this.props;
      advance(data);
      cb();
    }, (err: Error) => {
      const {
        addError
      } = this.props;
      networkErrorHelper(err.message as ERRORS, addError);
    });
  }

  private handleSendOffer = (bidId: string, cb: () => void) => {
    this.setState({
      currentDialog: Dialogs.OFFER,
      dialogBidId: bidId
    });
    cb();
  }

  private handleSendPO = (bidId: string, cb: () => void) => {
    this.setState({
      currentDialog: Dialogs.PURCHASE_ORDER,
      dialogBidId: bidId
    });
    cb();
  }

  private handleShowDetails = (bidId: string) => {
    this.setState({
      currentDialog: Dialogs.DETAILS,
      dialogBidId: bidId
    });
  }

  private handleCloseDialog = () => {
    const {
      i18n,
      history
    } = this.props;
    history.push(`/${i18n.language}/home`);
    this.setState({
      currentDialog: Dialogs.NONE
    });
  }

  private handleOrderConfirmed = (bidId: string) => {
    this.setState({
      currentDialog: Dialogs.ORDER_CONFIRMED,
      dialogBidId: bidId
    });
  }

  private handleDispatched = (bidId: string) => {
    this.setState({
      currentDialog: Dialogs.DISPATCHED,
      dialogBidId: bidId
    });
  }

  private offerAccepted = (el: IBidData) => {
    const {
      bidStates
    } = this.props;
    return el.state === bidStates['Offer'] && !!el.accepted;
  }

  private offerNotAccepted = (el: IBidData) => {
    const {
      bidStates
    } = this.props;
    return el.state === bidStates['Offer'] && !el.accepted;
  }

  private getStateColumn = (title: string, state: number|((el: IBidData) => boolean),
      onAdvance?: (bidId: string, cb: () => void) => void) => {
    const {
      allbids,
      classes
    } = this.props

    const xs = 1;

    return (
        <Grid
            className={classes.column}
            item
            xs={xs}
        >
            <Title>
                {title}
            </Title>
            {allbids.map((el) => {
                if (
                  (Number.isInteger(state) && el.state === state) ||
                  (state instanceof Function) && state(el)
                ) {
                  return (
                      <ProductCard 
                          bidId={el.bidId}
                          byDate={el.byDate}
                          commodity={el.commodity}
                          companyName={el.companyName || ''}
                          deliveryDate={el.deliveryDate || ''}
                          dispatchedDate={el.dispatchedDate || ''}
                          immediate={el.immediate}
                          key={el.bidId}
                          onButtonClick={onAdvance}
                          onDetailsShow={this.handleShowDetails}
                          product={el.product}
                          timestamp={el.timestamp}
                      />);
                }
                return null;
              })}
        </Grid>
    );
  }

  public render() {
    const {
      currentDialog,
      dialogBidId
    } = this.state;

    const {
      bidStates,
      classes,
      t,
      i18n
    } = this.props;

    return (
        <>
            <Container
                className={classes.container}
                maxWidth={false}
            >
                <Grid
                    alignItems="flex-start"
                    className={classes.outer}
                    container
                    justify="flex-start"
                    spacing={1}
                >
                    {this.getStateColumn(
                        t('processBoard.open'),
                        bidStates['Open'],
                        this.handleAdvance
                    )}
                    {this.getStateColumn(
                        t('processBoard.closed'),
                        bidStates['Closed'],
                        this.handleSendOffer
                    )}
                    {this.getStateColumn(
                        t('processBoard.offer'),
                        this.offerNotAccepted
                    )}
                    {this.getStateColumn(
                        t('processBoard.toOrder'),
                        this.offerAccepted,
                        this.handleSendPO
                    )}
                    {this.getStateColumn(
                        t('processBoard.ordered'),
                        bidStates['Ordered'],
                        this.handleOrderConfirmed
                    )}
                    {this.getStateColumn(
                        t('processBoard.confirmed'),
                        bidStates['Confirmed'],
                        this.handleDispatched
                    )}
                    {this.getStateColumn(
                        t('processBoard.dispatched'),
                        bidStates['Dispatched'],
                        this.handleAdvance
                    )}
                    {this.getStateColumn(
                        t('processBoard.pickup'),
                        bidStates['Pickup'],
                        this.handleAdvance
                    )}
                    {this.getStateColumn(
                        t('processBoard.delivered'),
                        bidStates['Delivered']
                    )}
                    {this.getStateColumn(
                        t('processBoard.paid'),
                        bidStates['Paid']
                    )}
                </Grid>
            </Container>
            <Switch>
                <Route
                    exact
                    path={`/${i18n.language}/home/:bidId`}
                >
                    <ShowDetails
                        onClose={this.handleCloseDialog} 
                    />
                </Route>
            </Switch>
            {currentDialog === Dialogs.OFFER && 
                <OfferDialog
                    bidId={dialogBidId}
                    onClose={this.handleCloseDialog}
                    onSend={this.handleAdvance}
                    open
                />}
            {currentDialog === Dialogs.PURCHASE_ORDER &&
                <PODialog
                    bidId={dialogBidId}
                    onClose={this.handleCloseDialog}
                    onSend={this.handleAdvance}
                    open
                />}
            {currentDialog === Dialogs.ORDER_CONFIRMED &&
                <OrderConfirmedDialog
                    bidId={dialogBidId}
                    onClose={this.handleCloseDialog}
                    onSend={this.handleAdvance}
                    open
                />}
            {currentDialog === Dialogs.DISPATCHED &&
                <DisptachedDialog
                    bidId={dialogBidId}
                    onClose={this.handleCloseDialog}
                    onSend={this.handleAdvance}
                    open
                />}
        </>
    );
  }
}

export default withRoles(
  connector(
    withStyles(styles)(
      withTranslation()(
        withRouter(ProcessBoard)
      )
    )
  ),
[Roles.Buyer]);