import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ERRORS } from '../../model/Errors';

const initialState = Array<ERRORS>();

const errors = createSlice({
  name: 'errors',
  initialState: initialState,
  reducers: {
    'addError': (state = initialState, action: PayloadAction<ERRORS>) => {
      state.push(action.payload);
      return state;
    },
    'resetErrors':  (state = initialState) => {
      state = [];
      return state;
    }
  }
});



export const { addError, resetErrors } = errors.actions;

export default errors.reducer;

