import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from '../store';
import Network, { TYPES } from "../../utils/Network";
import { Confirmations } from "../../model/ISystemState";
import IConfirmation from "../../model/IConfirmation";
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';


const initialState: Confirmations = {};

const orderconfirmation = createSlice({
  name: 'confirmations',
  initialState: initialState,
  reducers: {
    'addOrderConfirmation':  (state = initialState, action: PayloadAction<IConfirmation>) => {
      const payload = action.payload;
      state[payload.bidId] = payload;
      return state;
    }
  }
});

export const { addOrderConfirmation } = orderconfirmation.actions;

export default orderconfirmation.reducer;

export const fetchOrderConfirmation = (bidId: string): AppThunk => (dispatch) => {
  Network.GET<IConfirmation>(`/bids/${bidId}/offer/confirmation/`, TYPES.JSON).then((json) => {
    if (json) {
      dispatch(addOrderConfirmation(json));
    }
  }, (err: Error) => {
    if (err.message in ERRORS) {
      dispatch(addError(err.message as ERRORS));
      return;
    }
    dispatch(addError(ERRORS.NETWORK_ERROR));
  });
}
