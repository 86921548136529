import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


const styles = (theme: Theme) => createStyles({
  main: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    textAlign: 'center'
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
});

class Signin extends React.Component<WithStyles> {

  public render() {
    const {
      classes
    } = this.props;

    return (
        <Container
            className={classes.container}
            component="main"
            maxWidth="sm"
        >
            <div>
                <Grid
                    container
                    justify="center"
                >
                    <img
                        alt="EoS4U"
                        height="30%"
                        src="./assets/logo.png"
                        width="30%"
                    />
                </Grid>
                <Container
                    className={classes.main}
                    component="main"
                >
                    <CircularProgress />
                </Container>
            </div>
        </Container>
    );
  }
}

export default withStyles(styles)(Signin);
