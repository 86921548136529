/* eslint-disable react/no-danger */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { withTranslation, WithTranslation } from 'react-i18next';

import CheckboxValidator from '../forms/CheckboxValidator';
import Network, {TYPES, networkErrorHelper} from '../../utils/Network';
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';


const styles = (theme: Theme) => createStyles({
  terms: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '60vh',
    maxHeight: '60vh',
    overflow: 'scroll'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

const connector = connect(null, { addError });

interface ITermsProps extends WithTranslation, WithStyles, ConnectedProps<typeof connector>{
  onBack: () => void;
  onChange: (data: ITerms) => void;
}

export interface ITerms {
  isAuthorized: boolean;
  readAndUnderstood: boolean;
  contactMe: boolean;
}

interface ITermsState extends ITerms {
  terms: string;
  submitting: boolean;
}

class Terms extends React.Component<ITermsProps, ITermsState> {
  constructor(props: ITermsProps) {
    super(props);

    this.state = {
      terms: '',
      isAuthorized: false,
      readAndUnderstood: false,
      contactMe: false,
      submitting: false
    };
  }

  public componentDidMount() {
    const {
      i18n
    } = this.props;

    const language = i18n.language || 'en_US';
    const langToLicense = {
      'en_US': '49393121',
      'de_DE': '42846387'
    } as Record<string, string>;

    Network.GET<Record<string, string>>(`https://www.iubenda.com/api/terms-and-conditions/${langToLicense[language] || '49393121'}`,
        TYPES.JSON, true, {noCredentials: true}).then((res) => {
      if (res) {
        this.setState({
          terms: res.content
        });
      }
    }, (err: Error) => {
      const {
        addError
      } = this.props;
      networkErrorHelper(err.message as ERRORS, addError);
    });
    ValidatorForm.addValidationRule('isTruthy', value => !!value);
  }

  public  componentWillUnmount() {
    ValidatorForm.removeValidationRule('isTruthy');
  }

  private handleSubmit = () => {
    const {
      isAuthorized,
      readAndUnderstood,
      contactMe
    } = this.state;
    const {
      onChange
    } = this.props;

    this.setState({
      submitting: true
    });
    onChange({
      isAuthorized,
      readAndUnderstood,
      contactMe
    })
  }

  private handleReadTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ readAndUnderstood: event.target.checked });
  }

  private handleIsAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isAuthorized: event.target.checked });
  }

  private handleContactMe = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ contactMe: event.target.checked });
  }

   public render() {
    const {
      terms,
      isAuthorized,
      readAndUnderstood,
      contactMe,
      submitting
    } = this.state;
    const {
      t,
      classes,
      onBack
    } = this.props;

    return (
        <ValidatorForm
            instantValidate={false}
            onSubmit={this.handleSubmit}
        >
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Typography
                        className={classes.terms}
                        gutterBottom
                        variant="body2"
                    >
                        <div
                            dangerouslySetInnerHTML={{ __html: terms }}
                        />
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <CheckboxValidator 
                        errorMessages={[t('signup.errors.agreeToTerms')]}
                        label={t('signup.termsRead').toString()}
                        name="readAndUnderstood"
                        onChange={this.handleReadTerms}
                        validators={['isTruthy']}
                        value={readAndUnderstood}
                    />
                    <CheckboxValidator 
                        errorMessages={[t('signup.errors.notAuthorized')]}
                        label={t('signup.authorized').toString()}
                        name="isAuthorized"
                        onChange={this.handleIsAuthorized}
                        validators={['isTruthy']}
                        value={isAuthorized}
                    />
                    <CheckboxValidator 
                        errorMessages={[t('signup.errors.notContact')]}
                        label={t('signup.contactme').toString()}
                        name="contactMe"
                        onChange={this.handleContactMe}
                        validators={['isTruthy']}
                        value={contactMe}
                    />
                </Grid>
            </Grid>
            <div className={classes.buttons}>
                <Button
                    className={classes.button}
                    disabled={submitting}
                    onClick={onBack}
                >
                    {t('signup.back')}
                </Button>
                <Button
                    className={classes.button}
                    color="primary"
                    disabled={!terms || submitting}
                    type="submit"
                    variant="contained"
                >
                    {t('signup.next')}
                    {submitting &&
                        <CircularProgress 
                            className={classes.buttonProgress}
                            size={24}
                        />}
                </Button>
            </div>
        </ValidatorForm>
    );
  }
}

export default withStyles(styles)(withTranslation()(connector(Terms)));
