import React from 'react';
import store from '../redux/store';
import { fetchOwnBids } from '../redux/reducers/bids';
import { fetchAllBids } from '../redux/reducers/allbids';
import { fetchBidStates } from '../redux/reducers/bidStates';
import { Roles } from '../model/Roles';
import { withRoles, IWithRoleProps } from './Roles';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';
import ISystemState from "../model/ISystemState";

class Populatestore extends React.Component<IWithRoleProps> {

  public componentDidMount() {
    const {
      role
    } = this.props

    if (role === Roles.User) {
      (store.dispatch as ThunkDispatch<ISystemState, void, AnyAction>)(fetchOwnBids());
    }
    if (role === Roles.Buyer) {
      (store.dispatch as ThunkDispatch<ISystemState, void, AnyAction>)(fetchAllBids());
    }
    (store.dispatch as ThunkDispatch<ISystemState, void, AnyAction>)(fetchBidStates());
  }

  public render() {
    return null;
  }
}

export default withRoles(Populatestore, [Roles.User, Roles.Buyer]);