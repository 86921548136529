import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IBidData from "../../model/IBidData";


const initialState = Array<IBidData>();

const openBids = createSlice({
  name: 'openBids',
  initialState: initialState,
  reducers: {
    'addOpenBids': (state = initialState, action: PayloadAction<IBidData[]>) => {
      return state.concat(action.payload);
    },
    'removeOpenBid': (state = initialState, action: PayloadAction<string>) => {
      const idx = state.findIndex((el) => {
        return el.bidId === action.payload;
      });
      if (idx !== -1) {
        state = state.splice(idx, 1);
      }
    }
  }
});

export const { 
  addOpenBids,
  removeOpenBid
} = openBids.actions;

export default openBids.reducer;
