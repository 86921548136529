/* eslint-disable */

import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';


export class AutocompleteValidator extends ValidatorComponent {

  renderValidatorComponent() {
    const {
      getOptionLabel,
      label,
      disabled,
      options,
      id,
      name,
      value
    } = this.props;

    return (
        <React.Fragment>
            <Autocomplete
              onChange={(event, newValue) => {
                this.props.onChange(newValue)
              }}
              disabled={disabled}
              options={options}
              getOptionLabel={getOptionLabel}
              name={name}
              id={id}
              fullWidth
              renderInput={(params) => <TextField {...params} label={label} />}
              value={value}
            />
             {this.errorText()}
        </React.Fragment>
    );
  }

  errorText() {
      const { isValid } = this.state;

      if (isValid) {
          return null;
      }

      return (
          <FormHelperText error>
              {this.getErrorMessage()}
          </FormHelperText>
      );
  }
}

export default AutocompleteValidator;