import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from '../store';
import Network, { TYPES } from "../../utils/Network";
import IUserData, { Profile } from "../../model/IUserData";
import IAddressData from '../../model/IAddressData';
import IContactDetails from '../../model/IContactDetails';
import IAPIKey from '../../model/IAPIKey';
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';
import { addWarning } from '../../redux/reducers/warnings';
import { WARNINGS } from '../../model/Warnings';
import { Roles } from '../../model/Roles';
import { PaymentStatus } from '../../model/PaymentStatus';


const initialState = <IUserData> {
  authenticated: false,
  registered: false,
  role: 'Anonymous',
  profile: {}
};

const user = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    'setUserData': (state = initialState, action: PayloadAction<IUserData>) => {
      const payload = action.payload;
      state.authenticated = payload.authenticated;
      state.registered = payload.registered;
      state.role = payload.role;
      state.locale = payload.locale || 'en_US';
      state.verified = payload.verified;
      state.noPlan = payload.noPlan;
      state.paymentStatus = payload.paymentStatus;
      return state;
    },
    'setLocale': (state = initialState, action: PayloadAction<string>) => {
      state.locale = action.payload;
      return state;
    },
    'setVerified': (state = initialState, action: PayloadAction<boolean>) => {
      state.verified = action.payload;
      return state;
    },
    'setProfile': (state = initialState, action: PayloadAction<Profile>) => {
      state.profile = action.payload;
      return state;
    },
    'setContactDetails': (state = initialState, action: PayloadAction<IContactDetails>) => {
      state.profile.contactDetails = action.payload;
      return state;
    },
    'setCompanyDetails': (state = initialState, action: PayloadAction<IAddressData>) => {
      state.profile.companyDetails = action.payload;
      return state;
    },
    'setBillingDetails': (state = initialState, action: PayloadAction<IAddressData>) => {
      state.profile.billingDetails = action.payload;
      return state;
    },
    'setAPIKeysTest': (state = initialState, action: PayloadAction<IAPIKey[]>) => {
      state.apikeysTest = action.payload;
      return state;
    },
    'setAPIKeys': (state = initialState, action: PayloadAction<IAPIKey[]>) => {
      state.apikeys = action.payload;
      return state;
    },
    'setAPIKeyTest': (state = initialState, action: PayloadAction<IAPIKey>) => {
      state.apikeysTest.unshift(action.payload);
      return state;
    }
  }
});

export const {
  setUserData,
  setProfile,
  setCompanyDetails,
  setBillingDetails,
  setContactDetails,
  setLocale,
  setVerified,
  setAPIKeysTest,
  setAPIKeys,
  setAPIKeyTest
} = user.actions;

export default user.reducer;

export const fetchUser = (): AppThunk => (dispatch) => {
  Network.GET<IUserData>('/users/status/', TYPES.JSON).then((json) => {
    if (json) {
      dispatch(setUserData(json));
      if (json.role === Roles.User) {
        if (!json.verified) {
          dispatch(addWarning(WARNINGS.NOT_VERIFIED));
        }
        if (json.paymentStatus === PaymentStatus.FAILED) {
          dispatch(addError(ERRORS.PAYMENT_FAILED));
        }
      }
    }
  }, (ex: Error) => {
    if (ex.message in ERRORS) {
      dispatch(addError(ex.message as ERRORS));
      return;
    }
    dispatch(addError(ERRORS.NETWORK_ERROR));
  });
}

export const fetchTestApikeys = (): AppThunk => (dispatch) => {
  Network.GET<IAPIKey[]>('/developer/apikey/?env=test', TYPES.JSON).then((json) => {
    if (json) {
      dispatch(setAPIKeysTest(json));
    }
  }, (ex: Error) => {
    if (ex.message in ERRORS) {
      dispatch(addError(ex.message as ERRORS));
      return;
    }
    dispatch(addError(ERRORS.NETWORK_ERROR));
  });
}

export const fetchApikeys = (): AppThunk => (dispatch) => {
  Network.GET<IAPIKey[]>('/developer/apikey/?env=prd', TYPES.JSON).then((json) => {
    if (json) {
      dispatch(setAPIKeys(json));
    }
  }, (ex: Error) => {
    if (ex.message in ERRORS) {
      dispatch(addError(ex.message as ERRORS));
      return;
    }
    dispatch(addError(ERRORS.NETWORK_ERROR));
  });
}
