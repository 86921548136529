import React from 'react';
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import { Roles } from "../../../model/Roles";
import { withRoles, IWithRoleProps } from '../../Roles';
import { connect, ConnectedProps } from 'react-redux';
import ISystemState from "../../../model/ISystemState";
import { fetchOffer } from '../../../redux/reducers/offers';
import store from '../../../redux/store';
import Typography from '@material-ui/core/Typography';
import { formatDate } from '../../../utils/Date';
import Invoice from './Invoice';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';

const styles = (theme: Theme) => createStyles({
  container: {
    width: '100%',
    border: '1px solid #CCCCCC',
    height: '300px',
    overflow: 'scroll',
    padding: '1em'
  },
  contrast: {
    color: '#888888'
  },
  title: {
    color: theme.palette.primary.main
  },
  acceptedOn: {
    marginTop: theme.spacing(2)
  },
  offer: {
    marginTop: theme.spacing(2)
  }
});

const mapState = (state: ISystemState) => {
  return {
    offers: state.offers,
    bids: state.bids,
    bidStates: state.bidStates
  };
}

const connector = connect(mapState);

interface IOfferProps extends WithTranslation, WithStyles, IWithRoleProps, 
  ConnectedProps<typeof connector> {
    bidId: string;
}

class Offer extends React.Component<IOfferProps> {

  public componentDidMount() {
    const {
      offers,
      bidId
    } = this.props;

    if (!offers[bidId]) {
      (store.dispatch as ThunkDispatch<ISystemState, void, AnyAction>)(fetchOffer(bidId));
    }
  }

  public render() {
    const {
      classes,
      t,
      bidId,
      offers,
      bids,
      bidStates
    } = this.props;

    if (offers[bidId]) {
      const offer = offers[bidId];
      const bid = bids.find(el => el.bidId === bidId);
      let extendedPrice = 0;
      let priceWithVAT = 0;

      if (bid) {
        const price = bid.quantity * (1000 * offer.unitPrice);
        extendedPrice = price / 1000;
        priceWithVAT = (price * 0.19 + price) / 1000;
      }

      return (
          <Grid
              className={classes.offer}
              item
              xs={12}
          >
              <Divider />
              <Typography
                  className={classes.title}
                  variant="h6"
              >
                  {`${t('offer.userTitle')}:`}
              </Typography>
              <Grid
                  container
              >
                  <Grid
                      item
                      xs={2}
                  >
                      <Typography
                          variant="caption"
                      >
                          {`${t('offer.unitPrice')}:`}
                      </Typography>
                      <Typography>
                          {offer.unitPrice}
                      </Typography>
                  </Grid>
                  <Grid
                      item
                      xs={2}
                  >
                      <Typography
                          variant="caption"
                      >
                          {`${t('offer.extendedPrice')}:`}
                      </Typography>
                      <Typography>
                          {bid ? extendedPrice.toFixed(4) : 0.0}
                      </Typography>
                  </Grid>
                  <Grid
                      item
                      xs={2}
                  >
                      <Typography
                          variant="caption"
                      >
                          {`${t('offer.VAT')}:`}
                      </Typography>
                      <Typography>
                          {bid ? priceWithVAT.toFixed(4) : 0.0}
                      </Typography>
                  </Grid>
                  <Grid
                      item
                      xs={6}
                  >
                      <Typography
                          variant="caption"
                      >
                          {`${t('offer.commentsUser')}:`}
                      </Typography>
                      <Typography>
                          {offer.comment}
                      </Typography>
                  </Grid>
                  {bid && bid.accepted && bid.acceptedAt &&
                      <Grid
                          className={classes.acceptedOn}
                          item
                          xs={12}
                      >
                          <Typography>
                              {`${t('offer.offerAccepted')}: ${formatDate(new Date(bid.acceptedAt))}`}
                          </Typography>
                      </Grid>}
                  {bid && bid.state >= bidStates['Dispatched'] &&
                  <Grid
                      className={classes.acceptedOn}
                      item
                      xs={12}
                  >
                      <Invoice
                          bidId={bidId}
                      />
                  </Grid>}
              </Grid>
          </Grid>
      );
    } else {
      return (
          <Grid
              item
              xs={12}
          >
              <Divider />
              <Typography
                  className={classes.title}
                  variant="h6"
              >
                  {`${t('offer.comingShortly')}`}
              </Typography>
          </Grid>);
    }
  }
}

export default
  connector(
    withRoles(
      withStyles(styles)(
        withTranslation()(Offer)
      ), [Roles.User])
  );