import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/core/styles';
import Theme from './Theme';
import Splash from './Splash';
import './i18n/i18n';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from 'react-redux';
import store from './redux/store';


if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: "https://757d69b410ca49f992fae0141c90be30@o533166.ingest.sentry.io/5652445",
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: process.env.NODE_ENV
    });
}

ReactDOM.render(
    <React.StrictMode>
        <React.Suspense fallback={<Splash />}>
            <ThemeProvider theme={Theme}>
                <Provider store={store}>
                    <App />
                </Provider>
            </ThemeProvider>
        </React.Suspense>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
