import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { withTranslation, WithTranslation } from 'react-i18next';
import Toolbar from '@material-ui/core/Toolbar';
import IAddressData from '../../model/IAddressData';
import AddressForm from './AddressForm';
import IContactDetails from '../../model/IContactDetails';
import ContactDetails from './ContactDetails';
import { Roles } from '../../model/Roles';
import { withRoles, IWithRoleProps } from '../Roles';
import Terms, { ITerms } from './Terms';
import Network, { TYPES, networkErrorHelper } from '../../utils/Network';
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';
import ISystemState from "../../model/ISystemState";

interface IRegisterState {
  companyDetails?: IAddressData,
  billingDetails?: IAddressData,
  contactDetails?: IContactDetails,
  activeStep: number
}

const mapState = (state: ISystemState) => {
  return {
    noPlan: state.user.noPlan
  };
};
const connector = connect(mapState, { addError });


interface ISigninProps extends WithTranslation, WithStyles, IWithRoleProps, ConnectedProps<typeof connector>  {
  onSuccess(): void
}

const PAPER_WIDTH = 850;

const styles = (theme: Theme) => createStyles({
  main: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  layout: {
    width: 'auto',
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(PAPER_WIDTH + theme.spacing(2) * 2)]: {
      width: PAPER_WIDTH,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(PAPER_WIDTH + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: '60px',
    boxShadow: 'none',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    marginTop: '20px',
    width: '100px'
  }
});

class Register extends React.Component<ISigninProps, IRegisterState> {
  constructor(props: ISigninProps) {
    super(props);
    this.state = {
      activeStep: props.noPlan ? 3 : 0
    };
  }

  private nextStep() {
    let {activeStep} = this.state;
    activeStep += 1;
    this.setState({
      activeStep: activeStep
    });
  }

  private handleTpPreviousStep = () => {
    let {activeStep} = this.state;
    activeStep -= 1;
    this.setState({
      activeStep: activeStep
    });
  }

  private handleCompanyDetails = (data: IAddressData, cb: () => void) => {
    this.setState({
      companyDetails: data
    });
    cb();
    this.nextStep();
  }

  private handleBillingDetails = (data: IAddressData, cb: () => void) => {
    this.setState({
      billingDetails: data
    });
    cb();
    this.nextStep();
  }

  private handleContactDetails = (data: IContactDetails, cb: () => void) => {
    this.setState({
      contactDetails: data
    });
    cb();
    this.nextStep();
  }

  private handleSubmit = (data: ITerms) => {
    const {
      companyDetails,
      billingDetails,
      contactDetails
    } = this.state;
    const {
      onSuccess
    } = this.props;

    Network.POST('/users', TYPES.JSON, {
      contactDetails,
      billingDetails,
      companyDetails,
      locale: location.hash.split('/')[1],
      consent: {
        isAuthorized: data.isAuthorized,
        readAndUnderstood: data.readAndUnderstood,
        contactMe: data.contactMe
      }
    }).then(() => {
      onSuccess();
    }, (err: Error) => {
      const {
        addError
      } = this.props;
      networkErrorHelper(err.message as ERRORS, addError);
    });
  }

  private getStepContent (step: number) {
    const {
      companyDetails,
      billingDetails,
      contactDetails
    } = this.state;
    const {
      t
    } = this.props

    switch (step) {
      case 0:
        return (
            <>
                <Typography
                    gutterBottom
                    variant="h6"
                >
                    {t('signup.companyDetails')}
                </Typography>
                <AddressForm
                    billingDetails={companyDetails}
                    buttonText={t('signup.next')}
                    isBilling={false}
                    onChange={this.handleCompanyDetails}
                />
            </>);
      case 1:
        return (
            <>
                <Typography
                    gutterBottom
                    variant="h6"
                >
                    {t('signup.billingAddress')}
                </Typography>
                <AddressForm
                    billingDetails={billingDetails}
                    buttonText={t('signup.next')}
                    isBilling
                    onBack={this.handleTpPreviousStep}
                    onChange={this.handleBillingDetails}
                />
            </>);
      case 2:
        return (
            <>
                <Typography
                    gutterBottom
                    variant="h6"
                >
                    {t('signup.contactDetails')}
                </Typography>
                <ContactDetails 
                    buttonText={t('signup.next')}
                    contactDetails={contactDetails}
                    onBack={this.handleTpPreviousStep}
                    onChange={this.handleContactDetails}
                />
            </>);
      case 3:
        return (<Terms
            onBack={this.handleTpPreviousStep}
            onChange={this.handleSubmit}
                />);
      default:
        throw new Error('Unknown step');
    }
  }

  public render() {
    const {
      t,
      classes
    } = this.props;
    const {
      activeStep
    } = this.state;

    const steps = [
      t('signup.companyDetails'),
      t('signup.billingAddress'),
      t('signup.contactDetails'),
      t('signup.termsOfUse')
    ];

    return (
        <>
            <AppBar
                className={classes.appBar}
                position="absolute"
            >
                <Toolbar className={classes.toolbar}>
                    <Typography
                        className={classes.title}
                        color="inherit"
                        component="h1"
                        noWrap
                        variant="h6"
                    >
                        <img
                            alt="EoS4U"
                            className={classes.logo}
                            height="30%"
                            src="./assets/logo.png"
                            width="30%"
                        />
                    </Typography>
                </Toolbar>
            </AppBar>
            <CssBaseline />
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography
                        align="center"
                        component="h1"
                        variant="h6"
                    >
                        {t('signup.signup')}
                    </Typography>
                    <Stepper
                        activeStep={activeStep}
                        className={classes.stepper}
                    >
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <>
                        {this.getStepContent(activeStep)}
                    </>
                </Paper>
            </main>
        </>
    );
  }
}
export default withRoles(
  withStyles(styles)(withTranslation()(
    connector(Register)
  )), [Roles.Guest]
);