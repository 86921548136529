import React from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { Roles } from '../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../Roles';
import CircularProgress from '@material-ui/core/CircularProgress';

interface ISubmitBuyerProps extends WithTranslation, IWithRoleProps{
  editorEmpty: boolean;
  submitting: boolean;
  onClick: () => void;
}

class SubmitBuyer extends React.Component<ISubmitBuyerProps> {

  public render() {
    const {
      t,
      onClick,
      editorEmpty,
      submitting
    } = this.props;

    return (
        <Button 
            color="primary"
            disabled={editorEmpty || submitting}
            onClick={onClick}
            type="submit"
            variant="contained"
        >
            {t('order.send')}
            {submitting &&
                <CircularProgress
                    size={24}
                />}
        </Button>
    );
  }
}



export default withRoles(
    withTranslation()(
        SubmitBuyer
    ), [Roles.Buyer]
);
