import React from 'react';
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, ConnectedProps } from "react-redux";
import ISystemState from '../../model/ISystemState';
import Chip from '@material-ui/core/Chip';
import { addError } from '../../redux/reducers/errors';


const styles = () => createStyles({
  fullwidth: {
    width: '100%'
  },
  ok: {
    color: '#2dc937',
    borderColor: '#2dc937'
  },
  error: {
    color: '#cc3232',
    borderColor: '#cc3232'
  },
  warning: {
    color: '#ff9900',
    borderColor: '#ff9900'
  }
})


const mapState = (state: ISystemState) => {
  return {
    verified: state.user.verified
  };
};
const connector = connect(mapState, { addError });

interface IPaymentProps extends ConnectedProps<typeof connector>,  WithTranslation, WithStyles {}

interface IPaymentState {
  submitting: boolean;
  amount: number;
}

class Payment extends React.Component<IPaymentProps, IPaymentState> {
  constructor(props: IPaymentProps) {
    super(props);
    this.state = {
      submitting: false,
      amount: 0
    };
  }

  public render() {
    const {
      t,
      classes,
      verified,
    } = this.props;

    return (
        <Grid
            alignItems="center"
            container
            spacing={3}
            xs={12}
        >
            {(!verified) &&
            <Grid
                item
                xs={12}
            >
                {!verified &&
                <Chip
                    className={classes.warning}
                    label={t('profile.waitingForVerification')}
                    size="small"
                    variant="outlined"
                />}
                {verified &&
                <Chip
                    className={classes.ok}
                    label={t('profile.verified')}
                    size="small"
                    variant="outlined"
                />}
            </Grid>}
        </Grid>
          );
  }
}

export default connector(
  withStyles(styles)(
    withTranslation()(Payment)
  )
);
