import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from '../store';
import { BidStates } from '../../model/ISystemState';
import Network, { TYPES } from "../../utils/Network";
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';

const initialState: BidStates = {};

const bidStates = createSlice({
  name: 'bidStates',
  initialState: initialState,
  reducers: {
    'setBidStates': (state = initialState, action: PayloadAction<BidStates[]>) => {
      action.payload.forEach((el) => {
        state[el.name] = el.id;
      });
    }
  }
});

export const { setBidStates } = bidStates.actions;

export default bidStates.reducer;

export const fetchBidStates = (): AppThunk => (dispatch) => {
  Network.GET<BidStates[]>('/bids/states', TYPES.JSON).then((json) => {
    if (json) {
      dispatch(setBidStates(json));
    }
  }, (err: Error) => {
    if (err.message in ERRORS) {
      dispatch(addError(err.message as ERRORS));
      return;
    }
    dispatch(addError(ERRORS.NETWORK_ERROR));
  });
}
