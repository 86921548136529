import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import ISystemState from "../../../../model/ISystemState";
import { connect, ConnectedProps } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Network, { TYPES, networkErrorHelper } from '../../../../utils/Network';
import { addOwnBid } from '../../../../redux/reducers/bids';
import { removeOpenBid } from '../../../../redux/reducers/openBids';
import { formatDate } from '../../../../utils/Date';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import TwoStageButton from '../../../forms/TwoStageButton';
import IBidData from '../../../../model/IBidData';
import { addError } from '../../../../redux/reducers/errors';
import { ERRORS } from '../../../../model/Errors';
import { withRouter, RouteComponentProps } from 'react-router-dom';


const styles = () => createStyles({
  centerAligned: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '8px',
    display: 'flex',
    height: '100%'
  },
  contrast: {
    color: '#888888'
  }
});

interface MatchParams {
  bidId: string;
}


const mapState = (state: ISystemState) => {
  return {
    verified: state.user.verified,
    bids: state.openBids,
  };
}

const connector = connect(mapState, { addOwnBid, removeOpenBid, addError });

interface IJoinProps extends WithTranslation, WithStyles,
    ConnectedProps<typeof connector>, RouteComponentProps<MatchParams> {
  onClose: () => void;
}

interface IJoinState {
  submitting: boolean;
  quantity: number;
}

class Join extends React.Component<IJoinProps, IJoinState> {
  constructor(props: IJoinProps) {
    super(props);
    this.state = {
      submitting: false,
      quantity: 0
    }
  }

  private handleClose = () => {
    const {
      onClose
    } = this.props;
    onClose();
  }

  private handleSubmit = () => {
    const {
      submitting,
      quantity
    } = this.state;
    const {
      match,
      verified,
      addError
    } = this.props;

    if (!verified) {
      addError(ERRORS.USER_NOT_VERIFIED);
      return;
    }

    if (submitting) return;
    this.setState({
      submitting: true
    });

    const bidId = match.params.bidId;

    Network.POST<IBidData>(`/bids/${bidId}`, TYPES.JSON, {
      quantity
    }).then((res) => {
      const {
        addOwnBid,
        removeOpenBid
      } = this.props;
      this.setState({
        submitting: false
      });
      this.handleClose();
      addOwnBid(res);
      removeOpenBid(res.bidId);
    }, (err: Error) => {
      const {
        addError
      } = this.props;
      networkErrorHelper(err.message as ERRORS, addError);
    })
  }

  private handleSetQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const q = parseInt(event.target.value, 10);
    this.setState({
      quantity: isNaN(q) ? 0 : q
    })
  }

  private getField = (text: string, val: string) => {
    const {
      classes
    } = this.props;

    return (
        <Grid
            item
            xs={6}
        >
            <Typography
                className={classes.contrast}
                variant="caption"
            >
                {`${text}: `}
            </Typography>
            <Typography>
                {val}
            </Typography>
        </Grid>
    )
  }

  public render() {
    const {
      t,
      bids,
      match,
      classes
    } = this.props;

    const {
      quantity,
      submitting
    } = this.state;

    const bidId = match.params.bidId;

    const el = bids.find((el) => {
      return el.bidId === bidId;
    });

    if (!el) return null;

    return (el &&
        <div>
            <Dialog
                fullWidth
                maxWidth='md'
                onClose={this.handleClose}
                open
            >
                <DialogContent>
                    <DialogContentText />
                    <ValidatorForm
                        instantValidate={false}
                        onSubmit={this.handleSubmit}
                    >
                        <Grid
                            container
                            spacing={1}
                        >
                            {this.getField(t('order.commodity'), el.commodity)}
                            {this.getField(t('order.product'), el.product)}
                            {this.getField(t('order.manufacturer'), el.manufacturer)}
                            {el.EAN ? this.getField(t('order.EAN'), el.EAN) : this.getField(t('order.partNumber'), el.partNumber)}
                            <Grid
                                item
                                xs={3}
                            >
                                <TextValidator
                                    errorMessages={[t('order.errors.required'), 
                                      t('order.errors.quantityNaN'),
                                      t('order.errors.quantityTooSmall'),
                                      t('order.errors.quantityTooLarge')
                                    ]}
                                    fullWidth
                                    id="quantity"
                                    label={t('order.quantity').toString()}
                                    name="quantity"
                                    onChange={this.handleSetQuantity}
                                    validators={['required', 'isNumber', 'minNumber:1', 'maxNumber:100000000']}
                                    value={quantity}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={9}
                            />
                            {this.getField(t('order.neededBy'), formatDate(new Date(el.byDate)))}
                            <Grid
                                item
                                xs={12}
                            >
                                <Grid
                                    alignItems="center"
                                    container
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                    />
                                    <Grid
                                        item
                                        xs={6}
                                    >
                                        <DialogActions>
                                            <Button
                                                onClick={this.handleClose}
                                            >
                                                {t('order.close')}
                                            </Button>
                                            <TwoStageButton
                                                buttonText={t('openBid.join')}
                                                classNameButton={classes.verify}
                                                classNameLabel={classes.lowContrast}
                                                inProgress={submitting}
                                                labelText={t('openBid.joinConfirm')}
                                                name='joinBid'
                                                tooltipText={t('order.buttonTooltip')}
                                                type='submit'
                                            />
                                        </DialogActions>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </DialogContent>
            </Dialog>
        </div>
    );
  }
}

export default 
  withStyles(styles)(
    withTranslation()(
      connector(
        withRouter(Join)
      )
    )
  );