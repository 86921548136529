import React from "react";
import { connect, ConnectedProps } from "react-redux";
import ISystemState from "../../../model/ISystemState";
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { Roles } from '../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../Roles';
import TwoStageButton from '../../forms/TwoStageButton';

const styles = () => createStyles({
    exit: {
        backgroundColor: 'red'
    },
    lowContrast: {
      color: '#888888'
    },
});

const mapState = (state: ISystemState) => {
  return {
      bids: state.bids,
      bidStates: state.bidStates
  };
};

const connector = connect(mapState)

interface IExitBidProps extends ConnectedProps<typeof connector>, WithTranslation, WithStyles, IWithRoleProps{
  onClick: () => void;
}

interface IExitBidState {
  submitting: boolean;
}

class ExitBid extends React.Component<IExitBidProps, IExitBidState> {

  constructor(props: IExitBidProps) {
    super(props);
    this.state = {
      submitting: false
    };
  }

  private handleExitBid = () => {
    const {
      onClick
    } = this.props;
    const {
      submitting
    } = this.state;

    if (submitting) return;

    this.setState({
      submitting: true
    });

    onClick();
  }

  public render() {
    const {
      t,
      classes
    } = this.props;

    return (
        <TwoStageButton
            buttonText={t('order.exit')}
            classNameButton={classes.exit}
            classNameLabel={classes.lowContrast}
            labelText={t('order.exitConfirm')}
            name='exitBid'
            onClick={this.handleExitBid}
        />
    );
  }
}



export default connector(
  withRoles(
    withStyles(styles)(
        withTranslation()(
          ExitBid
        )
    ), [Roles.User])
);
