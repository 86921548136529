import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withTranslation, WithTranslation } from 'react-i18next';
import IAddressData from '../../model/IAddressData';
import AutocompleteValidator from '../forms/AutocompleteValidator.jsx';
import { connect, ConnectedProps } from "react-redux";
import { fetchIndustries } from '../../redux/reducers/industries';
import { fetchCountries } from '../../redux/reducers/countries';
import ISystemState from '../../model/ISystemState';
import IIndustry from '../../model/IIndustry';
import ICountry from '../../model/ICountry';
import store from '../../redux/store';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';

const styles = (theme: Theme) => createStyles({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
})

const mapState = (state: ISystemState) => {
  return {
    industries: state.industries,
    countries: state.countries
  };
};
const connector = connect(mapState);

interface IAddressFormState extends IAddressData{
  companyDetails?: IAddressData;
  submitting: boolean;
}

interface IAddressFormProps extends ConnectedProps<typeof connector>,  WithTranslation, WithStyles {
  isBilling: boolean;
  supressCheckbox?: boolean;
  companyDetails?: IAddressData;
  billingDetails?: IAddressData;
  onChange: (data: IAddressData, cb: () => void) => void;
  buttonText: string;
  onBack?: () => void;
  preserve?: boolean;
}

class AddressForm extends React.Component<IAddressFormProps, IAddressFormState> {
  constructor(props: IAddressFormProps) {
    super(props);

    const state = {
      companyName: '',
      industry: {name: ''} as IIndustry,
      address1: '',
      address2: '',
      country: {name: ''} as ICountry,
      city: '',
      state: '',
      zip: '',
      vat: '',
      saveAddress: false
    } as IAddressFormState;

    if (props.billingDetails) {
      const billingDetails = props.billingDetails;
      state.companyName = billingDetails.companyName;
      state.industry = billingDetails.industry;
      state.address1 = billingDetails.address1;
      state.address2 = billingDetails.address2;
      state.country = billingDetails.country;
      state.city = billingDetails.city;
      state.state = billingDetails.state;
      state.zip = billingDetails.zip;
      state.vat = billingDetails.vat;
      state.saveAddress = billingDetails.saveAddress
    }
    if (props.companyDetails) {
      const companyDetails = props.companyDetails;
      state.companyName = companyDetails.companyName;
      state.industry = companyDetails.industry;
      state.address1 = companyDetails.address1;
      state.address2 = companyDetails.address2;
      state.country = companyDetails.country;
      state.city = companyDetails.city;
      state.state = companyDetails.state;
      state.zip = companyDetails.zip;
      state.saveAddress = companyDetails.saveAddress;
    }

    state.submitting = false;
    this.state = {...state};
  }

  public componentDidMount() {
    const {
      i18n,
      industries,
      countries
    } = this.props;

    if (!countries || countries.length === 1) {
      (store.dispatch as ThunkDispatch<ISystemState, void, AnyAction>)(fetchCountries(i18n.language));
    }
    if (!industries || industries.length === 1) {
      (store.dispatch as ThunkDispatch<ISystemState, void, AnyAction>)(fetchIndustries(i18n.language));
    }

    ValidatorForm.addValidationRule('hasName', (value: ICountry) => {
      if (!value) return false;
      return value.name !== '';
    });
  }

  public componentWillUnmount() {
    ValidatorForm.removeValidationRule('hasName');
  }

  private submitCallback = () => {
    this.setState({
      submitting: false
    });
  }

  private handleSubmit = () => {
    const {
      companyName,
      industry,
      city,
      state,
      address1,
      address2,
      country,
      zip,
      vat,
      saveAddress,
      submitting
    } = this.state;

    const {
      onChange,
      preserve
    } = this.props;

    if (submitting) return;

    this.setState({
      submitting: true
    });

    onChange({
      companyName,
      industry,
      city,
      state,
      address1,
      address2,
      country,
      zip,
      vat,
      saveAddress
    }, this.submitCallback);

    if (!preserve) {
      if (!saveAddress) {
        this.setState({
          companyName: '',
          industry: {name: ''} as IIndustry,
          address1: '',
          address2: '',
          country: {name: ''} as ICountry,
          city: '',
          state: '',
          zip: '',
          vat: '',
          companyDetails: {
            companyName,
            industry,
            city,
            state,
            address1,
            address2,
            country,
            zip,
            vat,
            saveAddress
          }
        });
      }
    }
  }

  private handleSetCompanyName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ companyName: event.target.value });
  }

  private handleSetIndustry = (event: React.ChangeEvent<unknown>, newValue: IIndustry|null) => {
    if (newValue) {
      this.setState({ 
        industry: newValue
      });
    }
  }

  private handleSetAddress1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ address1: event.target.value });
  }

  private handleSetAddress2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ address2: event.target.value });
  }

  private handleSetCountry = (value: ICountry) => {
    this.setState({ country: value });
  }

  private handleSetCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ city: event.target.value });
  }

  private handleSetState = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ state: event.target.value });
  }

  private handleSetZip = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ zip: event.target.value });
  }

  private handleSetVat = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ vat: event.target.value.toUpperCase() });
  }

  private handleSaveAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ saveAddress: event.target.checked });
  }

  private renderAutocompleteInput = (params: AutocompleteRenderInputParams) => {
    const {
      t
    } = this.props;

    return (<TextField
        {...params}
        label={t('signup.industry')}
            />);
  }

  private handleBackButton = () => {
    const {
      companyDetails
    } = this.state;
    const {
      onBack
    } = this.props;
    if (companyDetails) {
      this.setState({
        companyName: companyDetails.companyName,
        industry: companyDetails.industry,
        address1: companyDetails.address1,
        address2: companyDetails.address2,
        country: companyDetails.country,
        city: companyDetails.city,
        state: companyDetails.state,
        zip: companyDetails.zip,
        vat: companyDetails.vat
      });
    }
    onBack && onBack();
  }

  public render() {
    const {
      companyName,
      industry,
      address1,
      address2,
      country,
      city,
      state,
      zip,
      vat,
      saveAddress,
      submitting
    } = this.state;

    const {
      isBilling,
      supressCheckbox,
      industries,
      countries,
      t,
      classes,
      onBack,
      buttonText
    } = this.props;

    const industryProps = {
      options: industries,
      getOptionLabel: (option: IIndustry) => option.name,
    };

    const countryProps = {
      options: countries,
      getOptionLabel: (option: ICountry) => option.name,
    };

    return (
        <ValidatorForm
            instantValidate={false}
            onSubmit={this.handleSubmit}
        >
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    sm={!isBilling && 6}
                    xs={12}
                >
                    <TextValidator
                        autoComplete="company-name"
                        disabled={isBilling && saveAddress}
                        errorMessages={[
                          t('signup.errors.required'),
                          t('signup.errors.nameTooShort'),
                          t('signup.errors.nameTooLong')
                        ]}
                        fullWidth
                        id="companyName"
                        label={t('signup.companyName').toString()}
                        name="companyName"
                        onChange={this.handleSetCompanyName}
                        validators={['required', 'minStringLength:2', 'maxStringLength:100']}
                        value={companyName}
                    />
                </Grid>
                {!isBilling &&
                    <Grid
                        item
                        sm={6}
                        xs={12}
                    >
                        <Autocomplete
                            fullWidth
                            getOptionLabel={industryProps.getOptionLabel}
                            id="industry"
                            onChange={this.handleSetIndustry}
                            options={industryProps.options}
                            renderInput={this.renderAutocompleteInput}
                            value={industry}
                        />
                    </Grid>}
                <Grid
                    item
                    xs={12}
                >
                    <TextValidator
                        autoComplete="shipping address-line1"
                        disabled={isBilling && saveAddress}
                        errorMessages={[
                          t('signup.errors.required'),
                          t('signup.errors.addressTooShort'),
                          t('signup.errors.addressTooLong')
                        ]}
                        fullWidth
                        id="address1"
                        label={t('signup.address1').toString()}
                        name="address1"
                        onChange={this.handleSetAddress1}
                        validators={['required', 'minStringLength:2', 'maxStringLength:150']}
                        value={address1}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <TextField
                        autoComplete="shipping address-line2"
                        disabled={isBilling && saveAddress}
                        fullWidth
                        id="address2"
                        label={t('signup.address2')}
                        name="address2"
                        onChange={this.handleSetAddress2}
                        value={address2}
                    />
                </Grid>
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <AutocompleteValidator
                        disabled={isBilling && saveAddress}
                        errorMessages={[t('signup.errors.required')]}
                        fullWidth
                        getOptionLabel={countryProps.getOptionLabel}
                        id="country"
                        label={t('signup.country').toString()}
                        name="country"
                        onChange={this.handleSetCountry}
                        options={countryProps.options}
                        validators={['hasName']}
                        value={country}
                    />
                </Grid>
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <TextValidator
                        autoComplete="shipping city"
                        disabled={isBilling && saveAddress}
                        errorMessages={[
                          t('signup.errors.required'),
                          t('signup.errors.nameTooShort'),
                          t('signup.errors.nameTooLong'
                        )]}
                        fullWidth
                        id="city"
                        label={t('signup.city').toString()}
                        name="city"
                        onChange={this.handleSetCity}
                        validators={['required', 'minStringLength:2', 'maxStringLength:150']}
                        value={city}
                    />
                </Grid>
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <TextField 
                        disabled={isBilling && saveAddress}
                        fullWidth
                        id="state"
                        label="State/Province/Region"
                        name="state"
                        onChange={this.handleSetState}
                        value={state}
                    />
                </Grid>
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <TextValidator
                        autoComplete="shipping postal-code"
                        disabled={isBilling && saveAddress}
                        errorMessages={[
                          t('signup.errors.required'),
                          t('signup.errors.zipTooShort'),
                          t('signup.errors.zipTooLong')
                        ]}
                        fullWidth
                        id="zip"
                        label={t('signup.zip').toString()}
                        name="zip"
                        onChange={this.handleSetZip}
                        validators={['required', 'minStringLength:4', 'maxStringLength:16']}
                        value={zip}
                    />
                </Grid>
                {isBilling &&
                    <Grid
                        item
                        sm={6}
                        xs={12}
                    >
                        <TextValidator
                            autoComplete="vatN"
                            errorMessages={[
                              t('signup.errors.required'),
                              t('signup.errors.vatTooShort'),
                              t('signup.errors.vatTooLong')
                            ]}
                            fullWidth
                            id="vatN"
                            label={t('signup.vatN').toString()}
                            name="vatN"
                            onChange={this.handleSetVat}
                            validators={['required', 'minStringLength:4', 'maxStringLength:26']}
                            value={vat}
                        />
                    </Grid>}
                <Grid
                    item
                    xs={12}
                >
                    {(!isBilling && !supressCheckbox) &&
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={saveAddress}
                                    color="secondary"
                                    name="saveAddress"
                                    onChange={this.handleSaveAddress}
                                    value={false}
                                />
                            }
                            label={t('signup.saveAddress')}
                        />}
                </Grid>
            </Grid>
            <div className={classes.buttons}>
                {onBack && (
                    <Button
                        className={classes.button}
                        onClick={this.handleBackButton}
                    >
                        {t('signup.back')}
                    </Button>
                )}
                <Button
                    className={classes.button}
                    color="primary"
                    disabled={submitting}
                    type="submit"
                    variant="contained"
                >
                    {buttonText}
                    {submitting &&
                        <CircularProgress 
                            className={classes.buttonProgress}
                            size={24}
                        />}
                </Button>
            </div>
        </ValidatorForm>);
  }
}

export default connector(
  withStyles(styles)(
    withTranslation()(AddressForm)
  )
);
