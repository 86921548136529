import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { formatDate } from '../../../utils/Date';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const styles = (theme: Theme) => createStyles({
  spacing: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: 'flex',
  },
  expand: {
      marginLeft: 'auto',
  },
  trimmed: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkitLineClamp': 2,
    '-webkitBoxOrient': 'vertical'
  },
  immediate: {
    color: '#cc3232',
    borderColor: '#cc3232'
  },
});

interface IProductCardProps extends WithStyles, WithTranslation, RouteComponentProps {
  bidId: string;
  product: string;
  byDate: string;
  timestamp: number;
  commodity: string;
  companyName: string;
  lastStep?: boolean;
  immediate?: boolean;
  deliveryDate?: string;
  dispatchedDate?: string;
  onButtonClick?: (bidId: string, cb: () => void) => void;
  onDetailsShow: (bidId: string) => void;
}

interface IProductCard {
  submitting: boolean;
}

class ProductCard extends React.Component<IProductCardProps, IProductCard> {
  constructor(props: IProductCardProps) {
    super(props);
    this.state = {
      submitting: false
    }
  }

  private handleAdvance = () => {
    const {
      bidId,
      onButtonClick
    } = this.props;

    if (!onButtonClick) return;

    const {
      submitting
    } = this.state;

    if (submitting) return;

    this.setState({
      submitting: true
    });
    onButtonClick(bidId, this.onAdvance);
  }

  private handleShowDetails = (ev: React.MouseEvent) => {
    ev.preventDefault();
    const {
      bidId,
      onDetailsShow,
      history,
      i18n
    } = this.props;

    history.push(`/${i18n.language}/home/${bidId}`);
    onDetailsShow(bidId);
  }

  private onAdvance = () => {
    this.setState({
      submitting: false
    });
  }

  public render() {
    const {
      submitting
    } = this.state;

    const {
      product,
      byDate,
      timestamp,
      commodity,
      companyName,
      lastStep,
      classes,
      immediate,
      deliveryDate,
      dispatchedDate,
      t,
      onButtonClick
    } = this.props;

    return (
        <Card className={classes.spacing}>
            <CardContent>
                <Grid
                    container
                    justify="space-between"
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography 
                            className={classes.trimmed}
                            color="textSecondary"
                            component="h2"
                            gutterBottom
                            variant="h6"
                        >
                            <Link
                                color="primary"
                                href="#"
                                onClick={this.handleShowDetails}
                            >
                                {product || t('processBoard.noData')}
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography 
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                        >
                            {t('processBoard.placedOn')}
                            {': ' + formatDate(new Date(timestamp))}
                        </Typography>
                        <Typography 
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                        >
                            {t('processBoard.neededBy')}
                            {': ' + formatDate(new Date(byDate))}
                        </Typography>
                        {deliveryDate && 
                            <Typography 
                                color="textSecondary"
                                gutterBottom
                                variant="body2"
                            >
                                {t('processBoard.deliveryDate')}
                                {': ' + formatDate(new Date(deliveryDate))}
                            </Typography>}
                        {dispatchedDate && 
                            <Typography 
                                color="textSecondary"
                                gutterBottom
                                variant="body2"
                            >
                                {t('processBoard.dispatchedDate')}
                                {': ' + formatDate(new Date(dispatchedDate))}
                            </Typography>}
                    </Grid>
                </Grid>
                <Typography 
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                >
                    {t('processBoard.commodity')}
                    {`: ${commodity}`}
                </Typography>
                <Typography 
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                >
                    {t('processBoard.customer')}
                    {`: ${companyName}`}
                </Typography>
                {immediate ?
                    <Chip
                        className={classes.immediate}
                        label="Immediate"
                        size="small"
                        variant="outlined"
                    /> : null}
            </CardContent>
            {!lastStep &&
            <CardActions
                className={classes.actions}
            >
                {onButtonClick &&
                <Button
                    className={classes.expand}
                    disabled={submitting}
                    onClick={this.handleAdvance}
                    size="small"
                >
                    {t('processBoard.nextStage')}
                    {submitting ?
                        <CircularProgress size={24} /> :
                        <ArrowForwardIcon />}
                </Button>}
            </CardActions>}
        </Card>
    );
  }
}

export default withStyles(styles)(
    withTranslation()(
      withRouter(ProductCard)
    )
  );
