import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import INoteData from "../../model/INoteData";
import { AppThunk } from '../store';
import Network, { TYPES } from "../../utils/Network";
import { BidNotes } from "../../model/ISystemState";
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';

const initialState: BidNotes = {};

interface IBidNotes {
  bidId: string;
  notes: INoteData[];
}

const bidnotes = createSlice({
  name: 'bidnotes',
  initialState: initialState,
  reducers: {
    'addBidNotes': (state = initialState, action: PayloadAction<IBidNotes>) => {
      const payload = action.payload;
      state[payload.bidId] = payload.notes;
    },
    'addNote':  (state = initialState, action: PayloadAction<IBidNotes>) => {
      const payload = action.payload;
      state[payload.bidId].unshift(payload.notes[0]);
      return state;
    }
  }
});

export const { addBidNotes, addNote } = bidnotes.actions;

export default bidnotes.reducer;

export const fetchNotes = (bidId: string): AppThunk => (dispatch) => {
  Network.GET<INoteData[]>(`/bids/${bidId}/notes/`, TYPES.JSON).then((json) => {
    if (json) {
      dispatch(addBidNotes({
        bidId: bidId,
        notes: json
      }));
    }
  }, (err: Error) => {
    if (err.message in ERRORS) {
      dispatch(addError(err.message as ERRORS));
      return;
    }
    dispatch(addError(ERRORS.NETWORK_ERROR));
  });
}
