import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import ISystemState from "../../../../model/ISystemState";
import { connect, ConnectedProps } from 'react-redux';
import { setVerified, setSuspended, setArchived } from '../../../../redux/reducers/users';
import Typography from '@material-ui/core/Typography';
import TwoStageButton from '../../../forms/TwoStageButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Network, { TYPES, networkErrorHelper } from '../../../../utils/Network';
import ISuccess from '../../../../model/ISuccess';
import { addError } from '../../../../redux/reducers/errors';
import { ERRORS } from '../../../../model/Errors';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const classes = () => createStyles({
  contrast: {
    color: '#888888'
  },
  verify: {
    backgroundColor: 'red'
  },
  accordion: {
    width: '100%'
  },
  suspend: {
    backgroundColor: 'red'
  },
  reactivate: {
    backgroundColor: 'green'
  },
  noPlan: {
    color: '#cc3232',
    borderColor: '#cc3232'
  },
  withPlan: {
    color: '#2dc937',
    borderColor: '#2dc937'
  }
});

const mapState = (state: ISystemState) => {
  return {
    users: state.users
  };
}

const connector = connect(mapState, { setVerified, setSuspended, setArchived, addError });

interface IDetailsDialogProps extends WithTranslation, WithStyles,
    ConnectedProps<typeof connector> {
  open: boolean;
  onClose: () => void;
  userId: string;
}

interface IDetailsDialogState {
  open: boolean;
  submitting: boolean;
  justification: string;
}

class DetailsDialog extends React.Component<IDetailsDialogProps, IDetailsDialogState> {
  constructor(props: IDetailsDialogProps) {
    super(props);
    this.state = {
      open: props.open,
      submitting: false,
      justification: ''
    }
  }

  private handleVerify = () => {
    const {
      submitting
    } = this.state;

    const {
      userId
    } = this.props;

    if (submitting) return;

    this.setState({
      submitting: true
    });

    Network.PATCH<ISuccess>(`/users/${userId}/verification/`, TYPES.JSON, {
      verified: true
    }).then(() => {
      const {
        onClose,
        setVerified
      } = this.props;
      setVerified(userId);
      this.setState({
        submitting: false
      });
      onClose();
    }, (err: Error) => {
      const {
        addError
      } = this.props;
      networkErrorHelper(err.message as ERRORS, addError);
    })
  }

  private handleClose = () => {
    const {
      onClose
    } = this.props;
    onClose();
    this.setState({
      open: false
    });
  }

  private handleJustificationUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      justification: event.target.value as unknown as string
    })
  }

  private getUser = () => {
    const {
      users,
      userId
    } = this.props;

    return users.find((el) => {
      return el.contactDetails.id === userId;
    })
  }

  private handleArchive = () => {
    const {
      justification
    } = this.state;

    const {
      userId
    } = this.props;

    const user = this.getUser();

    if (!user) return;

    this.manageUser('archive', {
      justification,
      archived: !user.contactDetails.archived
    }, () => {
      const {
        setArchived
      } = this.props;
      setArchived(userId);
    });
  }


  private handleSuspend = () => {
    const {
      justification
    } = this.state;

    const {
      userId
    } = this.props;

    const user = this.getUser();

    if (!user) return;

    this.manageUser('suspension', {
      justification,
      suspended: !user.contactDetails.suspended
    }, () => {
      const {
        setSuspended
      } = this.props;
      setSuspended(userId);
    });
  }

  private manageUser = (action: string, value: Record<string, unknown>, onSubmit: () => void) => {
    const {
      submitting
    } = this.state;

    const {
      userId
    } = this.props;

    if (submitting) return;

    this.setState({
      submitting: true
    });

    Network.PATCH<ISuccess>(`/users/${userId}/${action}/`, TYPES.JSON, value).then(() => {
      onSubmit();
      this.setState({
        submitting: false
      });
      this.handleClose();
    }, (err: Error) => {
      const {
        addError
      } = this.props;
      networkErrorHelper(err.message as ERRORS, addError);
    })
  }

  private getField = (caption: string, text: string, width: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12) => {
    const {
      classes
    } = this.props;

    if (text) {
        return (
            <Grid
                item
                xs={width}
            >
                <Typography
                    className={classes.contrast}
                    variant="caption"
                >
                    {`${caption}: `}
                </Typography>
                <Typography>
                    {text}
                </Typography>
            </Grid>
        );
    }
  }

  private getActionForm = (tSuffix: string, onSubmit: () => void, status?: boolean) => {
    const {
      classes,
      t
    } = this.props;
    const {
      submitting,
      justification
    } = this.state

    return (
        <ValidatorForm
            onSubmit={onSubmit}
            style={{
                width: '100%'
            }}
        >
            <Grid
                item
                xs={12}
            >
                <Grid
                    alignItems="center"
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={8}
                    >
                        <TextValidator
                            disabled={submitting}
                            errorMessages={[
                              t(`userManagement.details.errors.tooShort`),
                              t(`userManagement.details.errors.tooLong`)
                            ]}
                            fullWidth
                            id="justification"
                            label={t(`userManagement.details.justification`).toString()}
                            name="justification"
                            onChange={this.handleJustificationUpdate}
                            validators={['required', 'minStringLength:10', 'maxStringLength:256']}
                            value={justification}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={3}
                    >
                        <Button
                            className={status ? classes.reactivate : classes.suspend}
                            disabled={submitting}
                            type="submit"
                            variant="contained"
                        >
                            {status ? t(`userManagement.details.${tSuffix}.off`) : t(`userManagement.details.${tSuffix}.on`)}
                            {submitting &&
                                <CircularProgress
                                    size={24}
                                />}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
  }

  private getUserActions = (verified?: boolean, suspended?: boolean, archived?: boolean) => {
    const {
      classes,
      t
    } = this.props;

    return (
        <Accordion
            className={classes.accordion}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Typography
                        className={classes.contrast}
                        variant="h6"
                    >
                        {t('userManagement.details.manageUser')}
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                {!verified && this.getActionForm('archive', this.handleArchive, archived)}
                {verified ? this.getActionForm('suspend', this.handleSuspend, suspended) : null}
            </AccordionDetails>
        </Accordion>
    )
  }

  public render() {
    const {
      t,
      users,
      userId,
      classes
    } = this.props;

    const {
      open,
      submitting
    } = this.state;

    const user = users.find((el) => {
      return el.contactDetails.id === userId;
    });

    return (user &&
        <div>
            <Dialog
                disableBackdropClick={submitting}
                disableEscapeKeyDown={submitting}
                fullWidth
                maxWidth='md'
                onClose={this.handleClose}
                open={open}
            >
                <DialogContent>
                    <DialogContentText />
                    <Grid
                        container
                        spacing={1}
                    >
                        <Accordion
                            className={classes.accordion}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        className={classes.contrast}
                                        variant="h6"
                                    >
                                        {t('userManagement.details.companyinfo')}
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid
                                    container
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs={6}
                                    >
                                        <Typography
                                            className={classes.contrast}
                                            variant="caption"
                                        >
                                            {`${t('userManagement.details.companyName')}: `}
                                        </Typography>
                                        <Typography>
                                            <Link
                                                href={`${process.env.REACT_APP_REMOTE_URL || ''}/redirector/clients/?id=${user.companyDetails.accountingId || ''}`}
                                                target="_blank"
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        flexWrap: 'wrap',
                                                    }}
                                                >
                                                    <span>
                                                        {user.companyDetails.companyName}
                                                    </span>
                                                    <OpenInNewIcon
                                                        fontSize="small"
                                                    />
                                                </div>
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    {this.getField(t('userManagement.details.industry'), user.companyDetails.industry.name, 6)}
                                    {this.getField(t('userManagement.details.address1'), user.companyDetails.address1, 12)}
                                    {this.getField(t('userManagement.details.address2'), user.companyDetails.address2, 12)}
                                    {this.getField(t('userManagement.details.country'), user.companyDetails.country.name, 6)}
                                    {this.getField(t('userManagement.details.city'), user.companyDetails.city, 6)}
                                    {this.getField(t('userManagement.details.state'), user.companyDetails.state, 6)}
                                    {this.getField(t('userManagement.details.zip'), user.companyDetails.zip, 6)}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            className={classes.accordion}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        className={classes.contrast}
                                        variant="h6"
                                    >
                                        {t('userManagement.details.billinginfo')}
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid
                                    container
                                    spacing={1}
                                >
                                    {this.getField(t('userManagement.details.companyName'), user.billingDetails.companyName, 12)}
                                    {this.getField(t('userManagement.details.address1'), user.billingDetails.address1, 12)}
                                    {this.getField(t('userManagement.details.address2'), user.billingDetails.address2, 12)}
                                    {this.getField(t('userManagement.details.country'), user.billingDetails.country.name, 6)}
                                    {this.getField(t('userManagement.details.city'), user.billingDetails.city, 6)}
                                    {this.getField(t('userManagement.details.state'), user.billingDetails.state, 6)}
                                    {this.getField(t('userManagement.details.zip'), user.billingDetails.zip, 6)}
                                    {this.getField(t('userManagement.details.vat'), user.billingDetails.vat, 6)}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            className={classes.accordion}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        className={classes.contrast}
                                        variant="h6"
                                    >
                                        {t('userManagement.details.personaldetails')}
                                    </Typography>
                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid
                                    container
                                    spacing={1}
                                >
                                    {this.getField(t('userManagement.details.firstName'), user.contactDetails.firstName, 6)}
                                    {this.getField(t('userManagement.details.lastName'), user.contactDetails.lastName, 6)}
                                    {this.getField(t('userManagement.details.position'), user.contactDetails.position, 12)}
                                    {this.getField(t('userManagement.details.email'), user.contactDetails.email, 6)}
                                    {this.getField(t('userManagement.details.phonenumber'), user.contactDetails.phonenumber, 6)}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        {this.getUserActions(user.contactDetails.verified, user.contactDetails.suspended, user.contactDetails.archived)}
                        <Grid
                            alignItems="center"
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xs={6}
                            />
                            <Grid
                                item
                                xs={6}
                            >
                                <DialogActions>
                                    <Button
                                        onClick={this.handleClose}
                                    >
                                        {t('order.close')}
                                    </Button>
                                    {!user.contactDetails.verified && !user.contactDetails.archived &&
                                        <TwoStageButton
                                            buttonText={t('userManagement.verify')}
                                            classNameButton={classes.verify}
                                            classNameLabel={classes.lowContrast}
                                            labelText={t('userManagement.verifyConfirm')}
                                            name='exitBid'
                                            onClick={this.handleVerify}
                                        />}
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
  }
}

export default 
  withStyles(classes)(
    withTranslation()(
      connector(DetailsDialog)
    )
  );