import React from "react";
import clsx from 'clsx';
import { connect, ConnectedProps } from "react-redux";
import ISystemState from "../../../model/ISystemState";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withTranslation, WithTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Title from '../Title';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Roles } from '../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../Roles';
import Container from '@material-ui/core/Container';
import BidsListRow from './BirsListRow';
import ShowDetails from '../ShowDetails';
import { Switch, Route, withRouter, RouteComponentProps } from "react-router-dom";
import IBidData from '../../../model/IBidData';

const styles = (theme: Theme) => createStyles({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    noOverflow: {
        overflowY: 'hidden',
        overflowX: 'scroll'
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
});


const mapState = (state: ISystemState) => {
  return {
      bids: state.bids,
      bidStates: state.bidStates
  };
};

const connector = connect(mapState)

interface IBidsListProps extends ConnectedProps<typeof connector>,
    WithTranslation, WithStyles, IWithRoleProps, RouteComponentProps{
    title?: string;
    pathFragment: string;
    immutableDialog?: boolean;
    filter: (el: IBidData) => boolean;
    headerButton?: React.Component
  }

class BidsList extends React.Component<IBidsListProps> {

  private handleCloseDialog = () => {
    const {
      i18n,
      history,
      pathFragment
    } = this.props;
    history.push(`/${i18n.language}/${pathFragment}`);
  }

  private map = (el: IBidData) => {
      const {
        pathFragment,
        filter
      } = this.props;
      if (!filter(el)) return null;
      return (
          <BidsListRow
              el={el}
              key={el.timestamp}
              pathFragment={pathFragment}
          />
      );
  }

  public render() {
    const {
      bids,
      t,
      classes,
      i18n,
      title,
      pathFragment,
      immutableDialog,
      headerButton
    } = this.props;

    const noOverflowPaper = clsx(classes.paper, classes.noOverflow);

    return (
        <>
            <Container
                className={classes.container}
                maxWidth="lg"
            >
                <Grid
                    container
                    spacing={1}
                >
                    {title && 
                    <Title>
                        {title}
                        {headerButton && headerButton}
                    </Title>}
                    <Grid
                        container
                        item
                        spacing={3}
                        xs={12}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Paper 
                                className={noOverflowPaper}
                            >
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {t('bidsList.openDate')}
                                            </TableCell>
                                            <TableCell>
                                                {t('bidsList.commodity')}
                                            </TableCell>
                                            <TableCell>
                                                {t('bidsList.manufacturer')}
                                            </TableCell>
                                            <TableCell>
                                                {t('bidsList.product')}
                                            </TableCell>
                                            <TableCell>
                                                {t('bidsList.EAN')}
                                            </TableCell>
                                            <TableCell>
                                                {t('bidsList.partNumber')}
                                            </TableCell>
                                            <TableCell>
                                                {t('bidsList.neededBy')}
                                            </TableCell>
                                            <TableCell>
                                                {t('bidsList.quantity')}
                                            </TableCell>
                                            <TableCell>
                                                {t('bidsList.state')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {bids.map(this.map)}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Switch>
                <Route
                    exact
                    path={`/${i18n.language}/${pathFragment}/:bidId`}
                >
                    <ShowDetails
                        immutable={immutableDialog}
                        onClose={this.handleCloseDialog} 
                    />
                </Route>
            </Switch>
        </>
    );
  }
}

export default connector(withRoles(
        withRouter(
            withTranslation()(
                withStyles(styles)(BidsList)
            )
        ), [Roles.User]
    )
);
