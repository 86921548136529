import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

interface ITitleProps {
  children: PropTypes.ReactNodeLike
} 

export default function Title(props: ITitleProps): JSX.Element {
  const {
    children
  } = props;
  return (
      <Typography
          color="primary"
          component="h2"
          gutterBottom
          variant="h6"
      >
          {children}
      </Typography>
  );
}

