import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import ISystemState from '../../../model/ISystemState';
import IOfferData from '../../../model/IOfferData';
import { connect, ConnectedProps } from 'react-redux';
import Network, { TYPES, networkErrorHelper } from '../../../utils/Network';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchOffer, updatePrice } from '../../../redux/reducers/offers';
import store from '../../../redux/store';
import { AutocompleteValidator } from '../../forms/AutocompleteValidator';
import { fetchVendors } from '../../../redux/reducers/vendors';
import IVendor from '../../../model/IVendor';
import BidData from '../BidData';
import { addError } from '../../../redux/reducers/errors';
import { ERRORS } from '../../../model/Errors';
import EditableField from './EditableField';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';


function autoCompleteComparator<T extends IVendor>(option: T, value: T) : boolean {
  return option.name === value.name;
}

const styles = () => createStyles({
  centerAligned: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '8px',
    display: 'flex',
    height: '100%'
  },
  contrast: {
    color: '#888888'
  },
  unpaddedDialog: {
    paddingTop: '0px!important'
  }
});

const mapState = (state: ISystemState) => {
  return {
    bidStates: state.bidStates,
    bids: state.bids,
    allbids: state.allbids,
    offers: state.offers,
    vendors: state.vendors
  };
}

const connector = connect(mapState, { addError, updatePrice });

interface IPODialogProps extends WithTranslation, WithStyles,
    ConnectedProps<typeof connector> {
  open: boolean;
  onClose:  () => void;
  onSend: (bidId: string, cb: () => void) => void;
  bidId: string;
}

interface IPODialogState {
  open: boolean;
  unitPrice: number;
  submitting: boolean;
  preventSubmit: boolean;
  email: string;
  vendor: IVendor;
}

class PODialog extends React.Component<IPODialogProps, IPODialogState> {
  constructor(props: IPODialogProps) {
    super(props);
    this.state = {
      open: props.open,
      unitPrice: 0,
      submitting: false,
      preventSubmit: false,
      email: '',
      vendor: {id: '', name: '', email: ''} as IVendor
    }
  }

  public componentDidMount() {
    const {
      offers,
      vendors,
      bidId
    } = this.props;

    if (!offers[bidId]) {
      (store.dispatch as ThunkDispatch<ISystemState, void, AnyAction>)(fetchOffer(bidId));
    }

    if (!vendors.length) {
      (store.dispatch as ThunkDispatch<ISystemState, void, AnyAction>)(fetchVendors());
    }

    ValidatorForm.addValidationRule('hasVendor', (value: IVendor) => {
      if (!value) return false;
      return value.id !== '';
    });

    ValidatorForm.addValidationRule('mustMatch', (value: string) => {
      const {
        vendor
      } = this.state;

      return value === vendor.email;
    });
  }

  public componentWillUnmount() {
    ValidatorForm.removeValidationRule('hasVendor');
    ValidatorForm.removeValidationRule('mustMatch');
  }

  private handleClose = () => {
    const {
      onClose
    } = this.props;
    onClose();
    this.setState({
      open: false
    });
  }

  private handleNetworkError = (err: Error) => {
    const {
      addError
    } = this.props;
    networkErrorHelper(err.message as ERRORS, addError);
  }

  private handleSubmit = () => {
    const {
      submitting,
      vendor
    } = this.state;
    const {
      bidId,
      offers,
      onSend,
      onClose
    } = this.props;
    if (submitting) return;
    this.setState({
      submitting: true
    });

    Network.PATCH<IOfferData|null>(`/bids/${bidId}/offer/`, TYPES.JSON, {
      offerId: offers[bidId].offerId,
      vendorId: vendor.id
    }).then(() => {
      onSend(bidId, () => {return;});
      onClose();
      this.setState({
        preventSubmit: false,
      });
    }, this.handleNetworkError)
  }

  private handleSelectVendor = (vendor: IVendor) => {
    this.setState({
      vendor: vendor
    })
  }

  private handleConfirmEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value
    });
  }

  private handlePriceChange = () => {
    this.setState({
      preventSubmit: true
    });
  }

  private handlePriceUpdate = (newPrice: string, cb: () => void) => {
    const {
      bidId,
      offers
    } = this.props;

    Network.PATCH<IOfferData>(`/bids/${bidId}/offer/`, TYPES.JSON, {
      offerId: offers[bidId].offerId,
      newPrice: parseFloat(newPrice)
    }).then((data: IOfferData) => {
      const {
        updatePrice
      } = this.props;
      
      this.setState({
        preventSubmit: false
      });

      updatePrice(data);
      cb();
    }, this.handleNetworkError);
  }

  private getOptionLabel = (option: IVendor): string => {
    const {
      t
    } = this.props;
    if (!option.name && !option.email) return '';

    return `${option.name} <${option.email}> ${t('order.emaillanguage')}: ${option.locale}`;
  }

  public render() {
    const {
      t,
      classes,
      allbids,
      bids,
      bidId,
      offers,
      vendors
    } = this.props;

    const {
      open,
      submitting,
      vendor,
      email,
      preventSubmit
    } = this.state;

    let el = allbids.find((el) => {
      return el.bidId === bidId;
    });

    if (el === undefined) {
      el = bids.find((el) => {
        return el.bidId === bidId;
      });
    }

    let newPrice = 0;
    if (offers[bidId]) {
      newPrice = offers[bidId].unitPrice;
    }

    let submitDisabled;
    if (preventSubmit || !vendor || !vendor.id || (vendor.email !== email)) {
      submitDisabled = true;
    }

    return (el &&
        <div>
            <Dialog
                fullWidth
                maxWidth='md'
                onClose={this.handleClose}
                open={open}
            >
                <DialogContent>
                    <DialogContentText />
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Grid
                                alignItems="flex-end"
                                container
                                spacing={1}
                            >
                                <BidData el={el} />
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <EditableField
                                        canEdit
                                        caption={t('order.unitPrice').toString()}
                                        errorMessages={[t('order.errors.required')]}
                                        onChange={this.handlePriceChange}
                                        onUpdate={this.handlePriceUpdate}
                                        validators={['required', 'isFloat', 'minFloat:0.01', `maxFloat:${newPrice}`]}
                                        value={newPrice}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <ValidatorForm
                    instantValidate={false}
                    onSubmit={this.handleSubmit}
                >
                    <DialogContent
                        className={classes.unpaddedDialog}
                    >
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Grid
                                    alignItems="flex-end"
                                    container
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <AutocompleteValidator
                                            errorMessages={[t('place_PO.errors.required')]}
                                            fullWidth
                                            getOptionLabel={this.getOptionLabel}
                                            getOptionSelected={autoCompleteComparator}
                                            id="vendor"
                                            label={t('place_PO.vendor').toString()}
                                            name="vendor"
                                            onChange={this.handleSelectVendor}
                                            options={vendors}
                                            validators={['hasVendor']}
                                            value={vendor}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <TextValidator
                                            errorMessages={[
                                              t('place_PO.errors.required'),
                                              t('place_PO.errors.mustMatch'),
                                            ]}
                                            fullWidth
                                            id='confirmEmail'
                                            label={t('place_PO.confirmEmail').toString()}
                                            name="confirmEmail"
                                            onChange={this.handleConfirmEmail}
                                            validators={['required', 'mustMatch']}
                                            value={email}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <DialogActions>
                                    <Button
                                        onClick={this.handleClose}
                                    >
                                        {t('order.close')}
                                    </Button>
                                    <Button 
                                        color="primary"
                                        disabled={submitDisabled || submitting}
                                        type="submit"
                                        variant="contained"
                                    >
                                        {t('order.send')}
                                        {submitting &&
                                            <CircularProgress
                                                className={classes.buttonProgress}
                                                size={24}
                                            />}
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </ValidatorForm>
            </Dialog>
        </div>
    );
  }
}

export default 
  withStyles(styles)(
    withTranslation()(
      connector(PODialog)
    )
  );