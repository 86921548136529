import React, { ComponentType } from 'react';

import { connect, ConnectedProps } from "react-redux";
import ISystemState from "../model/ISystemState";
import { Roles } from "../model/Roles";
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';
import store from '../redux/store';
import { fetchUser } from '../redux/reducers/user';

(store.dispatch as ThunkDispatch<ISystemState, void, AnyAction>)( fetchUser() );


const mapStateToProps = (state: ISystemState) => {
  return {
    role: Roles[state.user.role as keyof typeof Roles]
  };
};

const connector = connect(mapStateToProps);

export interface IWithRoleProps extends ConnectedProps<typeof connector>{
  role: Roles
}
/* eslint-disable */
// failed to convince compiler
export const withRoles = <P extends IWithRoleProps>(Component: ComponentType<P>, targetRoles: Array<Roles>) => {

  class WithRoles extends React.Component<any> {
  /* eslint-enable */
    render() {
      const {
        role
      } = this.props;
      if (targetRoles.indexOf(role) !== -1) {
        return (
            <Component
                {...this.props as P}
            />
        );
      } else {
        return (
            null
        );
      }
    }
  }

  return connector(WithRoles);
}