import React from "react";
import { Roles } from '../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../Roles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PeopleIcon from '@material-ui/icons/People';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { withTranslation, WithTranslation } from 'react-i18next';
import Signout from '../Signout';
import { Link } from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

interface IUserDrawerProps extends IWithRoleProps, WithTranslation {}

class UserDrawer extends React.Component<IUserDrawerProps> {
    private getListItem = (path: string, text: string, icon: JSX.Element) => {
        return (
            <Link
                style={{textDecoration: 'none', color: 'inherit'}}
                to={path}
            >
                <ListItem button>
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                </ListItem>
            </Link>);
    }

  public render() {
    const {
        t,
        i18n
    } = this.props;
    return (
        <>
            {this.getListItem(`/${i18n.language}/home`, t('drawer.dashboard'), <DashboardIcon />)}
            {this.getListItem(`/${i18n.language}/userManagement`, t('drawer.userManagement'), <PeopleIcon />)}
            <Box
                alignItems="flex-end"
                display="flex"
                height="calc(100% - 96px)"
            >
                <Divider />
                <Signout />
            </Box>
        </>
    );
  }
}

export default 
    withRoles(withTranslation()(UserDrawer), [Roles.Buyer]);
