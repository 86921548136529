import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { formatDateForStorage } from '../../../utils/Date';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import validbarcode from 'barcode-validator';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { withTranslation, WithTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { connect, ConnectedProps } from 'react-redux';
import { addOwnBid } from '../../../redux/reducers/bids';
import { addError } from '../../../redux/reducers/errors';
import { ERRORS } from '../../../model/Errors';
import Network, { TYPES, networkErrorHelper } from '../../../utils/Network';
import { IOrderData } from '../../../model/IOrderData';
import IBidData from '../../../model/IBidData';
import { Typography } from '@material-ui/core';
import ISystemState from "../../../model/ISystemState";
import Tooltip from '@material-ui/core/Tooltip';

const styles = () => createStyles({
  centerAligned: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '8px',
    display: 'flex',
    height: '100%'
  },
});

const NEAR_DAYS = 8;
const FAR_DAYS = 179;
const SEARCH_DELAY = 1000; // in ms

// Autocomplete must always show all the results - filtering and ranking happens on the server side
const filterOptions = (options: Record<string, string>[]) => options;

const mapState = (state: ISystemState) => {
  return {
    verified: state.user.verified
  };
};

const connector = connect(mapState, { addOwnBid, addError });

interface IPlaceBidDialogProps extends WithTranslation, WithStyles,
    ConnectedProps<typeof connector> {
  open: boolean;
  onClose:  () => void;
}

interface IPlaceBidDialogState extends IOrderData {
  open: boolean;
  // "private" state value to keep the non primitive date type during the compoenent life
  _byDate: Date;
  partNumberEmpty: boolean;
  EANEmpty: boolean;
  submitting: boolean;
  tabstate: number;
  searchItems: Record<string, string>[];
  loadingSearch: boolean;
}

class PlaceBidDialog extends React.Component<IPlaceBidDialogProps, IPlaceBidDialogState> {
  constructor(props: IPlaceBidDialogProps) {
    super(props);
    this.state = {
      open: props.open,
      commodity: '',
      product: '',
      manufacturer: '',
      EAN: '',
      partNumber: '',
      quantity: 0,
      byDate: '',
      _byDate: (new DateFnsUtils()).addDays(new Date(), NEAR_DAYS),
      immediate: false,
      partNumberEmpty: false,
      EANEmpty: false,
      submitting: false,
      tabstate: 0,
      searchItems: [],
      loadingSearch: true
    }
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('requiredEAN', (value) => {
      const {
        partNumber
      } = this.state;
      return value !== '' || partNumber !== '';
    });
    ValidatorForm.addValidationRule('requiredPartNumber', (value) => {
      const {
        EAN
      } = this.state;
      return value !== '' || EAN !== '';
    });
    ValidatorForm.addValidationRule('EANValid', (value) => {
      if (!value) return true;
      return validbarcode(value);
    });
  }

  public componentWillUnmount() {
    ValidatorForm.removeValidationRule('requiredEAN');
    ValidatorForm.removeValidationRule('requiredPartNumber');
    ValidatorForm.removeValidationRule('EANValid');
  }

  private timeout = 0;

  private handleClose = () => {
    const {
      onClose
    } = this.props;
    onClose();
    this.setState({
      open: false
    });
  }

  private handleNetworkError = (err: Error) => {
    const {
      addError
    } = this.props;
    networkErrorHelper(err.message as ERRORS, addError);
  }

  private handleSubmit = () => {
    const {
      commodity,
      product,
      manufacturer,
      EAN,
      partNumber,
      quantity,
      _byDate,
      immediate,
      submitting
    } = this.state;

    const {
      addOwnBid,
      verified,
      addError
    } = this.props;

    if (!verified) {
      addError(ERRORS.USER_NOT_VERIFIED);
      return;
    }

    if (submitting) return;

    this.setState({
      submitting: true
    });

    const data = {
      commodity,
      product,
      manufacturer,
      EAN,
      partNumber,
      quantity,
      byDate: formatDateForStorage(new Date(_byDate)),
      immediate,
    };

    Network.POST<IBidData[]>('/bids', TYPES.JSON, data).then((data) => {
      addOwnBid(data[0]);
      this.handleClose();
    }, this.handleNetworkError);
  }

  private handleSetCommodity = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      commodity: event.target.value
    })
  }

  private handleSetProduct = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      product: event.target.value
    })
  }

  private handleSetManufacturer = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      manufacturer: event.target.value
    })
  }

  private handleSetEAN = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    this.setState({
      partNumberEmpty: val === '' ? false : true,
      EAN: event.target.value
    })
  }

  private handleSetPartNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    this.setState({
      EANEmpty: val === '' ? false : true,
      partNumber: event.target.value
    })
  }

  private handleSetDate = (date: Date | null) => {
    if (date) {
      this.setState({
        _byDate: date
      })
    }
  }

  private handleSetQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const q = parseInt(event.target.value, 10);
    this.setState({
      quantity: isNaN(q) ? 0 : q
    })
  }

  private handleProcessImmediately = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ immediate: event.target.checked });
  }

  private handleSearch = (event: React.ChangeEvent<unknown>, newValue: string) => {
    clearTimeout(this.timeout);
    if (newValue) {
      this.timeout = window.setTimeout(() => {
        Network.GET<Record<string, string>[]>('/search/', TYPES.JSON, false, {q: newValue}).then((newItems) => {
          const {
            searchItems
          } = this.state;

          if (newItems?.length) {
            this.setState({
              searchItems: newItems.concat(searchItems),
              loadingSearch: false
            });
          } else {
            this.setState({
              loadingSearch: false
            });
          }
          this.timeout = 0;
        }, this.handleNetworkError);
      }, SEARCH_DELAY);
    }
    this.setState({
      searchItems: [],
      loadingSearch: true
    });
  }

  private handleSearchChange = (event: React.ChangeEvent<unknown>, newValue: Record<string, string>|null) => {
    if (newValue) {
      clearTimeout(this.timeout);
      this.setState({
        EAN: newValue.upcean,
        product: newValue.name
      });
    } else {
      this.setState({
        EAN: ''
      });
    }
  }

  private getOptionLabel = (option: Record<string, unknown>) => {
    const {
      t
    } = this.props;
    if (option.id === -1) {
      return `+${option.extraResults as number} ${t('order.smartsearch.extraResults')}`;
    }
    return option.name as string;
  }

  private getOptionDisabled = (option: Record<string, unknown>) => {
    return option.id === -1;
  }

  private renderAutoCompleteInput = (params: AutocompleteRenderInputParams) => {
    const {
      t
    } = this.props;

    return (
        <TextField
            {...params}
            label={t('order.smartsearch.label')}
        />);
  }

  private handleSwitchTab = (event: React.ChangeEvent<unknown>, newValue: number) => {
    this.setState({
      tabstate: newValue
    })
  }

  public render() {
    const {
      t,
      classes
    } = this.props;
    const {
      open,
      commodity,
      product,
      manufacturer,
      EAN,
      partNumber,
      quantity,
      _byDate: byDate,
      immediate,
      partNumberEmpty,
      EANEmpty,
      submitting,
      tabstate,
      searchItems,
      loadingSearch
    } = this.state;

    const today = new Date();

    return (
        <div>
            <Dialog
                maxWidth='md'
                onClose={this.handleClose}
                open={open}
            >
                <DialogContent>
                    <DialogContentText />
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Tabs
                                indicatorColor="primary"
                                onChange={this.handleSwitchTab}
                                textColor="primary"
                                value={tabstate}
                            >
                                <Tab label={t('order.regularForm')} />
                                {/* <Tab label={t('order.smartForm')} /> */}
                                <Tab label={(
                                    <Typography
                                        style={{marginTop: "-0.2em"}}
                                        variant="body2"
                                    >
                                        {t('order.smartForm')}
                                        <span
                                            style={{verticalAlign: "super", fontSize: "0.7em", color: "red"}}
                                        >
                                            {t('order.smartsearch.beta')}
                                        </span>
                                    </Typography>)}
                                />
                            </Tabs>
                        </Grid>
                    </Grid>
                    {tabstate === 0 &&
                    <ValidatorForm
                        instantValidate={false}
                        onSubmit={this.handleSubmit}
                    >
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <TextValidator
                                    autoFocus
                                    errorMessages={[
                                        t('order.errors.required'),
                                        t('order.errors.nameTooShort'),
                                        t('order.errors.nameTooLong')]}
                                    fullWidth
                                    id="commodity"
                                    label={t('order.commodity').toString()}
                                    name="commodity"
                                    onChange={this.handleSetCommodity}
                                    validators={['required', 'minStringLength:2', 'maxStringLength:100']}
                                    value={commodity}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <TextValidator
                                    errorMessages={[t('order.errors.required'), t('order.errors.nameTooShort'), t('order.errors.nameTooLong')]}
                                    fullWidth
                                    id="product"
                                    label={t('order.product').toString()}
                                    name="product"
                                    onChange={this.handleSetProduct}
                                    validators={['required', 'minStringLength:2', 'maxStringLength:100']}
                                    value={product}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <TextValidator
                                    errorMessages={[t('order.errors.required'), t('order.errors.nameTooShort'), t('order.errors.nameTooLong')]}
                                    fullWidth
                                    id="manufacturer"
                                    label={t('order.manufacturer').toString()}
                                    name="manufacturer"
                                    onChange={this.handleSetManufacturer}
                                    validators={['required', 'minStringLength:2', 'maxStringLength:100']}
                                    value={manufacturer}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={3}
                            >
                                <TextValidator
                                    disabled={EANEmpty}
                                    errorMessages={[
                                      t('order.errors.required'),
                                      t('order.errors.eanNumbersOnly'),
                                      t('order.errors.eanInvalid')
                                    ]}
                                    fullWidth
                                    id="EAN"
                                    label={t('order.EAN').toString()}
                                    name="EAN"
                                    onChange={this.handleSetEAN}
                                    validators={[
                                      'requiredEAN',
                                      'matchRegexp:^\\d{8,13}$',
                                      'EANValid'
                                    ]}
                                    value={EAN}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={2}
                            >
                                <Container className={classes.centerAligned}>
                                    {t('order.or').toString()}
                                </Container>
                            </Grid>
                            <Grid
                                item
                                xs={7}
                            >
                                <TextValidator
                                    disabled={partNumberEmpty}
                                    errorMessages={[t('order.errors.required'), t('order.errors.nameTooShort'), t('order.errors.nameTooLong')]}
                                    fullWidth
                                    id="partNumber"
                                    label={t('order.partNumber').toString()}
                                    name="partNumber"
                                    onChange={this.handleSetPartNumber}
                                    validators={['requiredPartNumber']}
                                    value={partNumber}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                            >
                                <TextValidator
                                    errorMessages={[t('order.errors.required'), 
                                      t('order.errors.quantityNaN'),
                                      t('order.errors.quantityTooSmall'),
                                      t('order.errors.quantityTooLarge')
                                    ]}
                                    fullWidth
                                    id="quantity"
                                    label={t('order.quantity').toString()}
                                    name="quantity"
                                    onChange={this.handleSetQuantity}
                                    validators={['required', 'isNumber', 'minNumber:1', 'maxNumber:100000000']}
                                    value={quantity}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={2}
                            />
                            <Grid
                                item
                                xs={7}
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        format="dd/MM/yyyy"
                                        id="date-picker"
                                        label={t('order.neededBy')}
                                        maxDate={(new DateFnsUtils()).addDays(today, FAR_DAYS)}
                                        minDate={(new DateFnsUtils()).addDays(today, NEAR_DAYS)}
                                        onChange={this.handleSetDate}
                                        value={byDate}
                                        variant="inline"
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <FormControlLabel
                                    checked={immediate}
                                    control={
                                        <Checkbox
                                            color="secondary"
                                            name="processImmediately"
                                            onChange={this.handleProcessImmediately}
                                            value={false}
                                        />
                                  }
                                    label={t('order.processImmediately')}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <DialogActions>
                                    <Button 
                                        disabled={submitting}
                                        onClick={this.handleClose}
                                    >
                                        {t('order.cancel')}
                                    </Button>
                                    <Tooltip
                                        title={t('order.buttonTooltip').toString()}
                                    >
                                        <Button
                                            color="primary"
                                            disabled={submitting}
                                            type="submit"
                                            variant="contained"
                                        >
                                            {t('order.send')}
                                            {submitting &&
                                                <CircularProgress
                                                    className={classes.buttonProgress}
                                                    size={24}
                                                />}
                                        </Button>
                                    </Tooltip>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </ValidatorForm>}
                    {tabstate === 1 &&
                    <ValidatorForm
                        instantValidate={false}
                        onSubmit={this.handleSubmit}
                    >
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Autocomplete
                                    filterOptions={filterOptions}
                                    fullWidth
                                    getOptionDisabled={this.getOptionDisabled}
                                    getOptionLabel={this.getOptionLabel}
                                    id="smartsearch"
                                    loading={loadingSearch}
                                    loadingText={t('order.smartsearch.loading')}
                                    noOptionsText={t('order.smartsearch.notfound')}
                                    onChange={this.handleSearchChange}
                                    onInputChange={this.handleSearch}
                                    options={searchItems}
                                    renderInput={this.renderAutoCompleteInput}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={3}
                            >
                                <TextValidator
                                    disabled
                                    errorMessages={[
                                      t('order.errors.required'),
                                      t('order.errors.eanNumbersOnly'),
                                      t('order.errors.eanInvalid')
                                    ]}
                                    fullWidth
                                    id="EAN"
                                    label={t('order.EAN').toString()}
                                    name="EAN"
                                    onChange={this.handleSetEAN}
                                    validators={[
                                      'requiredEAN',
                                      'matchRegexp:^\\d{8,13}$',
                                      'EANValid'
                                    ]}
                                    value={EAN}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={9}
                            />
                            <Grid
                                item
                                xs={3}
                            >
                                <TextValidator
                                    errorMessages={[t('order.errors.required'), 
                                      t('order.errors.quantityNaN'),
                                      t('order.errors.quantityTooSmall'),
                                      t('order.errors.quantityTooLarge')
                                    ]}
                                    fullWidth
                                    id="quantity"
                                    label={t('order.quantity').toString()}
                                    name="quantity"
                                    onChange={this.handleSetQuantity}
                                    validators={['required', 'isNumber', 'minNumber:1', 'maxNumber:100000000']}
                                    value={quantity}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={2}
                            />
                            <Grid
                                item
                                xs={7}
                            >
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        format="dd/MM/yyyy"
                                        id="date-picker"
                                        label={t('order.neededBy')}
                                        maxDate={(new DateFnsUtils()).addDays(today, FAR_DAYS)}
                                        minDate={(new DateFnsUtils()).addDays(today, NEAR_DAYS)}
                                        onChange={this.handleSetDate}
                                        value={byDate}
                                        variant="inline"
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <FormControlLabel
                                    checked={immediate}
                                    control={
                                        <Checkbox
                                            color="secondary"
                                            name="processImmediately"
                                            onChange={this.handleProcessImmediately}
                                            value={false}
                                        />
                                  }
                                    label={t('order.processImmediately')}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <DialogActions>
                                    <Button 
                                        disabled={submitting}
                                        onClick={this.handleClose}
                                    >
                                        {t('order.cancel')}
                                    </Button>
                                    <Button 
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                    >
                                        {t('order.send')}
                                        {submitting &&
                                            <CircularProgress
                                                className={classes.buttonProgress}
                                                size={24}
                                            />}
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </ValidatorForm>}
                </DialogContent>
            </Dialog>
        </div>
    );
  }
}

export default 
  withStyles(styles)(
    withTranslation()(
      connector(PlaceBidDialog)));