import React from "react";
import clsx from 'clsx';
import { connect, ConnectedProps } from "react-redux";
import ISystemState from "../../../../model/ISystemState";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withTranslation, WithTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Title from '../../Title';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Roles } from '../../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../../Roles';
import Container from '@material-ui/core/Container';
import { Profile } from '../../../../model/IUserData';
import Network, { TYPES, networkErrorHelper } from '../../../../utils/Network';
import { setUsers } from '../../../../redux/reducers/users';
import UsersRow from './Row';
import DetailsDialog from './DetailsDialog';
import { addError } from '../../../../redux/reducers/errors';
import { ERRORS } from '../../../../model/Errors';

const styles = (theme: Theme) => createStyles({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    noOverflow: {
        overflowY: 'hidden',
        overflowX: 'scroll'
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
});


const mapState = (state: ISystemState) => {
  return {
    users: state.users,
  };
};

const connector = connect(mapState, { setUsers, addError })

interface IUsersProps extends ConnectedProps<typeof connector>, WithTranslation, WithStyles, IWithRoleProps{}

interface IUsersState {
  dialogOpen: boolean;
  userId: string;
}

class Users extends React.Component<IUsersProps, IUsersState> {

  constructor(props: IUsersProps) {
    super(props);
    this.state = {
      dialogOpen: false,
      userId: ''
    };
  }

  public componentDidMount = () => {
    const {
      users
    } = this.props;

    if (users.length === 0) {
      Network.GET<Profile[]>('/users/all/', TYPES.JSON).then((res) => {
        const {
          setUsers
        } = this.props;
        if (res) {
          setUsers(res);
        }
      }, (err: Error) => {
        const {
          addError
        } = this.props;
        networkErrorHelper(err.message as ERRORS, addError);
      });
    }
  }

  private handleRowClick = (userId: string) => {
    this.setState({
      dialogOpen: true,
      userId: userId
    });
  }

  private handleCloseDialog = () => {
    this.setState({
      dialogOpen: false
    });
  }

  private filter = (el: Profile, status: boolean, archived: boolean) => {
    if (!!el.contactDetails.verified === status && !!el.contactDetails.archived === archived) {
      return (
          <UsersRow
              key={el.contactDetails.id}
              onRowClick={this.handleRowClick}
              user={el}
          />
      )
    }
  }

  private notVerified = (el: Profile) => {
    return this.filter(el, false, false);
  }

  private notVerifiedArchived = (el: Profile) => {
    return this.filter(el, false, true);
  }

  private verified = (el: Profile) => {
    return this.filter(el, true, false);
  }

  private getTableHeader = () => {
    const {
      t
    } = this.props;
    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    {t('userManagement.details.companyName')}
                </TableCell>
                <TableCell>
                    {t('userManagement.details.phonenumber')}
                </TableCell>
                <TableCell>
                    {t('userManagement.details.email')}
                </TableCell>
                <TableCell>
                    {t('userManagement.fullname')}
                </TableCell>
            </TableRow>
        </TableHead>)
  }

  public render() {
    const {
      dialogOpen,
      userId
    } = this.state;

    const {
      users,
      t,
      classes
    } = this.props;

    const noOverflowPaper = clsx(classes.paper, classes.noOverflow);

    return (
        <>
            <Container
                className={classes.container}
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Title>
                        {t('userManagement.needVerification')}
                    </Title>
                    <Grid
                        container
                        item
                        spacing={3}
                        xs={12}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Paper 
                                className={noOverflowPaper}
                            >
                                <Table>
                                    {this.getTableHeader()}
                                    <TableBody>
                                        {users.map(this.notVerified)}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Container
                className={classes.container}
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Title>
                        {t('userManagement.allUsers')}
                    </Title>
                    <Grid
                        container
                        item
                        spacing={3}
                        xs={12}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Paper 
                                className={noOverflowPaper}
                            >
                                <Table>
                                    {this.getTableHeader()}
                                    <TableBody>
                                        {users.map(this.verified)}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Container
                className={classes.container}
            >
                <Grid
                    container
                    spacing={1}
                >
                    <Title>
                        {t('userManagement.archived')}
                    </Title>
                    <Grid
                        container
                        item
                        spacing={3}
                        xs={12}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Paper 
                                className={noOverflowPaper}
                            >
                                <Table>
                                    {this.getTableHeader()}
                                    <TableBody>
                                        {users.map(this.notVerifiedArchived)}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            {dialogOpen && userId &&
                <DetailsDialog
                    onClose={this.handleCloseDialog} 
                    open={dialogOpen}
                    userId={userId}
                />}
        </>
    );
  }
}



export default withRoles(
    connector(
        withStyles(styles)(
            withTranslation()(
                Users
            )
        )
    ), [Roles.Buyer]
);
