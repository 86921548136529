import React from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import { Roles } from '../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../Roles';
import TwoStageButton from '../../forms/TwoStageButton';

interface ISubmitUserProps extends WithTranslation, IWithRoleProps{
  submitting: boolean;
  onClick: () => void;
}

class SubmitBuyer extends React.Component<ISubmitUserProps> {

  public render() {
    const {
      t,
      onClick
    } = this.props;

    return (
        <TwoStageButton
            buttonText={t('offer.accept')}
            labelText={t('offer.acceptLabel')}
            name='asdasdad'
            onClick={onClick}
        />
    );
  }
}



export default withRoles(
    withTranslation()(
        SubmitBuyer
    ), [Roles.User]
);
