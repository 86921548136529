import React from 'react';
import 'draft-js/dist/Draft.css';
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import Draft, { Editor, RichUtils } from 'draft-js';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import BlockStyleControls from './BlockStyles';
import { Roles } from '../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../Roles';
import {stateToHTML} from 'draft-js-export-html';

const styles = () => createStyles({
  container: {
    width: '100%',
    border: '1px solid #CCCCCC',
    height: '300px',
    overflow: 'scroll',
    padding: '1em'
  },
  contrast: {
    color: '#888888'
  }
});

interface IBidNotesProps extends WithTranslation,
    WithStyles,
    IWithRoleProps {
      onChange: (noteHtml: string, isEmpty: boolean) => void;
    }

interface IBidNotesState {
  editorState: Draft.EditorState;
}

class BidNotes extends React.Component<IBidNotesProps, IBidNotesState> {
  constructor(props: IBidNotesProps) {
    super(props);
    this.state = {
      editorState: Draft.EditorState.createEmpty()
    }
  }

  private editorRef?: Editor;

  private handleEditorChange = (editorState: Draft.EditorState) => {
    const {
      onChange
    } = this.props;

    const content = editorState.getCurrentContent();
    onChange(
      stateToHTML(content),
      !content.hasText()
    );
    this.setState({
      editorState: editorState
    });
  }

  private handleFocus = () => {
    if (this.editorRef !== undefined) {
      this.editorRef.focus();
    }
  }

  private handleBlockStyleToggle = (style: string) => {
    const {
      editorState
    } = this. state;

    this.setState({
      editorState: RichUtils.toggleBlockType(
        editorState,
        style
      )
    });
  }

  private createReference = (ref: Editor) => {
    this.editorRef = ref;
  }

  public render() {
    const {
      editorState
    } = this.state;

    const {
      t,
      classes,
    } = this.props;

    return (
        <Grid
            item
            xs={12}
        >
            <Typography
                className={classes.contrast}
                variant="caption"
            >
                {`${t('order.notes.comment')}: `}
            </Typography>
            <BlockStyleControls
                editorState={editorState}
                onToggle={this.handleBlockStyleToggle}
            />
            <Container
                className={classes.container}
                onClick={this.handleFocus}
            >
                <Editor
                    editorState={editorState}
                    onChange={this.handleEditorChange}
                    placeholder={t('order.notes.clickhere')}
                    ref={this.createReference}
                />
            </Container>
        </Grid>
    );
  }
}

export default withRoles(
    withStyles(styles)(
      withTranslation()(BidNotes)
  ),
[Roles.Buyer]);