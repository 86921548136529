import React from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import { Roles } from '../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../Roles';
import Typography from '@material-ui/core/Typography';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from '@material-ui/core/Link';
import { connect, ConnectedProps } from 'react-redux';
import ISystemState, { BiddersDict } from "../../../model/ISystemState";
import Network, { TYPES, networkErrorHelper } from '../../../utils/Network';
import { addError } from '../../../redux/reducers/errors';
import { addBidders } from '../../../redux/reducers/bidders';
import { ERRORS } from '../../../model/Errors';
import IBidderDetails from '../../../model/IBidderDetails';
import Grid from '@material-ui/core/Grid';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';


const mapState = (state: ISystemState) => {
    return {
      bidders: state.bidders
    };
  }

const connector = connect(mapState, { addError, addBidders });

interface IBiddersListProps extends WithTranslation, IWithRoleProps, WithStyles, ConnectedProps<typeof connector> {
  bidId: string;
}

const styles = () => createStyles({
  root: {
    boxShadow: 'none'
  },
  contrast: {
    color: '#888888'
  }
});

class BiddersList extends React.Component<IBiddersListProps> {

  handleOnChange = (ev: React.ChangeEvent<Record<string, unknown>>, expanded: boolean) => {
    const {
      bidders,
      bidId
    } = this.props

    if (expanded && !bidders[bidId]) {
      Network.GET<IBidderDetails[]>(`/bids/${bidId}/bidders/`, TYPES.JSON).then((data) => {
        const {
          addBidders
        } = this.props;
        if (data) {
          const bidders = {} as BiddersDict;
          bidders[bidId] = data;
          addBidders(bidders);
        }
      }, (err: Error) => {
        const {
          addError
        } = this.props;
        networkErrorHelper(err.message as ERRORS, addError);
      });
    }
  }

  private getRows = () => {
    const {
      bidders,
      bidId
    } = this.props;

    if (!bidders[bidId]) {
      return;
    }

    const currentsBidders = bidders[bidId];

    return currentsBidders.map((el) => {
        return (
            <>
                <Grid
                    item
                    key={el.id}
                    xs={6}
                >
                    <Link
                        href={`${process.env.REACT_APP_REMOTE_URL || ''}/redirector/clients/?id=${el.accountingId}`}
                        target="_blank"
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}
                        >
                            <span>
                                {el.companyName}
                            </span>
                            <OpenInNewIcon
                                fontSize="small"
                            />
                        </div>
                    </Link>
                </Grid>
                <Grid
                    item
                    key={`${el.id}-qty`}
                    xs={6}
                >
                    {el.quantity}
                </Grid>
            </>
        );
    });
  }

  public render() {
    const {
      t,
      classes
    } = this.props;

    return (
        <Accordion
            className={classes.root}
            onChange={this.handleOnChange}
            square
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
            >
                <Link>
                    <Typography>
                        {`${t('biddersList.title')}:`}
                    </Typography>
                </Link>
            </AccordionSummary>
            <AccordionDetails>
                <Grid
                    alignItems="flex-end"
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={6}
                    >
                        {`${t('biddersList.companyName')}:`}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        {`${t('biddersList.quantity')}:`}
                    </Grid>
                    {this.getRows()}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
  }
}



export default 
  connector(
    withRoles(
      withStyles(styles)(
        withTranslation()(BiddersList)
      ), [Roles.Buyer]
    )
  );

