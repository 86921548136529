import React from 'react';
import ISystemState from './model/ISystemState';
import { connect, ConnectedProps } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { resetWarnings } from './redux/reducers/warnings';

const mapState = (state: ISystemState) => {
  return {
    warnings: state.warnings
  };
}

const connector = connect(mapState, { resetWarnings });

interface IWarningProps extends  WithTranslation, ConnectedProps<typeof connector> {}

interface IWarningState {
  open?: boolean;
}

class Warning extends React.Component<IWarningProps, IWarningState> {

  private handleClose = () => {
    const {
      resetWarnings
    } = this.props;

    resetWarnings();
  }

  public render() {
    const {
      t,
      warnings
    } = this.props;


    if (!warnings.length) {
      return (
          null
      );
    }

    return (
        <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            autoHideDuration={100000}
            onClose={this.handleClose}
            open
        >
            <MuiAlert
                elevation={6}
                onClose={this.handleClose}
                severity="warning"
                variant="filled"
            >
                {warnings.map((el) => {
                  return (
                      <div key={el}>
                          {t(`globalWarnings.${el}`)}
                      </div>
                  );
                })}
            </MuiAlert>
        </Snackbar>
    )
  }
}
export default connector(
    withTranslation()(Warning)
);