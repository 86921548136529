import React from "react";
import { connect, ConnectedProps } from "react-redux";
import ISystemState from "../../../model/ISystemState";
import IBidData from "../../../model/IBidData";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withTranslation, WithTranslation } from 'react-i18next';
import { formatDate } from '../../../utils/Date';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { withRouter, RouteComponentProps } from 'react-router-dom';


const styles = (theme: Theme) => createStyles({
    hover: {
        cursor: 'pointer'
    },
    open: {
        color: '#888888',
        borderColor: '#888888'
    },
    closed: {
        color: '#e7b416',
        borderColor: '#e7b416'
    },
    acceptedOffer: {
      color: '#2dc937',
      borderColor: '#2dc937'
    },
    offer: {
        color: '#cc3232',
        borderColor: '#cc3232'
    },
    ordered: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
    }
});


const mapState = (state: ISystemState) => {
  return {
      bidStates: state.bidStates
  };
};

const connector = connect(mapState)

interface IBidsListRowProps extends ConnectedProps<typeof connector>, WithStyles, WithTranslation, RouteComponentProps {
  el: IBidData;
  pathFragment: string;
}

class BidsListRow extends React.Component<IBidsListRowProps> {

  private handleRowClick = () => {
    const {
      el,
      history,
      i18n,
      pathFragment
    } = this.props;
    history.push(`/${i18n.language}/${pathFragment}/${el.bidId}`);
  }

  private getChip = (state: number, accepted?: boolean, exited?: boolean, expired?: boolean, isPaid?: string): JSX.Element => {
    const {
        bidStates,
        classes,
        t
    } = this.props;

    let translation = '';
    let className;
    let icon;
    switch (state) {
      case bidStates['Open']:
        translation = t('bidsList.states.open');
        className = classes.open;
      break;
      case bidStates['Closed']:
        translation = t('bidsList.states.closed');
        className = classes.closed;
      break;
      case bidStates['Offer']:
        translation = t('bidsList.states.offer');
        className = accepted ? classes.acceptedOffer : classes.offer;
        if (accepted) {
          icon = (<DoneIcon className={className} />);
        } else {
          icon = (<PriorityHighIcon className={className} />);
        }
      break;
      case bidStates['Confirmed']:
      case bidStates['Ordered']:
        translation = t('bidsList.states.ordered');
        className = classes.ordered;
        icon = (<DoneIcon className={className} />);
      break;
      case bidStates['Pickup']:
      case bidStates['Dispatched']:
        translation = t('bidsList.states.dispatched');
        className = classes.ordered;
      break;
      case bidStates['Paid']:
      case bidStates['Delivered']:
        translation = t('bidsList.states.delivered');
        className = classes.ordered;
        icon = (<ArrowForwardIosIcon className={className} />);
      break;
      default:
        translation = `${state}`;
    }

    if (exited) {
      translation = t('bidsList.states.exited');
      className = classes.open;
      icon = undefined;
    }

    if (expired) {
      translation = t('bidsList.states.expired');
      className = classes.offer;
      icon = undefined;
    }

    if (isPaid === 'PAID') {
      translation = t('bidsList.states.paid');
      className = classes.acceptedOffer;
      icon = (<DoneIcon className={className} />);
    }

    return (
        <Chip
            className={className}
            icon={icon}
            label={translation}
            size="small"
            variant="outlined"
        />)
  }

  public render() {
    const {
      classes,
      el
    } = this.props;

    return (
        <TableRow
            className={classes.hover}
            key={el.bidId}
            onClick={this.handleRowClick}
        >
            <TableCell>
                {formatDate(new Date(el.timestamp))}
            </TableCell>
            <TableCell>
                {el.commodity}
            </TableCell>
            <TableCell>
                {el.manufacturer}
            </TableCell>
            <TableCell>
                {el.product}
            </TableCell>
            <TableCell>
                {el.EAN}
            </TableCell>
            <TableCell>
                {el.partNumber}
            </TableCell>
            <TableCell>
                {formatDate(new Date(el.byDate))}
            </TableCell>
            <TableCell>
                {el.quantity}
            </TableCell>
            <TableCell>
                {this.getChip(el.state, el.accepted, el.exited, el.expired, el.isPaid)}
            </TableCell>
        </TableRow>
    );
  }
}



export default connector(
  withStyles(styles)(
    withTranslation()(withRouter(BidsListRow))
  )
);
