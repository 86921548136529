
import { createMuiTheme } from '@material-ui/core/styles';

const Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#5C9AB7',
    },
    secondary: {
      main: '#5995ED',
    },
    contrastThreshold: 0.1
  },
});


export default Theme;
