/* eslint-disable */
import React from 'react';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import FormHelperText from '@material-ui/core/FormHelperText';

import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';


function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={(value) => {
        let mask = ['+'];
        let len = value.length || 1;
        mask.length = len < 18 ? len : 18;
        return mask.fill(/\d/, 1);
      }}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}


export class PhoneValidator extends ValidatorComponent {

  renderValidatorComponent() {
    const {
      label,
      value,
      onChange
    } = this.props;

    return (
        <React.Fragment>
          <InputLabel htmlFor="phonenumber" shrink={true}>{label}</InputLabel>
            <Input
              fullWidth
              id="phonenumber"
              name="phonenumber"
              value={value}
              onChange={onChange}
              inputComponent={TextMaskCustom}
            />
             {this.errorText()}
        </React.Fragment>
    );
  }

  errorText() {
      const { isValid } = this.state;

      if (isValid) {
          return null;
      }

      return (
          <FormHelperText error>
              {this.getErrorMessage()}
          </FormHelperText>
      );
  }
}

export default PhoneValidator;