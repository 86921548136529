import React from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import { Roles } from '../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../Roles';
import Typography from '@material-ui/core/Typography';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';

interface IShowQuantityProps extends WithTranslation, IWithRoleProps, WithStyles{
  quantity: number;
}

const styles = () => createStyles({
  contrast: {
    color: '#888888'
  }
});

class ShowQuantity extends React.Component<IShowQuantityProps> {

  public render() {
    const {
      t,
      classes,
      quantity
    } = this.props;

    return (
        <>
            <Typography
                className={classes.contrast}
                variant="caption"
            >
                {`${t('order.quantity')}:`}
            </Typography>
            <Typography>
                {quantity}
            </Typography>
        </>
    );
  }
}



export default withRoles(
  withStyles(styles)(
    withTranslation()(ShowQuantity)
  ), [Roles.Buyer]
);
