import React from "react";
import { Roles } from '../../../model/Roles';
import { withRoles, IWithRoleProps } from '../../Roles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBox from '@material-ui/icons/AccountBox';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import Restore from '@material-ui/icons/Restore';
import Divider from '@material-ui/core/Divider';
import { withTranslation, WithTranslation } from 'react-i18next';
import Signout from '../Signout';
import { Link } from "react-router-dom";
import Box from '@material-ui/core/Box';


interface IUserDrawerProps extends IWithRoleProps, WithTranslation {}

class UserDrawer extends React.Component<IUserDrawerProps> {

  private getListItem = (path: string, text: string, icon: JSX.Element) => {
    return (
        <Link
            style={{textDecoration: 'none', color: 'inherit'}}
            to={path}
        >
            <ListItem button>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={text} />
            </ListItem>
        </Link>);
  }

  public render() {
    const {
        t,
        i18n
    } = this.props;
    return (
        <>
            {this.getListItem(`/${i18n.language}/home`, t('drawer.dashboard'), <DashboardIcon />)}
            {this.getListItem(`/${i18n.language}/archive`, t('drawer.archive'), <Restore />)}
            {this.getListItem(`/${i18n.language}/join`, t('drawer.joinBid'), <GroupWorkIcon />)}
            {this.getListItem(`/${i18n.language}/profile`, t('drawer.profile'), <AccountBox />)}
            <Box
                alignItems="flex-end"
                display="flex"
                height="calc(100% - 192px)"
            >
                <Divider />
                <Signout />
            </Box>
        </>
    );
  }
}

export default 
    withRoles(withTranslation()(UserDrawer), [Roles.User]);
