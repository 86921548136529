import DateFnsUtils from '@date-io/date-fns';

const dateUtil = new DateFnsUtils();

const TEMPLATE = 'dd.MM.yyyy';
const STORAGE_TEMPLATE = 'yyyy-MM-dd';

export const formatDate = (date: Date): string => {
  return dateUtil.format(date, TEMPLATE);
};

export const formatDateForStorage = (date: Date): string => {
  return dateUtil.format(date, STORAGE_TEMPLATE);
};
