import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = (theme: Theme) => createStyles({
  footer: {
    height: '100px',
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: '#333C44',
    color: 'white'
  },
  link: {
    color: 'white'
  }
});

interface IFooterProps extends WithTranslation, WithStyles {}

class Footer extends React.Component<IFooterProps> {

  public render() {
    const {
      t,
      classes
    } = this.props;

    const watermark = `EoS4U`;

    return (
        <footer className={classes.footer}>
            <Container maxWidth="sm">
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={4}
                    >
                        <Typography variant="body1">
                            {watermark}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                    >
                        <div>
                            <Typography variant="body2">
                                <Link
                                    className={classes.link}
                                    href={`${process.env.REACT_APP_WEBSITE_URL || ''}privacypolicy/`}
                                >
                                    {t(`footer.privacy`)}
                                </Link>
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body2">
                                <Link
                                    className={classes.link}
                                    href={`${process.env.REACT_APP_WEBSITE_URL || ''}termsofuse/`}
                                >
                                    {t(`footer.terms`)}
                                </Link>
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="body2">
                                <Link
                                    className={classes.link}
                                    href={`${process.env.REACT_APP_WEBSITE_URL || ''}feedback/`}
                                >
                                    {t(`footer.contacts`)}
                                </Link>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                    >
                        <div>
                            <Typography variant="body2">
                                <Link
                                    className={classes.link}
                                    href={`${process.env.REACT_APP_WEBSITE_URL || ''}imprint/`}
                                >
                                    {t(`footer.imprint`)}
                                </Link>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
  }
}


export default withStyles(styles)(
  withTranslation()(Footer)
);