import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IContactDetails from '../../model/IContactDetails';
import PhoneValidator from '../forms/PhoneValidator.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import { withTranslation, WithTranslation } from 'react-i18next';

import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  fullwidth: {
    width: '100%'
  }
})

interface IContactDetaulsState extends IContactDetails {
  submitting: boolean;
}

interface IContactDetailsProps extends WithTranslation, WithStyles {
  contactDetails?: IContactDetails;
  buttonText: string;
  onChange: (data: IContactDetails, cb: () => void) => void;
  onBack?: () => void;
  emailDisabled?: boolean;
}

class ContactDetails extends React.Component<IContactDetailsProps, IContactDetaulsState> {
  constructor(props: IContactDetailsProps) {
    super(props);

    const state = {
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      phonenumber: '',
      submitting: false
    } as IContactDetaulsState;

    if (props.contactDetails) {
      const contactDetails = props.contactDetails;
      state.firstName = contactDetails.firstName;
      state.lastName = contactDetails.lastName;
      state.position = contactDetails.position;
      state.email = contactDetails.email;
      state.phonenumber = contactDetails.phonenumber;
    }

    this.state = {
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      phonenumber: '',
      submitting: false
    }
    this.state = {...state};
  }

  private handleSubmit = () => {
    const {
      firstName,
      lastName,
      position,
      email,
      phonenumber,
      submitting
    } = this.state;

    const {
      onChange
    } = this.props;

    if (submitting) return;

    this.setState({
      submitting: true
    });

    onChange({
      firstName,
      lastName,
      position,
      email,
      phonenumber
    }, this.submitCallback);
  }

  private handleSetFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ firstName: event.target.value });
  }

  private handleSetLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ lastName: event.target.value });
  }

  private handleSetPosition = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ position: event.target.value });
  }

  private handleSetEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value });
  }

  private handleSetPhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      phonenumber: event.target.value,
    });
  }

  private handleBackButton = () => {
    const {
      onBack
    } = this.props;
    onBack && onBack();
  }

  private submitCallback = () => {
    this.setState({
      submitting: false
    });
  }

   public render() {
    const {
      firstName,
      lastName,
      position,
      phonenumber,
      email,
      submitting
    } = this.state;
    const {
      t,
      classes,
      onBack,
      buttonText,
      emailDisabled
    } = this.props;

    return (
        <ValidatorForm
            className={classes.fullwidth}
            instantValidate={false}
            onSubmit={this.handleSubmit}
        >
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <TextValidator
                        autoComplete="given-name"
                        errorMessages={[t('signup.errors.required'), t('signup.errors.nameTooShort'), t('signup.errors.nameTooLong')]}
                        fullWidth
                        id="firstName"
                        label={t('signup.firstName').toString()}
                        name="firstName"
                        onChange={this.handleSetFirstName}
                        validators={['required', 'minStringLength:1', 'maxStringLength:100']}
                        value={firstName}
                    />
                </Grid>
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <TextValidator
                        autoComplete="family-name"
                        errorMessages={[t('signup.errors.required'), t('signup.errors.nameTooShort'), t('signup.errors.nameTooLong')]}
                        fullWidth
                        id="lastName"
                        label={t('signup.lastName').toString()}
                        name="lastName"
                        onChange={this.handleSetLastName}
                        validators={['required', 'minStringLength:1', 'maxStringLength:100']}
                        value={lastName}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <TextValidator
                        autoComplete="position"
                        errorMessages={[t('signup.errors.required'), t('signup.errors.nameTooShort'), t('signup.errors.nameTooLong')]}
                        fullWidth
                        id="position"
                        label={t('signup.position').toString()}
                        name="position"
                        onChange={this.handleSetPosition}
                        validators={['required', 'minStringLength:1', 'maxStringLength:100']}
                        value={position}
                    />
                </Grid>
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <TextValidator
                        autoComplete="email"
                        disabled={emailDisabled}
                        errorMessages={[
                          t('signup.errors.required'),
                          t('signup.errors.correctEmail'),
                          t('signup.errors.nameTooShort'),
                          t('signup.errors.nameTooLong')]}
                        fullWidth
                        id="email"
                        label={t('signup.email').toString()}
                        name="email"
                        onChange={this.handleSetEmail}
                        validators={['required', 'isEmail', 'minStringLength:1', 'maxStringLength:100']}
                        value={email}
                    />
                </Grid>
                <Grid
                    item
                    sm={6}
                    xs={12}
                >
                    <PhoneValidator 
                        errorMessages={[t('signup.errors.required')]}
                        fullWidth
                        id="phonenumber"
                        label={t('signup.phonenumber').toString()}
                        name="phonenumber"
                        onChange={this.handleSetPhoneNumber}
                        validators={['required']}
                        value={phonenumber}
                    />
                </Grid>
            </Grid>
            <div className={classes.buttons}>
                {onBack &&
                <Button
                    className={classes.button}
                    onClick={this.handleBackButton}
                >
                    {t('signup.back')}
                </Button>}
                <Button
                    className={classes.button}
                    color="primary"
                    disabled={submitting}
                    type="submit"
                    variant="contained"
                >
                    {buttonText}
                    {submitting &&
                        <CircularProgress 
                            className={classes.buttonProgress}
                            size={24}
                        />}
                </Button>
            </div>
        </ValidatorForm>);
  }
}

export default withStyles(styles)(withTranslation()(ContactDetails));
