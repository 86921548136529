import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WARNINGS } from '../../model/Warnings';

const initialState = Array<WARNINGS>();

const warnings = createSlice({
  name: 'warnings',
  initialState: initialState,
  reducers: {
    'addWarning': (state = initialState, action: PayloadAction<WARNINGS>) => {
      state.push(action.payload);
      return state;
    },
    'resetWarnings':  (state = initialState) => {
      state = [];
      return state;
    }
  }
});



export const { addWarning, resetWarnings } = warnings.actions;

export default warnings.reducer;

