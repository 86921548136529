import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from '../store';
import Network, { TYPES } from "../../utils/Network";
import ICountry from '../../model/ICountry';
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';

const initialState = Array<ICountry>({name: ''});

const countries = createSlice({
  name: 'countries',
  initialState: initialState,
  reducers: {
    'addCountries': (state = initialState, action: PayloadAction<ICountry[]>) => {
      state = action.payload;
      return state;
    }
  }
});

export const { addCountries } = countries.actions;

export default countries.reducer;

export const fetchCountries = (language: string): AppThunk => (dispatch) => {
  Network.GET<Array<ICountry>>(`./i18n/${language}/countries.json`, TYPES.JSON, true).then((res) => {
    if (res) {
      dispatch(addCountries(res));
    }
  }, (err: Error) => {
    if (err.message in ERRORS) {
      dispatch(addError(err.message as ERRORS));
      return;
    }
    dispatch(addError(ERRORS.NETWORK_ERROR));
  });
}
