import { ERRORS } from '../model/Errors';
import * as Sentry from "@sentry/react";

export enum TYPES {
  JSON = 'json',
  TEXT = 'text'
}

export enum ContentType {
  json = 'application/json',
  text = 'text/html',
  multipart = 'multipart/form-data '
}

const toFormData = (obj: Record<string, string|Blob>) => {
  const formData  = new FormData();
  for(const name in obj) {
    formData.append(name, obj[name]);
  }
  return formData;
}

export const version = '/v1';

export const SERVER_URL = process.env.REACT_APP_REMOTE_URL || '';
export const APP_URL = process.env.REACT_APP_SELF_URL || '';

export const networkErrorHelper = (err: ERRORS, cb: (err: ERRORS) => void): void => {
  if (err in ERRORS) {
    cb(err);
    return;
  }

  cb(ERRORS.NETWORK_ERROR);
}

const processError = (res: Response) => {
  Sentry.addBreadcrumb({
    category: "network",
    message: JSON.stringify({
      ok: res.ok,
      redirected: res.redirected,
      status: res.status,
      statusText: res.statusText,
      type: res.type,
      url: res.url
    }),
    level: Sentry.Severity.Error,
  });
  Sentry.captureException(new Error(res.url));

  if (res.status >= 500)
    throw new Error(ERRORS.INTERNAL_SERVER_ERROR);

  if (res.status === 401 || res.status === 403) {
    throw new Error(ERRORS.UNAUTHORIZED);
  }

  if (res.status === 402)
    throw new Error(ERRORS.PAYMENT_REQUIRED);

  throw new Error(ERRORS.UNKNOWN_ERROR);
}

const sendData = <T>(path: string, method: string, type: TYPES, contentType: ContentType,
      body?: string|Record<string, unknown>): Promise<T> => {

  let parsedBody;

  switch (contentType) {
    case ContentType.json:
      parsedBody = JSON.stringify(body);
      break;
    case ContentType.text:
      parsedBody = body?.toString();
      break;
    case ContentType.multipart:
      parsedBody = toFormData(body as Record<string, Blob>)
      break;
  }

  const headers = {} as Record<string, string>;
  if (contentType !== ContentType.multipart) {
    headers['Content-Type'] = contentType;
  }

  return fetch(path, {
    credentials: 'include',
    method: method,
    headers: headers,
    body: parsedBody
  }).then((res): Promise<T> => {
    if (res.ok || res.status === 404) {
      return res[type]();
    }

    Sentry.addBreadcrumb({
      category: "network",
      message: JSON.stringify({
        ok: res.ok,
        redirected: res.redirected,
        status: res.status,
        statusText: res.statusText,
        type: res.type,
        url: res.url
      }),
      level: Sentry.Severity.Error,
    });
    Sentry.captureException(new Error(res.url));

    if (res.status >= 500)
      throw new Error(ERRORS.INTERNAL_SERVER_ERROR);

    if (res.status === 401 || res.status === 403) 
      throw new Error(ERRORS.UNAUTHORIZED);

    if (res.status === 402)
      throw new Error(ERRORS.PAYMENT_REQUIRED);

    throw new Error(ERRORS.UNKNOWN_ERROR);
  });
};

class Network {

  public static GET<T>(path: string, type: TYPES = TYPES.JSON, isLocal = false, params?: Record<string, string|boolean|number>): Promise<T|null> {
    let dest = path;
    let prefix = '';
    let noCredentials = false;
    if (params && params.prefix) {
      prefix = params.prefix.toString();
      delete params.prefix;
    }
    if (params && params.noCredentials) {
      noCredentials = params.noCredentials === true;
      delete params.noCredentials;
    }
    if (!isLocal) {
      const url = new URL(`${SERVER_URL}${prefix || version}${path}`);
      if (params) {
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key].toString()));
      }
      dest = url.toString();
    }

    return fetch(dest, {
      cache: 'no-store',
      headers: {
        'Cache-Control': 'no-store',
        'Pragma': 'no-cache'
      },
      credentials: (noCredentials ? undefined : 'include')
    }).then((res): Promise<T|null> => {
      if (res.status === 404) {
        return Promise.resolve(null);
      }
      if (res.ok)
        return res[type]();

      try {
        processError(res);
      } catch(ex) {
        return res.json().then((res: Record<string, boolean>) => {
          if (res.suspended) {
            return Promise.reject(new Error(ERRORS.SUSPENDED));
          }
          return Promise.reject(ex);
        });
      }
      return Promise.resolve(null);
    });
  }

  public static POST<T>(path: string, type: TYPES = TYPES.JSON, body: string|Record<string, unknown>, contentType?: ContentType): Promise<T> {
    return sendData(`${SERVER_URL}${version}${path}`, 'POST', type, contentType || ContentType[type], body);
  }

  public static PUT<T>(path: string, type: TYPES = TYPES.JSON, body: string|Record<string, unknown>, contentType?: ContentType): Promise<T> {
    return sendData(`${SERVER_URL}${version}${path}`, 'PUT', type, contentType || ContentType[type], body);
  }

  public static PATCH<T>(path: string, type: TYPES = TYPES.JSON, body: string|Record<string, unknown>, contentType?: ContentType): Promise<T> {
    return sendData(`${SERVER_URL}${version}${path}`, 'PATCH', type, contentType || ContentType[type], body);
  }

  public static DELETE<T>(path: string, type: TYPES = TYPES.JSON, body?: string|Record<string, unknown>, contentType?: ContentType): Promise<T> {
    return sendData(`${SERVER_URL}${version}${path}`, 'DELETE', type, contentType || ContentType[type], body);
  }
}

export default Network;
