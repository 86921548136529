import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from '../store';
import Network, { TYPES } from "../../utils/Network";
import IVendor from "../../model/IVendor";
import { addError } from '../../redux/reducers/errors';
import { ERRORS } from '../../model/Errors';


const initialState: Array<IVendor> = [];


const vendors = createSlice({
  name: 'vendors',
  initialState: initialState,
  reducers: {
    'addVendors': (state = initialState, action: PayloadAction<Array<IVendor>>) => {
      const payload = action.payload;
      state = payload;
      return state;
    }
  }
});

export const { addVendors } = vendors.actions;

export default vendors.reducer;

export const fetchVendors = (): AppThunk => (dispatch) => {
  Network.GET<Array<IVendor>>(`/vendors/`, TYPES.JSON).then((json) => {
    if (json) {
      dispatch(addVendors(json));
    }
  }, (err: Error) => {
    if (err.message in ERRORS) {
      dispatch(addError(err.message as ERRORS));
      return;
    }
    dispatch(addError(ERRORS.NETWORK_ERROR));
  });
}
