export enum ERRORS {
  LOGIN_FAILED = 'LOGIN_FAILED',
  WRONG_VERIFICATION_CODE = 'WRONG_VERIFICATION_CODE',
  USER_NOT_VERIFIED = 'USER_NOT_VERIFIED',
  NETWORK_ERROR = 'NETWORK_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  UNAUTHORIZED = 'UNAUTHORIZED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  SUSPENDED = 'SUSPENDED'
}